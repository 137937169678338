import { Currency, Price } from '@uniswap/sdk-core'
import { useCallback, useContext } from 'react'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'

interface TradePriceProps {
  price: Price<Currency, Currency>
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
  styleBody?: React.CSSProperties
}

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 400;
  background-color: transparent;
  border: none;
  height: 24px;
  cursor: pointer;
`
const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  width: fit-content;
`
const Body = styled(Text)`
  color: var(--mc-color-light-7);
  font-size: var(--mc-font-size-x-small);
  font-weight: var(--mc-font-weight-bold);
`
export default function TradePrice({ price, showInverted, setShowInverted, styleBody }: TradePriceProps) {
  const theme = useContext(ThemeContext)

  let formattedPrice: string
  try {
    formattedPrice = showInverted ? price.toSignificant(4) : price.invert()?.toSignificant(4)
  } catch (error) {
    formattedPrice = '0'
  }

  const label = showInverted ? `${price.quoteCurrency?.symbol}` : `${price.baseCurrency?.symbol} `
  const labelInverted = showInverted ? `${price.baseCurrency?.symbol} ` : `${price.quoteCurrency?.symbol}`
  const flipPrice = useCallback(() => setShowInverted(!showInverted), [setShowInverted, showInverted])

  const text = `${'1 ' + labelInverted + ' = ' + formattedPrice ?? '-'} ${label}`

  return (
    <Button onClick={flipPrice} title={text}>
      <ButtonWrapper>
        {/* <Text fontWeight={500} fontSize={14} color={theme.text1}> */}
        <Body style={styleBody}>{text}</Body>
      </ButtonWrapper>
    </Button>
  )
}
