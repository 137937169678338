import { ArrowDownIcon, FilledInfoIcon, InfoIcon } from '@axieinfinity/mochi-icons'
import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import { Trade as V2Trade } from '@uniswap/v2-sdk'
import { Trade as V3Trade } from '@uniswap/v3-sdk'
import { useContext, useState } from 'react'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { useUSDCValue } from '../../hooks/useUSDCPrice'
import { TYPE } from '../../theme'
import { computeFiatValuePriceImpact } from '../../utils/computeFiatValuePriceImpact'
import { AutoColumn } from '../Column'
import { FiatValue } from '../CurrencyInputPanel/FiatValue'
import CurrencyLogo from '../CurrencyLogo'
import { RowBetween, RowFixed } from '../Row'
import { AdvancedSwapDetails } from './AdvancedSwapDetails'
import { SwapShowAcceptChanges, TruncatedText } from './styleds'

import { Button } from '@axieinfinity/mochi'
import { isAddress } from 'ethers/lib/utils'
import { shortenAddress } from 'utils'
import { DarkGreyCard } from '../Card'
import TradePrice from '../swap/TradePrice'

const Container = styled(AutoColumn)`
  margin-top: 1rem;
  padding-right: var(--mc-space-inline-20x);
  padding-left: var(--mc-space-inline-20x);
`
const HeaderCard = styled.div`
  color: var(--mc-color-light-7);
  text-transform: uppercase;
  font-size: var(--mc-font-size-x-small);
  line-height: var(--mc-line-height-normal);
  font-weight: var(--mc-font-weight-bold);
`
const CurrencyLogoWrapper = styled.div`
  margin-right: var(--mc-space-inline-12x);
`
const CurrencySymbol = styled(Text)`
  color: var(--mc-color-light-9);
  line-height: var(--mc-line-height-3x-large);
`
const CardToCurrency = styled(DarkGreyCard)`
  margin-bottom: 0.25rem;
`
const CardFromCurrency = styled(DarkGreyCard)``

const RowTradePrice = styled(RowBetween)`
  margin-top: var(--mc-space-stack-8x);
  padding: 0 var(--mc-space-inline-16x);
`

const RowHeader = styled.div`
  font-size: var(--mc-font-size-x-small);
  line-height: var(--mc-line-height-normal);
  font-weight: var(--mc-font-weight-bold);
  color: var(--mc-color-light-7);
  text-transform: uppercase;
`
const CardSwapDetails = styled.div`
  border-radius: var(--mc-space-inset-8x);
  padding: var(--mc-space-stack-12x) var(--mc-space-inline-16x);
  background-color: var(--mc-color-light-1);
  margin-top: var(--mc-space-stack-8x);
`
const RowExtraInfo = styled(AutoColumn)`
  margin-top: var(--mc-space-stack-12x);
`
const InfoWrapper = styled.div`
  display: flex;
`
const Info = styled.div`
  font-size: var(--mc-font-size-normal);
  color: var(--mc-color-light-7);
`
const RowRecipientInfo = styled(AutoColumn)`
  padding-top: var(--mc-space-stack-12x);
`
const SwapPriceUpdateAccept = styled(SwapShowAcceptChanges)`
  margin-top: var(--mc-space-stack-12x);
`
export const ArrowWrapper = styled.div`
  padding: 4px;
  border-radius: 12px;
  height: 32px;
  width: 32px;
  position: relative;
  border: 2px solid #fff;
  margin-top: -18px;
  margin-bottom: -18px;
  left: calc(50% - 16px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.bg1};
  z-index: 2;
  color: var(--mc-color-light-7);
`
const InfoIconWrapper = styled.div`
  width: 16px;
  margin-right: var(--mc-space-inline-8x);
  margin-top: var(--mc-space-stack-2x);
`
const FilledInfoIconWrapper = styled.div`
  margin-right: var(--mc-space-inline-8x);
  color: var(--mc-color-blue-5);
  min-width: 20px;
`

export default function SwapModalHeader({
  trade,
  allowedSlippage,
  recipient,
  showAcceptChanges,
  onAcceptChanges,
}: {
  trade: V2Trade<Currency, Currency, TradeType> | V3Trade<Currency, Currency, TradeType>
  allowedSlippage: Percent
  recipient: string | null
  showAcceptChanges: boolean
  onAcceptChanges: () => void
}) {
  const theme = useContext(ThemeContext)

  const [showInverted, setShowInverted] = useState<boolean>(true)

  const fiatValueInput = useUSDCValue(trade.inputAmount)
  const fiatValueOutput = useUSDCValue(trade.outputAmount)

  return (
    <Container gap={'4px'}>
      <CardFromCurrency padding="16px 16px 16px 24px">
        <AutoColumn gap={'0px'}>
          <RowBetween>
            {/* <TYPE.body color={theme.text3} fontWeight={500} fontSize={14}> */}
            <HeaderCard>
              {/* <Trans> */}From:{/* </Trans> */}
            </HeaderCard>
            {/* </TYPE.body> */}
            <FiatValue fiatValue={fiatValueInput} />
          </RowBetween>
          <RowBetween align="center">
            <RowFixed gap={'0px'}>
              <CurrencyLogoWrapper>
                <CurrencyLogo currency={trade.inputAmount.currency} size={'32px'} />
              </CurrencyLogoWrapper>
              <CurrencySymbol fontSize={24} fontWeight={600}>
                {trade.inputAmount.currency.symbol}
              </CurrencySymbol>
            </RowFixed>
            <RowFixed gap={'0px'}>
              <TruncatedText
                fontSize={28}
                fontWeight={700}
                color={
                  showAcceptChanges && trade.tradeType === TradeType.EXACT_OUTPUT
                    ? theme.primary1
                    : 'var(--mc-color-light-9)'
                }
              >
                {trade.inputAmount.toSignificant(6)}
              </TruncatedText>
            </RowFixed>
          </RowBetween>
        </AutoColumn>
      </CardFromCurrency>
      <ArrowWrapper>
        <ArrowDownIcon size={24} />
      </ArrowWrapper>
      <CardToCurrency padding="16px 16px 16px 24px">
        <AutoColumn gap={'0px'}>
          <RowBetween>
            {/* <TYPE.body color={theme.text3} fontWeight={500} fontSize={14}> */}
            <HeaderCard>
              {/* <Trans> */}To:{/* </Trans> */}
            </HeaderCard>
            {/* </TYPE.body> */}
            <TYPE.body fontSize={14} color={theme.text3}>
              <FiatValue
                fiatValue={fiatValueOutput}
                priceImpact={computeFiatValuePriceImpact(fiatValueInput, fiatValueOutput)}
              />
            </TYPE.body>
          </RowBetween>
          <RowBetween align="flex-end">
            <RowFixed gap={'0px'}>
              <CurrencyLogoWrapper>
                <CurrencyLogo currency={trade.outputAmount.currency} size={'32px'} />
              </CurrencyLogoWrapper>
              <CurrencySymbol fontSize={24} fontWeight={600}>
                {trade.outputAmount.currency.symbol}
              </CurrencySymbol>
            </RowFixed>
            <RowFixed gap={'0px'}>
              <TruncatedText fontSize={28} fontWeight={700} color="var(--mc-color-light-9)">
                {trade.outputAmount.toSignificant(6)}
              </TruncatedText>
            </RowFixed>
          </RowBetween>
        </AutoColumn>
      </CardToCurrency>
      <RowTradePrice>
        {/* <TYPE.body color={theme.text2} fontWeight={500} fontSize={14}> */}
        <RowHeader>
          {/* <Trans> */}Price{/* </Trans> */}
        </RowHeader>
        {/* </TYPE.body> */}
        <TradePrice
          price={trade.executionPrice}
          showInverted={showInverted}
          setShowInverted={setShowInverted}
          styleBody={{
            color: 'var(--mc-color-light-9)',
            fontWeight: 'var(--mc-font-weight-semi-bold)',
            fontSize: 'var(--mc-font-size-normal)',
          }}
        />
      </RowTradePrice>

      {/* <LightCard style={{ padding: '.75rem', marginTop: '0.5rem' }}> */}
      <CardSwapDetails>
        <AdvancedSwapDetails trade={trade} allowedSlippage={allowedSlippage} />
      </CardSwapDetails>
      {/* </LightCard> */}

      <RowExtraInfo justify="flex-start" gap="sm">
        {trade.tradeType === TradeType.EXACT_INPUT ? (
          // <TYPE.italic fontWeight={400} textAlign="left" style={{ width: '100%' }}>
          <InfoWrapper>
            <InfoIconWrapper>
              <InfoIcon size={16} color="var(--mc-color-light-7)" />
            </InfoIconWrapper>
            <Info>
              {/* <Trans> */}
              Output is estimated. You will receive at least {trade
                .minimumAmountOut(allowedSlippage)
                .toSignificant(6)}{' '}
              {trade.outputAmount.currency.symbol} or the transaction will revert.
              {/* </Trans> */}
            </Info>
          </InfoWrapper>
        ) : (
          // </TYPE.italic>
          // <TYPE.italic fontWeight={400} textAlign="left" style={{ width: '100%' }}>
          <InfoWrapper>
            <InfoIconWrapper>
              <InfoIcon size={16} color="var(--mc-color-light-7)" />
            </InfoIconWrapper>
            <Info>
              {/* <Trans> */}
              Input is estimated. You will sell at most {trade.maximumAmountIn(allowedSlippage).toSignificant(6)}{' '}
              {trade.inputAmount.currency.symbol} or the transaction will revert.
              {/* </Trans> */}
            </Info>
          </InfoWrapper>
          // </TYPE.italic>
        )}
      </RowExtraInfo>
      {recipient !== null ? (
        <RowRecipientInfo justify="flex-start" gap="sm">
          <TYPE.main>
            <InfoWrapper>
              <InfoIconWrapper>
                <InfoIcon size={16} color="var(--mc-color-light-7)" />
              </InfoIconWrapper>
              <Info>
                {/* <Trans> */}
                Output will be sent to{' '}
                <b title={recipient}>{isAddress(recipient) ? shortenAddress(recipient) : recipient}</b>
                {/* </Trans> */}
              </Info>
            </InfoWrapper>
          </TYPE.main>
        </RowRecipientInfo>
      ) : null}
      {showAcceptChanges ? (
        <SwapPriceUpdateAccept justify="flex-start" gap={'0px'}>
          <RowBetween>
            <RowFixed>
              <FilledInfoIconWrapper>
                <FilledInfoIcon size={20} />
              </FilledInfoIconWrapper>
              <TYPE.main color="var(--mc-color-light-9)" fontWeight={400}>
                Price updated
              </TYPE.main>
            </RowFixed>
            <Button onClick={onAcceptChanges} intent="primary" size="small">
              Confirm
            </Button>
          </RowBetween>
        </SwapPriceUpdateAccept>
      ) : null}
    </Container>
  )
}
