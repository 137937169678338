import React, { useState } from 'react'

import { Popover } from '@axieinfinity/mochi'
import {
  CurrencyExchangeIcon,
  FilledBarChartIcon,
  FilledFlashIcon,
  FilledNotebookIcon,
  MoreHorizontalIcon,
  TransferIcon,
} from '@axieinfinity/mochi-icons'
import useToggle from 'hooks/useToggle'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'

const analyticsDomain = process.env.REACT_APP_KATANA_ANALYTICS_DOMAIN ?? ''

const MobileNavItem = styled.div`
  div {
    color: ${({ pathname, menu }: { pathname?: string; menu?: string[] }) =>
      menu && pathname && menu.includes(`/${pathname.split('/')[1]}`)
        ? 'var(--mc-color-blue-5)'
        : 'var(--mc-color-light-7)'};
  }
  &:hover {
    svg,
    div {
      color: var(--mc-color-blue-5) !important;
    }
  }
`

const IconNavItem = styled(MobileNavItem)<{ includePathname: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => (props.includePathname ? 'var(--mc-color-blue-5)' : 'var(--mc-color-light-6)')};
`

const BackLinkPopover = styled(Popover)`
  margin-bottom: 16px;
`
const HorizontalIconWrapper = styled.div`
  line-height: 10px;
  color: var(--mc-color-light-6);
`
const NavBar = styled.div`
  display: flex;
  background-color: white;
  width: 100vw;
  padding-top: 12px;
  padding-bottom: 12px;
  color: var(--mc-color-light-7);
  position: fixed;
  bottom: 0;
  left: 0;
  height: fit-content;
  justify-content: center;
  z-index: 2;
  box-shadow: 0 0 #0000, 0 0 #0000, var(--mc-theme-shadow-3);
`

const InAppRoute = styled(Link)`
  flex: 1 1 0%;
  cursor: pointer;
  text-align: center;
  &:hover {
    color: var(--mc-color-blue-5);
  }
`

const RouteName = styled.div`
  display: inline-block;
  font-weight: 500;
  line-height: 16px;
  font-size: 10px;
`

const BackLinkRouteList = styled.div`
  display: flex;
  cursor: pointer;
  text-align: center;
  flex: 1 1 0%;
  align-items: center;
  justify-content: center;
  &:hover {
    .check svg,
    .check div {
      color: var(--mc-color-blue-5);
    }
  }
`
const BacklinkRoute = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  color: var(--mc-color-light-6);
`

const BackLinkHeading = styled.div`
  font-weight: 500;
  line-height: 16px;
  font-size: 10px;
  color: var(--mc-color-light-7);
`
const PopoverContent = styled.div`
  display: flex;
  min-width: 200px;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
`
const PopoverLink = styled.a`
  padding-left: 8px;
  line-height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: var(--mc-font-weight-normal) !important;
  font-size: 12px;
  gap: 8px;
  color: var(--mc-color-light-7) !important;
  border-radius: var(--mc-space-inset-8x);
  &:hover {
    color: var(--mc-color-blue-5) !important;
    background-color: var(--mc-color-light-1);
    svg {
      color: var(--mc-color-blue-5);
    }
  }
`

const routes = {
  swap: ['/swap'],
  pool: ['/add', '/remove', '/increase', '/find', '/pool'],
  farm: ['/farm'],
}

export const MobileNavBar: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [openProductsMenu, toogleProductsMenu] = useToggle(false)
  const location = useLocation()
  const toggleOpen = () => setOpen(!open)
  return (
    <NavBar className="mobile-element-flex">
      <InAppRoute to="/swap" className={`mobile-nav-item`}>
        <IconNavItem
          includePathname={routes.swap.includes(location.pathname)}
          pathname={location.pathname}
          menu={routes.swap}
        >
          <TransferIcon size={24} />
          <RouteName>Swap</RouteName>
        </IconNavItem>
      </InAppRoute>

      <InAppRoute to="/pool" className="mobile-nav-item">
        <IconNavItem
          includePathname={routes.pool.includes(`/${location.pathname.split('/')[1]}`)}
          pathname={location.pathname}
          menu={routes.pool}
        >
          <CurrencyExchangeIcon size={24} />
          <RouteName>Pool</RouteName>
        </IconNavItem>
      </InAppRoute>
      <InAppRoute to="/farm" className="mobile-nav-item">
        <IconNavItem
          includePathname={routes.farm.includes(location.pathname)}
          menu={routes.farm}
          pathname={location.pathname}
        >
          <FilledFlashIcon size={24} />
          <RouteName>Farm</RouteName>
        </IconNavItem>
      </InAppRoute>

      <BackLinkRouteList className="mobile-nav-item" onClick={toggleOpen}>
        <BackLinkPopover
          target={
            <BacklinkRoute className="check" onClick={toggleOpen}>
              <HorizontalIconWrapper>
                <MoreHorizontalIcon size={24} />
              </HorizontalIconWrapper>

              <BackLinkHeading>More</BackLinkHeading>
            </BacklinkRoute>
          }
          open={open}
          position="top"
          onOpenChange={toggleOpen}
        >
          <PopoverContent>
            <PopoverLink id="chart" href={analyticsDomain} onClick={toggleOpen} target="_blank">
              <FilledBarChartIcon size={24} color="var(--mc-color-light-6)" />
              Analytics
            </PopoverLink>
            <PopoverLink
              id="docs"
              target="_blank"
              href="https://axie-infinity.gitbook.io/katana-docs/"
              onClick={toggleOpen}
            >
              <FilledNotebookIcon size={24} color="var(--mc-color-light-6)" />
              Docs
            </PopoverLink>
          </PopoverContent>
        </BackLinkPopover>
      </BackLinkRouteList>
    </NavBar>
  )
}
