import {
  CurrencyExchangeIcon,
  DoubleChevronDownIcon,
  FilledShieldIcon,
  InfoIcon,
  PlusIcon,
} from '@axieinfinity/mochi-icons'
import { Contract } from '@ethersproject/contracts'
import { TransactionResponse } from '@ethersproject/providers'
import { Currency, Percent } from '@uniswap/sdk-core'
import { useCallback, useContext, useMemo, useState } from 'react'
import ReactGA from 'react-ga'
import { RouteComponentProps } from 'react-router'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { ButtonError as BaseButtonError } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'

import CurrencyLogo from '../../components/CurrencyLogo'
import { WETH9_EXTENDED } from '../../constants/tokens'
import { useCurrency } from '../../hooks/Tokens'
import { usePairContract, useV2RouterContract } from '../../hooks/useContract'
import { useV2LiquidityTokenPermit } from '../../hooks/useERC20Permit'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { useActiveWeb3React } from '../../hooks/web3'

import { Button, Spinner } from '@axieinfinity/mochi'
import { BigNumber } from '@ethersproject/bignumber'
import { AppContext } from 'index'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import useDebouncedChangeHandler from '../../hooks/useDebouncedChangeHandler'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/burn/actions'
import { useBurnActionHandlers, useBurnState, useDerivedBurnInfo } from '../../state/burn/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useUserSlippageToleranceWithDefault } from '../../state/user/hooks'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { calculateSlippageAmount } from '../../utils/calculateSlippageAmount'
import { currencyId } from '../../utils/currencyId'
import AppBody from '../AppBody'
import { Wrapper } from '../Pool/styleds'

const DEFAULT_REMOVE_LIQUIDITY_SLIPPAGE_TOLERANCE = new Percent(5, 100)

const ApproveWrapper = styled.div`
  border-width: var(--mc-space-inset-x);
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-color: var(--mc-color-blue-5);
  padding: var(--mc-space-inset-12x);
  background-color: var(--mc-color-blue-1);
  border-radius: var(--mc-space-inset-8x);
  margin-top: 4px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`
const ApproveMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`
const ButtonApprove = styled(Button)`
  margin-top: 8px;
  width: 100%;
  @media (min-width: 768px) {
    margin-top: 0px;
    width: auto;
  }
`
const ButtonApproveWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
const ButtonConnect = styled(Button)`
  width: 100%;
`
const ButtonConnectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const ButtonError = styled(BaseButtonError)`
  width: 100%;
  margin-top: 8px;
`
const ButtonConfirm = styled(Button)`
  margin-top: 20px;
  width: 100%;
`

const PlusIconWrapper = styled.div`
  padding: 4px;
  border-radius: var(--mc-space-inset-8x);
  background-color: var(--mc-color-light-1);
  color: var(--mc-color-light-7);
  margin-right: var(--mc-space-inline-4x);
  margin-left: var(--mc-space-inline-4x);
  height: 32px;
`
const PlusIconWrapperAdvance = styled.div`
  display: flex;
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  background-color: var(--mc-color-light-1);
  border-radius: var(--mc-space-inset-8x);
  border-width: 1px;
  border-color: var(--mc-color-white);
  color: var(--mc-color-light-7);
  border-width: 2px;
`
const ColumnHeader = styled(AutoColumn)`
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
`
const RowOutputCurrency = styled.div`
  display: flex;
  align-items: center;
`
const OutputCurrency = styled.div`
  align-items: center;
  flex: 1 1 0%;
  background-color: var(--mc-color-light-1);
  border-radius: var(--mc-space-inset-8x);
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-bottom: 12px;
`
const CurrencySymbol = styled.span`
  color: var(--mc-color-light-7);
  font-weight: var(--mc-font-weight-bold);
  font-size: var(--mc-font-size-x-small);
  line-height: var(--mc-line-height-normal);
`
const CurrencyInfo = styled.div`
  display: flex;
  gap: 4px;
`
const CurrencyAmount = styled.span`
  font-weight: var(--mc-font-weight-semi-bold);
  font-size: var(--mc-font-size-large);
  line-height: var(--mc-line-height-x-large);
  color: var(--mc-color-green-5);
`
const RowExtraInfo = styled.div`
  display: flex;
  margin-top: 4px;
  gap: 8px;
`
const InfoText = styled.span`
  font-size: var(--mc-font-size-normal);
  color: var(--mc-color-light-7);
  line-height: var(--mc-line-height-medium);
`
const ColumnBottom = styled.div`
  margin: 24px 20px 20px;
`
const ModalContainer = styled.div`
  background-color: var(--mc-color-white);
  border-width: 1px;
  border-radius: var(--mc-space-inset-8x);
  border-color: var(--mc-color-light-3);
  padding: var(--mc-space-inset-12x);
`
const ModalTitle = styled.div`
  color: var(--mc-color-light-7);
  display: flex;
  font-weight: var(--mc-font-weight-bold);
  font-size: var(--mc-font-size-x-small);
  line-height: var(--mc-line-height-normal);
  text-transform: uppercase;
  gap: 4px;
`
const TextWithMargin = styled.span`
  margin-left: 4px;
`
const BurnedInfo = styled.div`
  background-color: var(--mc-color-light-2);
  display: flex;
  border-radius: var(--mc-space-inset-8x);
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 8px;
  margin-bottom: 20px;
  width: max-content;
`
const BurnedAmount = styled.span`
  font-weight: var(--mc-font-weight-bold);
  font-size: var(--mc-font-size-normal);
  line-height: var(--mc-line-height-medium);
  color: var(--mc-color-light-7);
  padding-top: 4px;
  padding-bottom: 4px;
`

const ModalPriceInfo = styled.div`
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  gap: 8px;
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 12px;
  }
`

const ModalPriceCard = styled.div`
  background-color: var(--mc-color-light-2);
  display: flex;
  border-radius: var(--mc-space-inset-8x);
  padding: 4px 16px;
  color: var(--mc-color-light-7);
  flex: 1 1 0%;
  gap: 4px;
`
const ExchangeFrom = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 4px;
`
const ExchangeTo = styled.div`
  display: flex;
  align-items: center;
  width: min-content;
`

const Container = styled(Wrapper)`
  padding-top: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
`
const Title = styled.label`
  color: var(--mc-color-light-7);
  font-weight: var(--mc-font-weight-semi-bold);
  font-size: var(--mc-font-size-medium);
  line-height: var(--mc-line-height-large);
`
const Body = styled(AutoColumn)`
  margin-top: 8px;
`
const TitleWrapper = styled.div`
  margin-bottom: 8px;
`

const PlusIconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  height: 32px;
  margin-top: -12px;
  margin-bottom: -12px;
  z-index: 1;
`

const ExchangePriceCard = styled.div`
  flex: 1 1 0%;
  border-radius: var(--mc-space-inset-8x);
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  background-color: var(--mc-color-light-2);
  align-items: center;
  justify-content: center;
  gap: 4px;
`
const ExchangePriceContainer = styled.div`
  padding: var(--mc-space-inset-12x);
  background-color: var(--mc-color-white);
  border-width: var(--mc-space-inset-x);
  border-color: var(--mc-color-light-3);
  border-radius: var(--mc-space-inset-8x);
`
const ExchangePriceBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  color: var(--mc-color-light-7);
  gap: 8px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`
const ExchangePriceTitle = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: 12px;
  font-size: var(--mc-font-size-x-small);
  line-height: var(--mc-line-height-normal);
  text-transform: uppercase;
  color: var(--mc-color-light-7);
  font-weight: var(--mc-font-weight-bold);
  //
`

const BottomButton = styled.div`
  position: relative;
`
const PositionCard = styled(AutoColumn)`
  width: 100%;
  max-width: 480px;
  margin-top: 1rem;
`
export default function RemoveLiquidity({
  history,
  match: {
    params: { currencyIdA, currencyIdB },
  },
}: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
  const [currencyA, currencyB] = [useCurrency(currencyIdA) ?? undefined, useCurrency(currencyIdB) ?? undefined]
  const { account, chainId, library } = useActiveWeb3React()
  const [tokenA, tokenB] = useMemo(() => [currencyA?.wrapped, currencyB?.wrapped], [currencyA, currencyB])
  const { pendingLogin } = useContext(AppContext)

  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // burn state
  const { independentField, typedValue } = useBurnState()
  const { pair, parsedAmounts, error } = useDerivedBurnInfo(currencyA ?? undefined, currencyB ?? undefined)
  const { onUserInput: _onUserInput } = useBurnActionHandlers()
  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showDetailed, setShowDetailed] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false) // clicked confirm

  // txn values
  const [txHash, setTxHash] = useState<string>('')
  const deadline = useTransactionDeadline()
  const allowedSlippage = useUserSlippageToleranceWithDefault(DEFAULT_REMOVE_LIQUIDITY_SLIPPAGE_TOLERANCE)

  const formattedAmounts = {
    [Field.LIQUIDITY_PERCENT]: parsedAmounts[Field.LIQUIDITY_PERCENT].equalTo('0')
      ? '0'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].lessThan(new Percent('1', '100'))
      ? '<1'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
    [Field.LIQUIDITY]:
      independentField === Field.LIQUIDITY ? typedValue : parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) ?? '',
    [Field.CURRENCY_A]:
      independentField === Field.CURRENCY_A ? typedValue : parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    [Field.CURRENCY_B]:
      independentField === Field.CURRENCY_B ? typedValue : parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? '',
  }

  const atMaxAmount = parsedAmounts[Field.LIQUIDITY_PERCENT]?.equalTo(new Percent('1'))

  // pair contract
  const pairContract: Contract | null = usePairContract(pair?.liquidityToken?.address)

  const router = useV2RouterContract()

  // allowance handling
  const { gatherPermitSignature, signatureData } = useV2LiquidityTokenPermit(
    parsedAmounts[Field.LIQUIDITY],
    router?.address
  )
  const [approval, approveCallback] = useApproveCallback(parsedAmounts[Field.LIQUIDITY], router?.address)

  async function onAttemptToApprove() {
    if (!pairContract || !pair || !library || !deadline) throw new Error('missing dependencies')
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
    if (!liquidityAmount) throw new Error('missing liquidity amount')

    if (gatherPermitSignature) {
      try {
        await gatherPermitSignature()
      } catch (error) {
        // try to approve if gatherPermitSignature failed for any reason other than the user rejecting it
        if ((error as any)?.code !== 4001) {
          await approveCallback()
        }
      }
    } else {
      await approveCallback()
    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      return _onUserInput(field, typedValue)
    },
    [_onUserInput]
  )

  const onLiquidityInput = useCallback((typedValue: string): void => onUserInput(Field.LIQUIDITY, typedValue), [
    onUserInput,
  ])
  const onCurrencyAInput = useCallback((typedValue: string): void => onUserInput(Field.CURRENCY_A, typedValue), [
    onUserInput,
  ])
  const onCurrencyBInput = useCallback((typedValue: string): void => onUserInput(Field.CURRENCY_B, typedValue), [
    onUserInput,
  ])

  // tx sending
  const addTransaction = useTransactionAdder()

  async function onRemove() {
    if (!chainId || !library || !account || !deadline || !router) throw new Error('missing dependencies')
    const { [Field.CURRENCY_A]: currencyAmountA, [Field.CURRENCY_B]: currencyAmountB } = parsedAmounts
    if (!currencyAmountA || !currencyAmountB) {
      throw new Error('missing currency amounts')
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(currencyAmountA, allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(currencyAmountB, allowedSlippage)[0],
    }

    if (!currencyA || !currencyB) throw new Error('missing tokens')
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
    if (!liquidityAmount) throw new Error('missing liquidity amount')

    const currencyBIsETH = currencyB.isNative
    const oneCurrencyIsETH = currencyA.isNative || currencyBIsETH

    if (!tokenA || !tokenB) throw new Error('could not wrap')

    let methodNames: string[], args: Array<string | string[] | number | boolean>
    // we have approval, use normal remove liquidity
    if (approval === ApprovalState.APPROVED) {
      // removeLiquidityRON
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityRON', 'removeLiquidityRONSupportingFeeOnTransferTokens']
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          account,
          deadline.toHexString(),
        ]
      }
      // removeLiquidity
      else {
        methodNames = ['removeLiquidity']
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          deadline.toHexString(),
        ]
      }
    }
    // we have a signature, use permit versions of remove liquidity
    else if (signatureData !== null) {
      // removeLiquidityRONWithPermit
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityRONWithPermit', 'removeLiquidityRONWithPermitSupportingFeeOnTransferTokens']
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          account,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s,
        ]
      }
      // removeLiquidityRONWithPermit
      else {
        methodNames = ['removeLiquidityWithPermit']
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s,
        ]
      }
    } else {
      throw new Error('Attempting to confirm without approval or a signature. Please contact support.')
    }

    const safeGasEstimates: (BigNumber | undefined)[] = await Promise.all(
      methodNames.map((methodName) =>
        router.estimateGas[methodName](...args)
          .then(calculateGasMargin)
          .catch((error) => {
            console.error(`estimateGas failed`, methodName, args, error)
            return undefined
          })
      )
    )

    const indexOfSuccessfulEstimation = safeGasEstimates.findIndex((safeGasEstimate) =>
      BigNumber.isBigNumber(safeGasEstimate)
    )

    // all estimations failed...
    if (indexOfSuccessfulEstimation === -1) {
      console.error('This transaction would fail. Please contact support.')
    } else {
      const methodName = methodNames[indexOfSuccessfulEstimation]
      const safeGasEstimate = safeGasEstimates[indexOfSuccessfulEstimation]

      setAttemptingTxn(true)
      await router[methodName](...args, {
        gasLimit: safeGasEstimate,
      })
        .then((response: TransactionResponse) => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary: `Remove ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)} ${
              currencyA?.symbol
            } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)} ${currencyB?.symbol}`,
          })

          setTxHash(response.hash)

          ReactGA.event({
            category: 'Liquidity',
            action: 'Remove',
            label: [currencyA?.symbol, currencyB?.symbol].join('/'),
          })
        })
        .catch((error: Error) => {
          setAttemptingTxn(false)
          // we only care if the error is something _other_ than the user rejected the tx
          console.error(error)
        })
    }
  }

  function modalHeader() {
    return (
      <ColumnHeader gap={'md'}>
        <RowOutputCurrency>
          <OutputCurrency>
            <CurrencySymbol>{currencyA?.symbol}</CurrencySymbol>
            <CurrencyInfo>
              <CurrencyLogo currency={currencyA} size="28px" />
              <CurrencyAmount>{parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}</CurrencyAmount>
            </CurrencyInfo>
          </OutputCurrency>
          <PlusIconWrapper>
            <PlusIcon size={24} />
          </PlusIconWrapper>
          <OutputCurrency>
            <CurrencySymbol>{currencyB?.symbol}</CurrencySymbol>
            <CurrencyInfo>
              <CurrencyLogo currency={currencyB} size="28px" />
              <CurrencyAmount>{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}</CurrencyAmount>
            </CurrencyInfo>
          </OutputCurrency>
        </RowOutputCurrency>
        {/* <RowBetween align="flex-end">
          <Text fontSize={24} fontWeight={500}>
            {parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}
          </Text>
          <RowFixed gap="4px">
            <CurrencyLogo currency={currencyA} size={'24px'} />
            <Text fontSize={24} fontWeight={500} style={{ marginLeft: '10px' }}>
              {currencyA?.symbol}
            </Text>
          </RowFixed>
        </RowBetween> */}
        {/* <RowFixed>
          <PlusIcon size={24} className="text-basic-7" />
        </RowFixed> */}
        {/* <RowBetween align="flex-end">
          <Text fontSize={24} fontWeight={500}>
            {parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}
          </Text>
          <RowFixed gap="4px">
            <CurrencyLogo currency={currencyB} size={'24px'} />
            <Text fontSize={24} fontWeight={500} style={{ marginLeft: '10px' }}>
              {currencyB?.symbol}
            </Text>
          </RowFixed>
        </RowBetween> */}

        {/* <TYPE.italic fontSize={12} color={theme.text2} textAlign="left" padding={'12px 0 0 0'}> */}
        {/* </TYPE.italic> */}
        <RowExtraInfo>
          <div>
            <InfoIcon size={16} color={'var(--mc-color-light-7)'} />
          </div>
          <InfoText>
            {/* <Trans> */}
            Output is estimated. If the price changes by more than {allowedSlippage.toSignificant(4)}% your transaction
            will revert.
            {/* </Trans> */}
          </InfoText>
        </RowExtraInfo>
      </ColumnHeader>
    )
  }

  function modalBottom() {
    return (
      <ColumnBottom>
        <ModalContainer>
          <ModalTitle>
            <DoubleChevronDownIcon size={16} />
            {currencyA?.symbol}/{currencyB?.symbol} burned
          </ModalTitle>
          <BurnedInfo>
            <DoubleCurrencyLogo currency0={currencyA} currency1={currencyB} size={20} />
            <BurnedAmount>{parsedAmounts[Field.LIQUIDITY]?.toSignificant(6)}</BurnedAmount>
          </BurnedInfo>
          <ModalTitle>
            <CurrencyExchangeIcon size={16} />
            <TextWithMargin>current price</TextWithMargin>
          </ModalTitle>
          {pair && (
            <ModalPriceInfo>
              <ModalPriceCard>
                <ExchangeFrom>
                  <CurrencyLogo currency={currencyA} size="20px" />
                  <div>
                    <strong>1 {currencyA?.symbol}</strong> =
                  </div>
                </ExchangeFrom>
                <ExchangeTo>
                  {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'} {currencyB?.symbol}
                </ExchangeTo>
              </ModalPriceCard>
              <ModalPriceCard>
                <ExchangeFrom>
                  <CurrencyLogo currency={currencyB} size="20px" />
                  <div>
                    <strong>1 {currencyB?.symbol}</strong> =
                  </div>
                </ExchangeFrom>
                <ExchangeTo>
                  {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'} {currencyA?.symbol}
                </ExchangeTo>
              </ModalPriceCard>
            </ModalPriceInfo>
          )}
        </ModalContainer>
        {/* <RowBetween>
          <Text color={theme.text2} fontWeight={500} fontSize={16}>
            <Trans>
              RON {currencyA?.symbol}/{currencyB?.symbol} Burned
            </Trans>
          </Text>
          <RowFixed>
            <DoubleCurrencyLogo currency0={currencyA} currency1={currencyB} margin={true} />
            <Text fontWeight={500} fontSize={16}>
              {parsedAmounts[Field.LIQUIDITY]?.toSignificant(6)}
            </Text>
          </RowFixed>
        </RowBetween>
        {pair && (
          <>
            <RowBetween>
              <Text color={theme.text2} fontWeight={500} fontSize={16}>
                <Trans>Price</Trans>
              </Text>
              <Text fontWeight={500} fontSize={16} color={theme.text1}>
                1 {currencyA?.symbol} = {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'} {currencyB?.symbol}
              </Text>
            </RowBetween>
            <RowBetween>
              <div />
              <Text fontWeight={500} fontSize={16} color={theme.text1}>
                1 {currencyB?.symbol} = {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'} {currencyA?.symbol}
              </Text>
            </RowBetween>
          </>
        )} */}
        {/* <ButtonPrimary disabled={!(approval === ApprovalState.APPROVED || signatureData !== null)} onClick={onRemove}> */}
        <ButtonConfirm
          size="large"
          disabled={!(approval === ApprovalState.APPROVED || signatureData !== null)}
          onClick={onRemove}
        >
          {/* <Text fontWeight={500} fontSize={20}> */}
          {/* <Trans> */}Confirm{/* </Trans> */}
          {/* </Text> */}
        </ButtonConfirm>
        {/* </ButtonPrimary> */}
      </ColumnBottom>
    )
  }

  // const pendingText = t`Removing ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
  //   currencyA?.symbol
  // } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencyB?.symbol}`
  const pendingText = `Burning ${formattedAmounts[Field.LIQUIDITY]} ${currencyA?.symbol}/${currencyB?.symbol}`

  const liquidityPercentChangeCallback = useCallback(
    (value: number) => {
      onUserInput(Field.LIQUIDITY_PERCENT, value.toString())
    },
    [onUserInput]
  )

  const oneCurrencyIsETH = currencyA?.isNative || currencyB?.isNative
  const oneCurrencyIsWETH = Boolean(
    chainId &&
      WETH9_EXTENDED[chainId] &&
      (currencyA?.equals(WETH9_EXTENDED[chainId]) || currencyB?.equals(WETH9_EXTENDED[chainId]))
  )

  const handleSelectCurrencyA = useCallback(
    (currency: Currency) => {
      if (currencyIdB && currencyId(currency) === currencyIdB) {
        history.push(`/remove/${currencyId(currency)}/${currencyIdA}`)
      } else {
        history.push(`/remove/${currencyId(currency)}/${currencyIdB}`)
      }
    },
    [currencyIdA, currencyIdB, history]
  )
  const handleSelectCurrencyB = useCallback(
    (currency: Currency) => {
      if (currencyIdA && currencyId(currency) === currencyIdA) {
        history.push(`/remove/${currencyIdB}/${currencyId(currency)}`)
      } else {
        history.push(`/remove/${currencyIdA}/${currencyId(currency)}`)
      }
    },
    [currencyIdA, currencyIdB, history]
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.LIQUIDITY_PERCENT, '0')
    }
    setTxHash('')
  }, [onUserInput, txHash])

  const [innerLiquidityPercentage, setInnerLiquidityPercentage] = useDebouncedChangeHandler(
    Number.parseInt(parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0)),
    liquidityPercentChangeCallback
  )
  return (
    <>
      <AppBody>
        <AddRemoveTabs creating={false} adding={false} defaultSlippage={DEFAULT_REMOVE_LIQUIDITY_SLIPPAGE_TOLERANCE} />
        <Container>
          <Title htmlFor="liquidity-amount">
            {/* <Trans> */}Remove Amount{/* </Trans> */}
          </Title>
          <TransactionConfirmationModal
            isOpen={showConfirm}
            onDismiss={handleDismissConfirmation}
            attemptingTxn={attemptingTxn}
            hash={txHash ? txHash : ''}
            hasRelatedIcon={false}
            maxWidth={txHash ? undefined : 520}
            content={() => (
              <ConfirmationModalContent
                title={
                  <>
                    {/* <Trans> */}You will receive{/* </Trans> */}
                  </>
                }
                onDismiss={handleDismissConfirmation}
                topContent={modalHeader}
                hasHeaderLine
                padding={0}
                bottomContent={modalBottom}
              />
            )}
            trade={{ tokenA: currencyA, tokenB: currencyB }}
            pendingText={pendingText}
          />
          <Body gap="md">
            {/* <BlueCard>
              <AutoColumn gap="10px">
                <TYPE.link fontWeight={400} color={'primaryText1'}>
                  <Trans>
                    <b>Tip:</b> Removing pool tokens converts your position back into underlying tokens at the current
                    rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive.
                  </Trans>
                </TYPE.link>
              </AutoColumn>
            </BlueCard> */}
            {/* <LightCard> */}
            {/* <AutoColumn gap="20px"> */}
            {/* <RowBetween>
                  <Text fontWeight={500}>
                     <Trans>Remove Amount </Trans>
                  </Text>
                  <ClickableText
                    fontWeight={500}
                    onClick={() => {
                      setShowDetailed(!showDetailed)
                    }}
                  >
                    {showDetailed ?  <Trans>Simple</Trans> : <Trans>Detailed </Trans> }
                  </ClickableText>
                </RowBetween>
                <Row style={{ alignItems: 'flex-end' }}>
                  <Text fontSize={72} fontWeight={500}>
                    {formattedAmounts[Field.LIQUIDITY_PERCENT]}%
                  </Text>
                </Row> */}
            {/* {!showDetailed && (
                  <>
                    <Slider value={innerLiquidityPercentage} onChange={setInnerLiquidityPercentage} />
                    <RowBetween>
                      <MaxButton onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '25')} width="20%">
                        25%
                      </MaxButton>
                      <MaxButton onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '50')} width="20%">
                        50%
                      </MaxButton>
                      <MaxButton onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '75')} width="20%">
                        75%
                      </MaxButton>
                      <MaxButton onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')} width="20%">
                        Max
                      </MaxButton>
                    </RowBetween>
                  </>
                )} */}
            {/* </AutoColumn> */}
            {/* </LightCard> */}
            {/* {!showDetailed && (
              <>
                <ColumnCenter>
                  <ArrowDown size="16" color={theme.text2} />
                </ColumnCenter>
                <LightCard>
                  <AutoColumn gap="10px">
                    <RowBetween>
                      <Text fontSize={24} fontWeight={500}>
                        {formattedAmounts[Field.CURRENCY_A] || '-'}
                      </Text>
                      <RowFixed>
                        <CurrencyLogo currency={currencyA} style={{ marginRight: '12px' }} />
                        <Text fontSize={24} fontWeight={500} id="remove-liquidity-tokena-symbol">
                          {currencyA?.symbol}
                        </Text>
                      </RowFixed>
                    </RowBetween>
                    <RowBetween>
                      <Text fontSize={24} fontWeight={500}>
                        {formattedAmounts[Field.CURRENCY_B] || '-'}
                      </Text>
                      <RowFixed>
                        <CurrencyLogo currency={currencyB} style={{ marginRight: '12px' }} />
                        <Text fontSize={24} fontWeight={500} id="remove-liquidity-tokenb-symbol">
                          {currencyB?.symbol}
                        </Text>
                      </RowFixed>
                    </RowBetween>
                    {chainId && (oneCurrencyIsWETH || oneCurrencyIsETH) ? (
                      <RowBetween style={{ justifyContent: 'flex-end' }}>
                        {oneCurrencyIsETH ? (
                          <StyledInternalLink
                            to={`/remove/v2/${currencyA?.isNative ? WETH9_EXTENDED[chainId].address : currencyIdA}/${
                              currencyB?.isNative ? WETH9_EXTENDED[chainId].address : currencyIdB
                            }`}
                          >
                            Receive WETH
                          </StyledInternalLink>
                        ) : oneCurrencyIsWETH ? (
                          <StyledInternalLink
                            to={`/remove/v2/${currencyA?.equals(WETH9_EXTENDED[chainId]) ? 'ETH' : currencyIdA}/${
                              currencyB?.equals(WETH9_EXTENDED[chainId]) ? 'ETH' : currencyIdB
                            }`}
                          >
                            Receive ETH
                          </StyledInternalLink>
                        ) : null}
                      </RowBetween>
                    ) : null}
                  </AutoColumn>
                </LightCard>
              </>
            )} */}

            {/* {showDetailed && ( */}
            <>
              <CurrencyInputPanel
                value={formattedAmounts[Field.LIQUIDITY]}
                onUserInput={onLiquidityInput}
                onMax={() => {
                  onUserInput(Field.LIQUIDITY_PERCENT, '100')
                }}
                showMaxButton={!atMaxAmount}
                currency={pair?.liquidityToken}
                pair={pair}
                isDeposit
                id="liquidity-amount"
              />
              {/* <ColumnCenter>
                <ArrowDown size="16" color={theme.text2} />
              </ColumnCenter> */}
              <div>
                <TitleWrapper>
                  <Title>
                    {/* <Trans> */}You will receive{/* </Trans> */}
                  </Title>
                </TitleWrapper>
                <CurrencyInputPanel
                  hideBalance={true}
                  value={formattedAmounts[Field.CURRENCY_A]}
                  onUserInput={onCurrencyAInput}
                  onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                  showMaxButton={!atMaxAmount}
                  currency={currencyA}
                  label={'Output'}
                  isReceiving
                  onCurrencySelect={handleSelectCurrencyA}
                  id="remove-liquidity-tokena"
                />

                {/* <ColumnCenter> */}
                <PlusIconContainer>
                  <PlusIconWrapperAdvance>
                    <PlusIcon size={24} />
                  </PlusIconWrapperAdvance>
                </PlusIconContainer>
                {/* </ColumnCenter> */}
                <CurrencyInputPanel
                  hideBalance={true}
                  value={formattedAmounts[Field.CURRENCY_B]}
                  onUserInput={onCurrencyBInput}
                  onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                  showMaxButton={!atMaxAmount}
                  currency={currencyB}
                  label={'Output'}
                  isReceiving
                  onCurrencySelect={handleSelectCurrencyB}
                  id="remove-liquidity-tokenb"
                />
              </div>
            </>
            {/* )} */}
            {pair && (
              <ExchangePriceContainer>
                <ExchangePriceTitle>
                  <CurrencyExchangeIcon size={16} />
                  Prices
                </ExchangePriceTitle>
                <ExchangePriceBody>
                  <ExchangePriceCard>
                    <ExchangeFrom>
                      <CurrencyLogo currency={currencyA} />
                      <strong>{currencyA?.symbol}</strong> ={' '}
                    </ExchangeFrom>
                    {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'} {currencyB?.symbol}
                  </ExchangePriceCard>
                  <ExchangePriceCard>
                    <ExchangeFrom>
                      <CurrencyLogo currency={currencyB} />
                      <strong>{currencyB?.symbol}</strong>={' '}
                    </ExchangeFrom>
                    {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'} {currencyA?.symbol}
                  </ExchangePriceCard>
                </ExchangePriceBody>
                {/* <RowBetween>
                  <Trans>Price:</Trans>
                  <div>
                    1 {currencyA?.symbol} = {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'} {currencyB?.symbol}
                  </div>
                </RowBetween>
                <RowBetween>
                  <div />
                  <div>
                    1 {currencyB?.symbol} = {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'} {currencyA?.symbol}
                  </div>
                </RowBetween> */}
              </ExchangePriceContainer>
            )}
            <BottomButton>
              {!account ? (
                <ButtonConnect size="large" disabled={pendingLogin} onClick={toggleWalletModal} intent="secondary">
                  <ButtonConnectWrapper>
                    {pendingLogin && <Spinner intent="primary" size="small" />}
                    Connect Ronin Wallet
                  </ButtonConnectWrapper>
                </ButtonConnect>
              ) : (
                <>
                  {(approval === ApprovalState.PENDING ||
                    !(approval === ApprovalState.APPROVED || signatureData !== null)) &&
                    approval !== ApprovalState.UNKNOWN &&
                    formattedAmounts[Field.LIQUIDITY] &&
                    formattedAmounts[Field.LIQUIDITY] !== '0' && (
                      <ApproveWrapper>
                        <ApproveMessage>
                          <FilledShieldIcon size={24} color={'var(--mc-color-blue-5)'} />
                          <span>
                            Allow Katana to use your {currencyA?.symbol}/{currencyB?.symbol}
                          </span>
                        </ApproveMessage>
                        {/* <Button
                          size={Button.sizes.Small}
                          intent="primary"
                          className="mt-8 md:mt-0 w-full md:w-auto"
                          onClick={onAttemptToApprove}
                        >
                          Approve
                          {approval === ApprovalState.PENDING ? (
                            <span className="ml-8 basic-circular">
                              <CircularProgress size={CircularProgress.sizes.Small} />
                            </span>
                          ) : undefined}
                        </Button> */}

                        <ButtonApprove intent="primary" onClick={onAttemptToApprove} size="small">
                          {/* <Trans>Approve {currencies[Field.INPUT]?.symbol}</Trans> */}
                          <ButtonApproveWrapper>
                            Approve
                            {approval === ApprovalState.PENDING ? <Spinner intent="primary" size="small" /> : undefined}
                          </ButtonApproveWrapper>
                        </ButtonApprove>
                      </ApproveWrapper>
                    )}
                  {/* <RowBetween> */}
                  {/* <ButtonConfirmed
                      onClick={onAttemptToApprove}
                      confirmed={approval === ApprovalState.APPROVED || signatureData !== null}
                      disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
                      mr="0.5rem"
                      fontWeight={500}
                      fontSize={16}
                    >
                      {approval === ApprovalState.PENDING ? (
                        <Dots>
                          <Trans>Approving</Trans>
                        </Dots>
                      ) : approval === ApprovalState.APPROVED || signatureData !== null ? (
                         <Trans> Approved </Trans>
                      ) : (
                        <Trans>Approve</Trans>
                      )}
                    </ButtonConfirmed> */}
                  {(error || approval === ApprovalState.APPROVED) && (
                    <ButtonError
                      onClick={() => {
                        setShowConfirm(true)
                      }}
                      disabled={error || !isValid || (signatureData === null && approval !== ApprovalState.APPROVED)}
                      error={
                        error || (!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B])
                      }
                      size="large"
                    >
                      <Text fontSize={16} fontWeight={500}>
                        {error || (
                          <>
                            {' '}
                            {/* <Trans> */}Remove{/* </Trans> */}
                          </>
                        )}
                      </Text>
                    </ButtonError>
                  )}
                  {/* </RowBetween> */}
                </>
              )}
            </BottomButton>
          </Body>
        </Container>
      </AppBody>

      {pair ? (
        <PositionCard>
          <MinimalPositionCard showUnwrapped={oneCurrencyIsWETH} pair={pair} />
        </PositionCard>
      ) : null}
    </>
  )
}
