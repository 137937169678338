import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { Currency, CurrencyAmount, Percent } from '@uniswap/sdk-core'
import { useCallback, useContext, useState } from 'react'
import ReactGA from 'react-ga'
import { RouteComponentProps } from 'react-router-dom'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { ButtonError } from '../../components/Button'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import Row, { RowFlat, RowFlatNoLiquid } from '../../components/Row'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'

import { ZERO_PERCENT } from '../../constants/misc'
import { RONIN_WETH, tokenPairsByChain, WETH9_EXTENDED } from '../../constants/tokens'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useV2RouterContract } from '../../hooks/useContract'
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { PairState } from '../../hooks/useV2Pairs'
import { useActiveWeb3React } from '../../hooks/web3'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/mint/actions'
import { useDerivedMintInfo, useMintActionHandlers, useMintState } from '../../state/mint/hooks'

import { Button, Spinner } from '@axieinfinity/mochi'

import { CurrencyExchangeIcon, FilledShieldIcon, PlusIcon } from '@axieinfinity/mochi-icons'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import { AppContext } from 'index'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useIsExpertMode, useUserSlippageToleranceWithDefault } from '../../state/user/hooks'
import { TYPE } from '../../theme'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { calculateSlippageAmount } from '../../utils/calculateSlippageAmount'
import { currencyId } from '../../utils/currencyId'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import AppBody from '../AppBody'
import { Wrapper } from '../Pool/styleds'
import { ConfirmAddModalBottom } from './ConfirmAddModalBottom'
import { PoolPriceBar } from './PoolPriceBar'
import { ThirdPartyWarning } from 'components/ThirdPartyWarning/ThirdPartyWarning'

const DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)

const PlusIconWrapper = styled.div`
  padding: var(--mc-space-inset-4x);
  border-radius: var(--mc-space-inset-8x);
  background-color: var(--mc-color-light-1);
  height: 32px;
`
const ModalHeaderContainer = styled(AutoColumn)`
  padding: var(--mc-space-stack-20x) var(--mc-space-inline-20x) 0;
`
const LogoWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--mc-space-stack-8x);
`
const LiquidityAmount = styled(Text)`
  text-align: center;
  width: 100%;
`
const PoolTokenSymbolWrapper = styled(Row)`
  margin-bottom: var(--mc-space-stack-12x);
`
const PoolTokenSymbol = styled(Text)`
  line-height: var(--mc-line-height-x-large);
  color: var(--mc-color-light-7);
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
`
const PoolNote = styled(Text)`
  font-size: var(--mc-font-size-normal);
  line-height: var(--mc-line-height-medium);
  text-align: center;
  max-width: 406px;
  /* margin-left: auto;
  margin-right: auto; */
`
const PoolNoteWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Container = styled(Wrapper)`
  padding-top: var(--mc-space-stack-12x);
`
const PriceAndPoolShare = styled.div`
  background-color: var(--mc-color-white);
  border-width: var(--mc-space-inset-x);
  border-color: var(--mc-color-light-3);
  border-radius: var(--mc-space-inset-8x);
  padding: var(--mc-space-inset-12x);
  margin-bottom: var(--mc-space-stack-12x);
`
const HeaderPPS = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: var(--mc-space-stack-12x);
  gap: var(--mc-space-inset-4x);
  color: var(--mc-color-light-7);
`
const SubHeader = styled(TYPE.subHeader)`
  text-transform: uppercase;
  line-height: var(--mc-line-height-normal);
`
const ButtonConnect = styled(Button)`
  width: 100%;
`
const ButtonConnectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--mc-space-inset-4x);
`
//div className=" justify-between flex-wrap items-center border-primary-5 p-12 bg-primary-1 rounded flex mt-4"
const ApproveWrapper = styled.div`
  border-width: var(--mc-space-inset-x);
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-color: var(--mc-color-blue-5);
  padding: var(--mc-space-inset-12x);
  background-color: var(--mc-color-blue-1);
  border-radius: var(--mc-space-inset-8x);
  margin-top: var(--mc-space-stack-4x);
`
const ApproveMessage = styled.div`
  display: flex;
  align-items: center;
  gap: var(--mc-space-stack-12x);
`
const ButtonApprove = styled(Button)`
  //flex: 1 1 0%;
  margin-top: var(--mc-space-stack-8x);
  width: 100%;
  @media (min-width: 768px) {
    margin-top: 0;
    //flex-grow: 0;
    width: auto;
  }
`
const ButtonApproveWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const PositionCardContainer = styled(AutoColumn)`
  min-width: 20rem;
  width: 100%;
  max-width: 400px;
  margin-top: 1rem;
`
export default function AddLiquidity({
  match: {
    params: { currencyIdA, currencyIdB },
  },
  history,
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  const { account, chainId, library } = useActiveWeb3React()
  const theme = useContext(ThemeContext)
  const { pendingLogin } = useContext(AppContext)

  const currencyA = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)
  const pairs = tokenPairsByChain(chainId)

  const oneCurrencyIsWRON = Boolean(
    chainId &&
      ((currencyA && currencyA.equals(WETH9_EXTENDED[chainId])) ||
        (currencyB && currencyB.equals(WETH9_EXTENDED[chainId])))
  )

  const oneCurrencyIsWETH = Boolean(
    chainId &&
      ((currencyA && currencyA.equals(RONIN_WETH[chainId])) || (currencyB && currencyB.equals(RONIN_WETH[chainId])))
  )

  const checkIsInvalidPair = () => {
    if (!currencyB) return false
    const pair = pairs && currencyB.symbol && pairs[currencyB.symbol || 'none']
    return !pair?.includes(currencyA?.symbol || 'none')
  }

  const isInvalidPair = checkIsInvalidPair()

  const toggleWalletModal = useWalletModalToggle() // toggle wallet when disconnected

  const expertMode = useIsExpertMode()

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState()
  const {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error,
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined)

  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity)

  // const isValid = !error && oneCurrencyIsWETH
  const isValid = !error && !isInvalidPair
  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  // txn values
  const deadline = useTransactionDeadline() // custom from users settings
  const allowedSlippage = useUserSlippageToleranceWithDefault(DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE) // custom from users
  const [txHash, setTxHash] = useState<string>('')

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field]),
      }
    },
    {}
  )

  const atMaxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
      }
    },
    {}
  )

  const router = useV2RouterContract()

  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_A], router?.address)
  const [approvalB, approveBCallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_B], router?.address)

  const addTransaction = useTransactionAdder()

  async function onAdd() {
    if (!chainId || !library || !account || !router) return

    const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts
    if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB || !deadline) {
      return
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(parsedAmountA, noLiquidity ? ZERO_PERCENT : allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(parsedAmountB, noLiquidity ? ZERO_PERCENT : allowedSlippage)[0],
    }

    let estimate,
      method: (...args: any) => Promise<TransactionResponse>,
      args: Array<string | string[] | number>,
      value: BigNumber | null
    if (currencyA.isNative || currencyB.isNative) {
      const tokenBIsETH = currencyB.isNative
      estimate = router.estimateGas.addLiquidityRON
      method = router.addLiquidityRON
      args = [
        (tokenBIsETH ? currencyA : currencyB)?.wrapped?.address ?? '', // token
        (tokenBIsETH ? parsedAmountA : parsedAmountB).quotient.toString(), // token desired
        amountsMin[tokenBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(), // token min
        amountsMin[tokenBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(), // eth min
        account,
        deadline.toHexString(),
      ]
      value = BigNumber.from((tokenBIsETH ? parsedAmountB : parsedAmountA).quotient.toString())
    } else {
      estimate = router.estimateGas.addLiquidity
      method = router.addLiquidity
      args = [
        currencyA?.wrapped?.address ?? '',
        currencyB?.wrapped?.address ?? '',
        parsedAmountA.quotient.toString(),
        parsedAmountB.quotient.toString(),
        amountsMin[Field.CURRENCY_A].toString(),
        amountsMin[Field.CURRENCY_B].toString(),
        account,
        deadline.toHexString(),
      ]
      value = null
    }

    setAttemptingTxn(true)
    await estimate(...args, value ? { value } : {})
      .then((estimatedGasLimit) =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit),
        }).then((response) => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary: `Add ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)} ${
              currencies[Field.CURRENCY_A]?.symbol
            } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)} ${currencies[Field.CURRENCY_B]?.symbol}`,
          })

          setTxHash(response.hash)

          ReactGA.event({
            category: 'Liquidity',
            action: 'Add',
            label: [currencies[Field.CURRENCY_A]?.symbol, currencies[Field.CURRENCY_B]?.symbol].join('/'),
          })
        })
      )
      .catch((error) => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  const modalHeader = () => {
    return noLiquidity ? (
      <AutoColumn gap="20px">
        <RowFlatNoLiquid>
          <DoubleCurrencyLogo
            currency0={currencies[Field.CURRENCY_A]}
            currency1={currencies[Field.CURRENCY_B]}
            size={64}
          />
          <Text fontSize="28px" fontWeight={700} lineHeight="40px">
            {currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol}
          </Text>
        </RowFlatNoLiquid>
      </AutoColumn>
    ) : (
      <ModalHeaderContainer>
        <LogoWrapper>
          <DoubleCurrencyLogo
            margin
            currency0={currencies[Field.CURRENCY_A]}
            currency1={currencies[Field.CURRENCY_B]}
            size={64}
          />
        </LogoWrapper>
        <RowFlat>
          <LiquidityAmount fontSize="28px" fontWeight={700} lineHeight="40px">
            {formatCurrencyAmount(liquidityMinted)}
          </LiquidityAmount>
        </RowFlat>
        <PoolTokenSymbolWrapper>
          <PoolTokenSymbol fontSize="20px" fontWeight={600}>
            {currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol + ' Pool Tokens'}
          </PoolTokenSymbol>
        </PoolTokenSymbolWrapper>
        <PoolNoteWrapper>
          <PoolNote>
            {/* <Trans> */}
            Output is estimated. If the price changes by more than {allowedSlippage.toSignificant(4)}% your transaction
            will revert.
            {/* </Trans> */}
          </PoolNote>
        </PoolNoteWrapper>
      </ModalHeaderContainer>
    )
  }

  const modalBottom = () => {
    return (
      <ConfirmAddModalBottom
        price={price}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        onAdd={onAdd}
        poolTokenPercentage={poolTokenPercentage}
      />
    )
  }

  const pendingText = `Supplying ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
    currencies[Field.CURRENCY_A]?.symbol
  } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencies[Field.CURRENCY_B]?.symbol}`

  const handleCurrencyASelect = useCallback(
    (currencyA: Currency) => {
      const newCurrencyIdA = currencyId(currencyA)
      if (newCurrencyIdA === currencyIdB) {
        history.push(`/add/${currencyIdB}/${currencyIdA}`)
      } else {
        history.push(`/add/${newCurrencyIdA}/${currencyIdB}`)
      }
    },
    [currencyIdB, history, currencyIdA]
  )
  const handleCurrencyBSelect = useCallback(
    (currencyB: Currency) => {
      const newCurrencyIdB = currencyId(currencyB)
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          history.push(`/add/${currencyIdB}/${newCurrencyIdB}`)
        } else {
          history.push(`/add/${newCurrencyIdB}`)
        }
      } else {
        history.push(`/add/${currencyIdA ? currencyIdA : 'ETH'}/${newCurrencyIdB}`)
      }
    },
    [currencyIdA, history, currencyIdB]
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('')
    }
    setTxHash('')
  }, [onFieldAInput, txHash])

  const isCreate = history.location.pathname.includes('/create')

  const addIsUnsupported = useIsSwapUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)

  const isThirdPartyToken = currencies?.CURRENCY_A?.symbol === 'BERRY' || currencies?.CURRENCY_B?.symbol === 'BERRY'

  return (
    <>
      {isThirdPartyToken && <ThirdPartyWarning />}

      <AppBody>
        <AddRemoveTabs creating={isCreate} adding={true} defaultSlippage={DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE} />
        <Container>
          <TransactionConfirmationModal
            isOpen={showConfirm}
            onDismiss={handleDismissConfirmation}
            attemptingTxn={attemptingTxn}
            hash={txHash}
            maxWidth={attemptingTxn || txHash ? 420 : 560}
            content={() => (
              <ConfirmationModalContent
                title={noLiquidity ? 'You are creating a pool' : 'You will receive'}
                onDismiss={handleDismissConfirmation}
                topContent={modalHeader}
                bottomContent={modalBottom}
                bottomGap="0"
                padding={0}
                hasHeaderLine
              />
            )}
            pendingText={pendingText}
            currencyToAdd={pair?.liquidityToken}
            trade={{ tokenA: currencies[Field.CURRENCY_A], tokenB: currencies[Field.CURRENCY_B] }}
            isSupplying
          />
          <AutoColumn gap="8px">
            {/* {noLiquidity ||
              (isCreate ? (
                <ColumnCenter>
                  <BlueCard>
                    <AutoColumn gap="10px">
                      <TYPE.link fontWeight={600} color={'primaryText1'}>
                        <Trans>You are the first liquidity provider.</Trans>
                      </TYPE.link>
                      <TYPE.link fontWeight={400} color={'primaryText1'}>
                        <Trans>The ratio of tokens you add will set the price of this pool.</Trans>
                      </TYPE.link>
                      <TYPE.link fontWeight={400} color={'primaryText1'}>
                        <Trans>Once you are happy with the rate click supply to review.</Trans>
                      </TYPE.link>
                    </AutoColumn>
                  </BlueCard>
                </ColumnCenter>
              ) : // ) : (
              //   <ColumnCenter>
              //     <BlueCard>
              //       <AutoColumn gap="10px">
              //         <TYPE.link fontWeight={400} color={'primaryText1'}>
              //           <Trans>
              //             <b>
              //               <Trans>Tip:</Trans>
              //             </b>{' '}
              //             When you add liquidity, you will receive pool tokens representing your position. These tokens
              //             automatically earn fees proportional to your share of the pool, and can be redeemed at any
              //             time.
              //           </Trans>
              //         </TYPE.link>
              //       </AutoColumn>
              //     </BlueCard>
              //   </ColumnCenter>
              // ))}
              null)} */}
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_A]}
              onUserInput={onFieldAInput}
              onMax={() => {
                onFieldAInput(formatCurrencyAmount(maxAmounts[Field.CURRENCY_A], undefined, true).toString() ?? '')
              }}
              onCurrencySelect={handleCurrencyASelect}
              isDeposit
              showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
              currency={currencies[Field.CURRENCY_A]}
              id="add-liquidity-input-tokena"
              otherCurrency={currencies[Field.CURRENCY_B]}
              showCommonBases
            />
            <ColumnCenter>
              <PlusIconWrapper>
                <PlusIcon size={24} color={'var(--mc-color-light-7)'} />
                {/* <Plus size="16" color={theme.text2} /> */}
              </PlusIconWrapper>
            </ColumnCenter>
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_B]}
              onUserInput={onFieldBInput}
              onCurrencySelect={handleCurrencyBSelect}
              onMax={() => {
                onFieldBInput(formatCurrencyAmount(maxAmounts[Field.CURRENCY_B], undefined, true).toString() ?? '')
              }}
              showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
              currency={currencies[Field.CURRENCY_B]}
              otherCurrency={currencies[Field.CURRENCY_A]}
              id="add-liquidity-input-tokenb"
              isDeposit
              showCommonBases
            />
            {currencies[Field.CURRENCY_A] && currencies[Field.CURRENCY_B] && pairState !== PairState.INVALID && (
              <>
                {/* <LightCard padding="0px" className="mt-4" borderRadius={'20px'}> */}
                <PriceAndPoolShare>
                  {/* <RowBetween padding="1rem"> */}
                  <HeaderPPS>
                    <CurrencyExchangeIcon size={16} />
                    <SubHeader fontWeight={700} fontSize={10}>
                      {noLiquidity
                        ? // <Trans>Initial prices and pool share</Trans>
                          'Initial prices and pool share'
                        : // <Trans>Prices and pool share</Trans>
                          'Prices and pool share'}
                    </SubHeader>
                  </HeaderPPS>
                  {/* </RowBetween>{' '} */}
                  {/* <LightCard padding="1rem" borderRadius={'20px'}> */}
                  <PoolPriceBar
                    currencies={currencies}
                    poolTokenPercentage={poolTokenPercentage}
                    noLiquidity={noLiquidity}
                    price={price}
                  />
                  {/* </LightCard> */}
                </PriceAndPoolShare>
                {/* </LightCard> */}
              </>
            )}

            {addIsUnsupported ? (
              <Button disabled={true} size="large" intent="secondary">
                <TYPE.main mb="4px">
                  {/* <Trans>Unsupported Asset</Trans> */}
                  Unsupported Asset
                </TYPE.main>
              </Button>
            ) : !account ? (
              <ButtonConnect size="large" intent="secondary" disabled={pendingLogin} onClick={toggleWalletModal}>
                <ButtonConnectWrapper>
                  {pendingLogin && <Spinner intent="primary" size="small" />}
                  Connect Ronin Wallet
                </ButtonConnectWrapper>
                {/* <Trans>Connect Ronin Wallet</Trans> */}
              </ButtonConnect>
            ) : (
              <AutoColumn gap={'md'}>
                {(approvalA === ApprovalState.NOT_APPROVED ||
                  approvalA === ApprovalState.PENDING ||
                  approvalB === ApprovalState.NOT_APPROVED ||
                  approvalB === ApprovalState.PENDING) &&
                  isValid && (
                    <>
                      {approvalA !== ApprovalState.APPROVED && currencies[Field.CURRENCY_A] && (
                        <ApproveWrapper>
                          <ApproveMessage>
                            <FilledShieldIcon color="var(--mc-color-blue-5)" size={24} />
                            <span>Allow Katana to use your {currencies[Field.CURRENCY_A]?.symbol}</span>
                          </ApproveMessage>
                          <ButtonApprove intent="primary" onClick={approveACallback} size="small">
                            {/* <Trans>Approve {currencies[Field.INPUT]?.symbol}</Trans> */}
                            <ButtonApproveWrapper>
                              Approve
                              {approvalA === ApprovalState.PENDING ? (
                                <Spinner intent="secondary" size="small" />
                              ) : undefined}
                            </ButtonApproveWrapper>
                          </ButtonApprove>
                        </ApproveWrapper>
                      )}
                      {approvalB !== ApprovalState.APPROVED && currencies[Field.CURRENCY_B] && (
                        <ApproveWrapper>
                          <ApproveMessage>
                            <FilledShieldIcon color="var(--mc-color-blue-5)" size={24} />
                            <span>Allow Katana to use your {currencies[Field.CURRENCY_B]?.symbol}</span>
                          </ApproveMessage>
                          <ButtonApprove size="small" intent="primary" onClick={approveBCallback}>
                            <ButtonApproveWrapper>
                              Approve
                              {approvalB === ApprovalState.PENDING ? (
                                <Spinner intent="secondary" size="small" />
                              ) : undefined}
                            </ButtonApproveWrapper>
                          </ButtonApprove>
                        </ApproveWrapper>
                      )}
                      {/* <RowBetween>
                        {approvalA !== ApprovalState.APPROVED && (
                          <ButtonPrimary
                            onClick={approveACallback}
                            disabled={approvalA === ApprovalState.PENDING}
                            width={approvalB !== ApprovalState.APPROVED ? '48%' : '100%'}
                          >
                            {approvalA === ApprovalState.PENDING ? (
                              <Dots>
                                <Trans>Approving {currencies[Field.CURRENCY_A]?.symbol}</Trans>
                              </Dots>
                            ) : (
                              <Trans>Approve {currencies[Field.CURRENCY_A]?.symbol}</Trans>
                            )}
                          </ButtonPrimary>
                        )}
                        {approvalB !== ApprovalState.APPROVED && (
                          <ButtonPrimary
                            onClick={approveBCallback}
                            disabled={approvalB === ApprovalState.PENDING}
                            width={approvalA !== ApprovalState.APPROVED ? '48%' : '100%'}
                          >
                            {approvalB === ApprovalState.PENDING ? (
                              <Dots>
                                <Trans>Approving {currencies[Field.CURRENCY_B]?.symbol}</Trans>
                              </Dots>
                            ) : (
                              <Trans>Approve {currencies[Field.CURRENCY_B]?.symbol}</Trans>
                            )}
                          </ButtonPrimary>
                        )}
                      </RowBetween> */}
                    </>
                  )}
                {(error || (approvalA === ApprovalState.APPROVED && approvalB === ApprovalState.APPROVED)) && (
                  <ButtonError
                    onClick={() => {
                      expertMode ? onAdd() : setShowConfirm(true)
                    }}
                    size="large"
                    disabled={!isValid || approvalA !== ApprovalState.APPROVED || approvalB !== ApprovalState.APPROVED}
                    error={
                      (!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]) ||
                      error ||
                      noLiquidity ||
                      approvalA !== ApprovalState.APPROVED ||
                      approvalB !== ApprovalState.APPROVED
                    }
                  >
                    {isInvalidPair ? (
                      <Text fontSize={16} lineHeight="24px" fontWeight={600} color="var(--mc-color-light-6)">
                        {/* <Trans>Unsupported Pair</Trans> */}Unsupported Pair{' '}
                      </Text>
                    ) : error ? (
                      <Text fontSize={16} lineHeight="24px" fontWeight={600} color="var(--mc-color-light-6)">
                        {error}
                      </Text>
                    ) : (
                      // <Trans>Supply</Trans>
                      'Supply'
                    )}
                  </ButtonError>
                )}
              </AutoColumn>
            )}
          </AutoColumn>
        </Container>
      </AppBody>

      {!addIsUnsupported ? (
        pair && !noLiquidity && pairState !== PairState.INVALID ? (
          <PositionCardContainer>
            <MinimalPositionCard showUnwrapped={oneCurrencyIsWRON} pair={pair} />
          </PositionCardContainer>
        ) : null
      ) : (
        <UnsupportedCurrencyFooter
          show={addIsUnsupported}
          currencies={[currencies.CURRENCY_A, currencies.CURRENCY_B]}
        />
      )}
    </>
  )
}
