export const openInAppBrowser = (url: string, title?: string) => {
  if (window.ronin?.provider.appFunction) {
    window.ronin?.provider.appFunction(
      {
        method: 'open_in_app_browser',
        title: title ?? '',
        url,
      },
      () => {}
    )
  }
}
