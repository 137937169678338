// import { AlertCircle, CheckCircle } from 'react-feather'
import styled from 'styled-components'
import { useActiveWeb3React } from '../../hooks/web3'
import { ExternalLink, StyledLinkButton } from '../../theme/components'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import { AutoColumn } from '../Column'
import { AutoRow } from '../Row'

import { ExternalLinkIcon, FilledCircleTickIcon, FilledCrossCircleIcon } from '@axieinfinity/mochi-icons'
import { openInAppBrowser } from 'utils/openInAppBrowser'

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`

const HashContainer = styled.span`
  font-size: var(--mc-font-size-normal);
  color: var(--mc-color-light-9);
  line-height: var(--mc-line-height-medium);
`
const ButtonLink = styled(StyledLinkButton)`
  display: flex;
  align-items: center;
  font-size: var(--mc-font-size-normal);
  color: var(--mc-color-blue-5);
  line-height: var(--mc-line-height-medium);
  font-weight: var(--mc-font-weight-semi-bold);
  padding-left: var(--mc-space-inline-4x);
  background: none !important;
`
const ButtonExternalLink = styled(ExternalLink)`
  display: flex;
  align-items: center;
  font-size: var(--mc-font-size-normal);
  color: var(--mc-color-blue-5);
  line-height: var(--mc-line-height-medium);
  font-weight: var(--mc-font-weight-semi-bold);
  padding-left: var(--mc-space-inline-4x);
`

export default function TransactionPopup({
  hash,
  success,
  summary,
}: {
  hash: string
  success?: boolean
  summary?: string
}) {
  const { chainId } = useActiveWeb3React()

  // const theme = useContext(ThemeContext)

  return (
    <RowNoFlex>
      <div style={{ paddingRight: 16 }}>
        {/* {success ? <CheckCircle color={theme.green1} size={24} /> : <AlertCircle color={theme.red1} size={24} />} */}
        {success ? (
          <FilledCircleTickIcon size={40} color="var(--mc-color-blue-5)" />
        ) : (
          <FilledCrossCircleIcon size={40} color="var(--mc-color-red-5)" />
        )}
      </div>
      <AutoColumn gap="4px">
        <HashContainer>{summary ?? 'Hash: ' + hash.slice(0, 8) + '...' + hash.slice(58, 65)}</HashContainer>
        {chainId &&
          (window.isWalletApp ? (
            <ButtonLink
              onClick={() =>
                openInAppBrowser(getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION), 'Ronin Explorer')
              }
            >
              View on Explorer <ExternalLinkIcon size={16} />
            </ButtonLink>
          ) : (
            <ButtonExternalLink href={getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)}>
              View on Explorer <ExternalLinkIcon size={16} />
            </ButtonExternalLink>
          ))}
      </AutoColumn>
    </RowNoFlex>
  )
}
