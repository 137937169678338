import { ReactNode } from 'react'
import styled from 'styled-components'

const TitleWrapper = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  line-height: 16px;
  color: var(--mc-color-light-7);
`
interface StyleProps {
  style?: React.CSSProperties
  className?: string
  children: ReactNode
}

type Props = StyleProps & {
  title: string
}

export const Title = (props: StyleProps) => (
  <TitleWrapper style={{ letterSpacing: 0.5, fontSize: 10, ...props.style }}>{props.children}</TitleWrapper>
)

export const Section = (props: Props) => {
  return (
    <div className={props.className} style={props.style}>
      <Title>{props.title}</Title>
      <div>{props.children}</div>
    </div>
  )
}
