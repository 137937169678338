import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

export const MULTICALL2_ADDRESSES: AddressMap = {
  [SupportedChainId.RONIN_TESTNET]: '0x31c9ef8a631e2489e69833df3b2cb4bf0dc413bc',
  [SupportedChainId.RONIN_MAINNET]: '0xc76d0d0d3aa608190f78db02bf2f5aef374fc0b9',
}

export const V2_FACTORY_ADDRESSES: AddressMap = {
  [SupportedChainId.RONIN_TESTNET]: '0x86587380c4c815ba0066c90adb2b45cc9c15e72c',
  [SupportedChainId.RONIN_MAINNET]: '0xb255d6a720bb7c39fee173ce22113397119cb930',
}

export const V2_ROUTER_ADDRESS: AddressMap = {
  [SupportedChainId.RONIN_TESTNET]: '0xDa44546C0715ae78D454fE8B84f0235081584Fe0',
  [SupportedChainId.RONIN_MAINNET]: '0x7d0556d55ca1a92708681e2e231733ebd922597d',
}

export const AXSETH_LP_STAKING_POOL_ADDRESS: AddressMap = {
  [SupportedChainId.RONIN_TESTNET]: '0x448a8eda73fcd2ab340242eddd8401e35c347aba',
  [SupportedChainId.RONIN_MAINNET]: '0x487671acdea3745b6dac3ae8d1757b44a04bfe8a',
}

export const SLPETH_LP_STAKING_POOL_ADDRESS: AddressMap = {
  [SupportedChainId.RONIN_TESTNET]: '0x481dc6c1969976838bb0b59959d1f6712790e2a9',
  [SupportedChainId.RONIN_MAINNET]: '0xd4640c26c1a31cd632d8ae1a96fe5ac135d1eb52',
}

export const RONETH_LP_STAKING_POOL_ADDRESS: AddressMap = {
  [SupportedChainId.RONIN_TESTNET]: '0x9e6e4384ffbc4003b6d7af838728c7a6545f41b3',
  [SupportedChainId.RONIN_MAINNET]: '0xb9072cEc557528f81DD25DC474d4d69564956e1e',
}

export const RONAXS_LP_STAKING_POOL_ADDRESS: AddressMap = {
  [SupportedChainId.RONIN_TESTNET]: '0x08da409c62af6e27168c73f3b592270d3e6d5841',
  [SupportedChainId.RONIN_MAINNET]: '0x14327fa6a4027d8f08c0a1b7feddd178156e9527',
}

export const RONSLP_LP_STAKING_POOL_ADDRESS: AddressMap = {
  [SupportedChainId.RONIN_TESTNET]: '0xb063c25b361270ca9dfa886194dca78c9c6c5d94',
  [SupportedChainId.RONIN_MAINNET]: '0x4e2d6466a53444248272b913c105e9281ec266d8',
}

export const RONUSDC_LP_STAKING_POOL_ADDRESS: AddressMap = {
  [SupportedChainId.RONIN_TESTNET]: '0xc720c38f4ca4b7b4827497e8b89b8ccd45c2fa76',
  [SupportedChainId.RONIN_MAINNET]: '0xba1c32baff8f23252259a641fd5ca0bd211d4f65',
}

export const STAKING_MANAGER_ADDRESS: AddressMap = {
  [SupportedChainId.RONIN_TESTNET]: '0x9bff909855a104a1acdb4803547b087e197d1f9c',
  [SupportedChainId.RONIN_MAINNET]: '0x8bd81a19420bad681b7bfc20e703ebd8e253782d',
}

export const GOVERNANCE_ADDRESS: AddressMap = {}
export const TIMELOCK_ADDRESS: AddressMap = {}
export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {}
export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {}
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {}
export const QUOTER_ADDRESSES: AddressMap = {}
export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {}
export const ENS_REGISTRAR_ADDRESSES: AddressMap = {}
export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {}
export const SWAP_ROUTER_ADDRESSES: AddressMap = {}
export const V3_MIGRATOR_ADDRESSES: AddressMap = {}
