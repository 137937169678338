// NOTE: disable this eslint rule for internal app's tracking user purpose (FOR INTERNAL APP URL ONLY)
/* eslint-disable react/jsx-no-target-blank */
import { Tag } from '@axieinfinity/mochi'
import { ArrowRightIcon } from '@axieinfinity/mochi-icons'
import { RNS_APP_URL } from 'constants/routing'
import styled from 'styled-components'

export const RNSBanner = () => {
  const RNSBannerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    background: var(--mc-color-blue-1);
    div {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .text {
      margin-left: 4px;
      @media (max-width: 767px) {
        margin-left: 20px;
      }
      span {
        display: inline-flex;
        @media (max-width: 767px) {
          max-width: 200px;
        }
      }
    }
    svg {
      margin-left: 4px;
    }
  `

  return (
    <RNSBannerWrapper>
      <a href={'https://roninchain.com/blog/posts/2024-q1-katana-liquidity-mining-program-update'} target="_blank">
        <div className="flex cursor-pointer items-center">
          <Tag intent="success" variant="filled">
            New
          </Tag>
          <div className="mc-text-md text">
            <span>Katana Liquidity Mining Program Update on March 1st</span>
            <ArrowRightIcon size={20} color="var(--mc-color-blue-5)" />
          </div>
        </div>
      </a>
    </RNSBannerWrapper>
  )
}
