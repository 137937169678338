import { Percent } from '@uniswap/sdk-core'
import React from 'react'
import { warningSeverity } from '../../utils/prices'
import { ErrorText, ErrorPill } from './styleds'

/**
 * Formatted version of price impact text with warning colors
 */
export default function FormattedPriceImpact({ priceImpact }: { priceImpact?: Percent }) {
  return (
    <ErrorText fontWeight={600} fontSize={14} severity={warningSeverity(priceImpact)}>
      {priceImpact
        ? `${priceImpact.multiply(-1).toFixed(2) === '0.00' ? '<0.01' : priceImpact.multiply(-1).toFixed(2)}%`
        : '-'}
    </ErrorText>
  )
}

export function SmallFormattedPriceImpact({ priceImpact }: { priceImpact?: Percent }) {
  return (
    <ErrorPill fontWeight={600} fontSize={14} severity={warningSeverity(priceImpact)}>
      {priceImpact ? `(${priceImpact.multiply(-1).toFixed(2)}%)` : '-'}
    </ErrorPill>
  )
}
