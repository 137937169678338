import { Currency, CurrencyAmount, Fraction, Price } from '@uniswap/sdk-core'
import JSBI from 'jsbi'
import { isNil } from 'lodash'

export function formatCurrencyAmount(
  amount: CurrencyAmount<Currency> | undefined,
  options?: { maxFraction?: number; maxSignificant?: number },
  toNumber?: boolean
) {
  if (!amount) {
    return toNumber ? 0 : '-'
  }

  if (JSBI.equal(amount.quotient, JSBI.BigInt(0))) {
    return toNumber ? 0 : '0'
  }

  if (amount.divide(amount.decimalScale).lessThan(new Fraction(1, 100000))) {
    return toNumber ? amount.toExact() : '<0.00001'
  }

  // return amount.toSignificant(sigFigs)
  // return amount.toSignificant(undefined, undefined, Rounding.ROUND_DOWN)
  const { maxFraction = 6, maxSignificant } = options || {}

  let autoMaxFraction: number | undefined = maxFraction
  let autoMaxSignificant: number | undefined = maxSignificant

  if (isNil(options)) {
    if (!amount.lessThan(1)) {
      autoMaxFraction = Math.max(6 - Math.floor(Math.log10(Number(amount.toExact()))), 0)
      autoMaxSignificant = undefined
    } else {
      autoMaxFraction = 20
      autoMaxSignificant = 6
    }
  }
  return toNumber
    ? autoMaxSignificant
      ? amount.toSignificant(autoMaxSignificant)
      : amount.toExact()
    : new Intl.NumberFormat('en-US', {
        maximumSignificantDigits: autoMaxSignificant,
        maximumFractionDigits: autoMaxFraction,
      }).format(Number(amount.toExact()))
}

export function formatPrice(price: Price<Currency, Currency> | undefined, sigFigs: number) {
  if (!price) {
    return '-'
  }

  if (parseFloat(price.toFixed(sigFigs)) < 0.0001) {
    return '<0.0001'
  }

  return price.toSignificant(sigFigs)
}
