import { SupportedChainId } from 'constants/chains'

const NETWORK_URLS_FREE_GAS_RPC: {
  [chainId in SupportedChainId]: string
} = {
  [SupportedChainId.RONIN_MAINNET]: 'https://proxy.roninchain.com/free-gas-rpc',
  [SupportedChainId.RONIN_TESTNET]: 'https://saigon-testnet.roninchain.com/free-gas-rpc',
}

export const getFreeSwapRon = async (account?: string | null, chainId?: SupportedChainId) => {
  if (!account || !chainId) return undefined
  try {
    const raw = JSON.stringify({
      id: 11,
      jsonrpc: '2.0',
      method: 'ronin_getFreeGasSwapQuota',
      params: [`${account}`],
    })

    const response = await fetch(NETWORK_URLS_FREE_GAS_RPC[chainId], {
      headers: new Headers({ 'content-type': 'application/json' }),
      method: 'POST',
      body: raw,
    })
    const data = await response.json()
    return data.result
  } catch (err) {
    console.error(err)
    return undefined
  }
}
