import { Percent } from '@uniswap/sdk-core'
import styled from 'styled-components/macro'
import SettingsTab from '../Settings'

import { TYPE } from '../../theme'
import { RowBetween, RowFixed } from '../Row'

const Container = styled.div`
  padding: 24px 20px 12px;
  width: 100%;
  color: ${({ theme }) => theme.text2};
`
const Header = styled(TYPE.black)`
  margin-right: var(--mc-space-inline-8x);
`
const Text = styled.div`
  font-size: var(--mc-font-size-large);
  line-height: var(--mc-line-height-x-large);
  color: var(--mc-color-light-9);
  font-weight: var(--mc-font-weight-semi-bold);
`
export default function SwapHeader({ allowedSlippage }: { allowedSlippage: Percent }) {
  return (
    <Container>
      <RowBetween>
        <RowFixed>
          <Header fontWeight={500} fontSize={16}>
            {/* <Trans>Swap</Trans> */}
            <Text>Swap</Text>
          </Header>
        </RowFixed>
        <RowFixed>
          <SettingsTab placeholderSlippage={allowedSlippage} />
        </RowFixed>
      </RowBetween>
    </Container>
  )
}
