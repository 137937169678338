import styled from 'styled-components'

interface Props {
  value?: string
  symbol: string
  symbolClassName?: string
  className?: string
  symbolStyle?: React.CSSProperties
  style?: React.CSSProperties
}
const Wrapper = styled.span`
  overflow-wrap: break-word;
`
export const Token = ({ value, style, symbol, symbolStyle, className, symbolClassName }: Props) => {
  if (value === undefined) {
    return (
      <span className={className} style={style}>
        --
      </span>
    )
  } else
    return (
      <Wrapper className={className} style={style}>
        {`${value} `}
        <span className={symbolClassName} style={symbolStyle}>
          {symbol}
        </span>
      </Wrapper>
    )
}
