import { Tag } from '@axieinfinity/mochi'
import { ExternalLinkIcon, FilledNotebookIcon, SignOutIcon } from '@axieinfinity/mochi-icons'
import { useCallback, useEffect, useRef } from 'react'
import { ExternalLink as LinkIcon } from 'react-feather'
import { useAppDispatch } from 'state/hooks'
import styled from 'styled-components'
import { openInAppBrowser } from 'utils/openInAppBrowser'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import { roninWallet } from '../../connectors'
import { useActiveWeb3React } from '../../hooks/web3'
import { clearAllTransactions } from '../../state/transactions/actions'
import { ExternalLink, LinkStyledButton, StyledLinkButton } from '../../theme'
import { shortenAddress } from '../../utils'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import { ButtonSecondary } from '../Button'
import { AutoRow } from '../Row'
import Copy from './Copy'
import Transaction from './Transaction'

const Separator = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.bg2};
`

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  color: ${(props) => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};
`

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const InfoCard = styled.div``

const LowerSection = styled.div<{ hasMarginTop: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  flex-grow: 1;
  overflow: auto;

  margin-top: ${({ hasMarginTop }) => (hasMarginTop ? '24px' : '')};
  h5 {
    margin: 0;
    font-weight: 400;
    color: ${({ theme }) => theme.text3};
  }
`

const CloseIcon = styled.div`
  position: absolute;
  right: -4px;
  top: -4px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`

const WalletName = styled.div`
  width: initial;
  font-size: 0.825rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text3};
`

const TransactionListWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
`

const WalletAction = styled(ButtonSecondary)`
  width: fit-content;
  font-weight: 400;
  margin-left: 8px;
  font-size: 0.825rem;
  padding: 4px 6px;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const FilledNotebookIconWrapper = styled.div`
  color: var(--mc-color-light-7);
`

const AccountDetailSection = styled.div`
  padding: 24px;
`
const AccountHeaderRow = styled(HeaderRow)`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: var(--mc-color-light-9);
`

const SectionSeperator = styled(Separator)`
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: -24px;
  margin-right: -24px;
  display: flex;
`

const AccountDetailInfoCard = styled(InfoCard)`
  position: relative;
  background-color: var(--mc-color-light-1);
  padding: 16px;
  border-radius: 12px;
  line-height: 10px;
`

const DisconnectButtonSection = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
`
const DisconnectButton = styled.div`
  display: flex;
  align-items: center;
  color: var(--mc-color-red-5);
  user-select: none;
  cursor: pointer;
`

const SignoutMessage = styled.div`
  margin-left: 8px;
  font-size: 14px;
  line-height: 20px;
`

const ConnectWalletSection = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  color: var(--mc-color-light-7);
`
const ConnectButtonHeading = styled.div`
  margin-left: 4px;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`

const AddressSection = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  word-break: break-all;
`

const AddressActionSection = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
`
const AccountExternalLink = styled(ExternalLink)`
  margin-left: 8px;
  display: block;
  height: 20px;
`

const ViewHeading = styled.div`
  margin-left: 4px;
  display: inline-block;
`
const AppViewAccountButton = styled(StyledLinkButton)`
  margin-left: 8px;
  display: block;
  height: 20px;
`
const ExtensionViewAccountButton = styled(ExternalLink)`
  margin-left: 8px;
  display: block;
  height: 20px;
`

const LowerSectionRow = styled(AutoRow)`
  align-items: center;
`

const RecentTxHeading = styled.div`
  display: inline-block;
  font-size: 10px;
  line-height: 16px;
  color: var(--mc-color-light-7);
  font-weight: 700;
  text-transform: uppercase;
`

const ClearAllButton = styled(LinkStyledButton)`
  margin-left: 4px;
  font-weight: 400;
  text-decoration-line: none;
  font-size: 12px;
  line-height: 16px;
  height: 16px;
`

function renderTransactions(transactions: string[]) {
  return (
    <TransactionListWrapper>
      {transactions.map((hash, i) => {
        return <Transaction key={i} hash={hash} />
      })}
    </TransactionListWrapper>
  )
}

interface AccountDetailsProps {
  toggleWalletModal: () => void
  pendingTransactions: string[]
  confirmedTransactions: string[]
  ENSName?: string
  openOptions: () => void
}

export default function AccountDetails({
  toggleWalletModal,
  pendingTransactions,
  confirmedTransactions,
  ENSName,
  openOptions,
}: AccountDetailsProps) {
  const { chainId, account, connector } = useActiveWeb3React()
  const dispatch = useAppDispatch()
  const timeoutRef = useRef<any>()

  const clearAllTransactionsCallback = useCallback(() => {
    if (chainId) dispatch(clearAllTransactions({ chainId }))
  }, [dispatch, chainId])

  const handleClickDisconnect = () => {
    toggleWalletModal()
    timeoutRef.current = setTimeout(() => {
      roninWallet.handleAccountsChanged([])
      ;(window as any).canAutoLogin = false
      timeoutRef.current = null
    })
  }

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return (
    <AccountDetailSection>
      <UpperSection>
        <CloseIcon onClick={toggleWalletModal}>
          <CloseColor />
        </CloseIcon>

        <AccountHeaderRow>Account</AccountHeaderRow>

        <SectionSeperator />

        <AccountDetailInfoCard>
          <DisconnectButtonSection>
            <DisconnectButton onClick={handleClickDisconnect}>
              <SignOutIcon size={16} />
              <SignoutMessage>Disconnect</SignoutMessage>
            </DisconnectButton>
          </DisconnectButtonSection>

          <ConnectWalletSection>
            <FilledNotebookIconWrapper>
              <FilledNotebookIcon size={16} />
            </FilledNotebookIconWrapper>

            <ConnectButtonHeading>Ronin Wallet</ConnectButtonHeading>
          </ConnectWalletSection>

          <AddressSection>
            {ENSName ? <p> {ENSName}</p> : <p> {account && shortenAddress(account, 10, false).toLowerCase()}</p>}
          </AddressSection>

          <AddressActionSection>
            {ENSName ? (
              <>
                {account && <Copy toCopy={account.toLowerCase()} />}
                {chainId && account && (
                  <AccountExternalLink href={getExplorerLink(chainId, ENSName, ExplorerDataType.ADDRESS)}>
                    <LinkIcon size={16} />

                    <ViewHeading>
                      {/* <Trans> */}View on Explorer{/* </Trans> */}
                    </ViewHeading>
                  </AccountExternalLink>
                )}
              </>
            ) : (
              <>
                {account && <Copy toCopy={account} />}
                {chainId &&
                  account &&
                  (window.isWalletApp ? (
                    <AppViewAccountButton
                      onClick={() =>
                        openInAppBrowser(getExplorerLink(chainId, account, ExplorerDataType.ADDRESS), 'Ronin Explorer')
                      }
                    >
                      View on Explorer
                    </AppViewAccountButton>
                  ) : (
                    <ExtensionViewAccountButton
                      href={getExplorerLink(chainId, account.toLowerCase(), ExplorerDataType.ADDRESS)}
                      title="Ronin Explorer"
                    >
                      <Tag intent="grey" size="medium" interactive leftIcon={ExternalLinkIcon}>
                        View on Explorer
                      </Tag>
                    </ExtensionViewAccountButton>
                  ))}
              </>
            )}
          </AddressActionSection>
        </AccountDetailInfoCard>
      </UpperSection>

      <LowerSection hasMarginTop={!!pendingTransactions.length || !!confirmedTransactions.length}>
        {!!pendingTransactions.length || !!confirmedTransactions.length ? (
          <>
            <LowerSectionRow mb={'12px'}>
              <RecentTxHeading>Recent Transactions</RecentTxHeading>
              <ClearAllButton onClick={clearAllTransactionsCallback}>(Clear all)</ClearAllButton>
            </LowerSectionRow>
            {renderTransactions(pendingTransactions)}
            {renderTransactions(confirmedTransactions)}
          </>
        ) : null}
      </LowerSection>
    </AccountDetailSection>
  )
}
