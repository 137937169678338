import { Currency, Percent, Price } from '@uniswap/sdk-core'
import CurrencyLogo from 'components/CurrencyLogo'
import { useContext } from 'react'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { AutoColumn } from '../../components/Column'
import { ONE_BIPS } from '../../constants/misc'
import { Field } from '../../state/mint/actions'

const ExchangePriceContainer = styled.div`
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-wrap: wrap;
  gap: var(--mc-space-inset-8x);
  align-items: center;
  max-width: 100%;
`
const PriceCard = styled.div`
  flex: 1 1 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--mc-color-light-2);
  border-radius: var(--mc-space-inset-8x);
  padding: var(--mc-space-stack-4x) var(--mc-space-inline-8x);
`
const PriceCardWithMarginTop = styled(PriceCard)`
  margin-top: 8px;
  @media (min-width: 768px) {
    margin-top: 0px;
  }
`
const ExchangeFrom = styled(Text)`
  white-space: nowrap;
  margin-left: var(--mc-space-inline-4x);
  line-height: var(--mc-line-height-medium);
`
const CurrencySymbol = styled.div`
  font-weight: var(--mc-font-weight-bold);
  display: inline-block;
`
const ExchangeTo = styled.div`
  font-size: var(--mc-font-size-normal);
  color: var(--mc-color-light-7);
  text-overflow: ellipsis;
  overflow: hidden;
  overflow-wrap: break-word;
  margin-left: var(--mc-space-inline-8x);
  line-height: var(--mc-line-height-medium);
  text-align: center;
  max-width: calc(100% - 78px);
`
const ExchangeToPrice = styled.div`
  word-break: break-all;
  display: inline-block;
`
const PoolShareResult = styled.div`
  background-color: var(--mc-color-light-2);
  border-radius: var(--mc-space-inset-8x);
  padding: var(--mc-space-stack-4x) var(--mc-space-inline-8x);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--mc-space-stack-8x);
`
const ResultMessage = styled.div`
  color: var(--mc-color-light-7);
  font-size: var(--mc-font-size-normal);
`
export function PoolPriceBar({
  currencies,
  noLiquidity,
  poolTokenPercentage,
  price,
}: {
  currencies: { [field in Field]?: Currency }
  noLiquidity?: boolean
  poolTokenPercentage?: Percent
  price?: Price<Currency, Currency>
}) {
  const theme = useContext(ThemeContext)
  return (
    <AutoColumn gap="md">
      {/* <AutoRow justify="space-around" gap="4px"> */}
      <div>
        <ExchangePriceContainer>
          {/* <AutoColumn justify="center"> */}
          <PriceCard>
            <CurrencyLogo currency={currencies[Field.CURRENCY_A]} size="20px" />
            <ExchangeFrom fontWeight={400} fontSize={14} color="var(--mc-color-light-7)">
              <CurrencySymbol>&nbsp;1 {currencies[Field.CURRENCY_A]?.symbol}</CurrencySymbol> =
            </ExchangeFrom>
            <ExchangeTo>
              <ExchangeToPrice>{price?.toSignificant(6) ?? '-'}</ExchangeToPrice> {currencies[Field.CURRENCY_B]?.symbol}
            </ExchangeTo>
          </PriceCard>
          <PriceCardWithMarginTop>
            <CurrencyLogo currency={currencies[Field.CURRENCY_B]} size="20px" />
            <ExchangeFrom fontWeight={400} fontSize={14} color="var(--mc-color-light-7)">
              <CurrencySymbol>&nbsp;1 {currencies[Field.CURRENCY_B]?.symbol}</CurrencySymbol> =
            </ExchangeFrom>
            <ExchangeTo>
              <ExchangeToPrice>{price?.invert()?.toSignificant(6) ?? '-'}</ExchangeToPrice>{' '}
              {currencies[Field.CURRENCY_A]?.symbol}
            </ExchangeTo>
          </PriceCardWithMarginTop>
          {/* </AutoColumn> */}
          {/* <AutoColumn justify="center"> */}
          {/* <div className="flex-1">
            <TYPE.black>{price?.invert()?.toSignificant(6) ?? '-'}</TYPE.black>
            <Text fontWeight={500} fontSize={14} color={theme.text2} pt={1}>
              {currencies[Field.CURRENCY_A]?.symbol} per {currencies[Field.CURRENCY_B]?.symbol}
            </Text>
            </AutoColumn>
          </div> */}
        </ExchangePriceContainer>
        {/* <AutoColumn justify="center"> */}
        <PoolShareResult>
          <ResultMessage>
            {/* <TYPE.black> */}
            You will own{' '}
            <strong>
              {noLiquidity && price
                ? '100'
                : (poolTokenPercentage?.lessThan(ONE_BIPS) ? '<0.01' : poolTokenPercentage?.toFixed(2)) ?? '0'}
              %
            </strong>{' '}
            {/* </TYPE.black> */}
            {/* <Text fontWeight={500} fontSize={14} color={theme.text2} pt={1}> */}
            Share of Pool
            {/* </Text> */}
            {/* </AutoColumn> */}
          </ResultMessage>
        </PoolShareResult>
      </div>
      {/* </AutoRow> */}
    </AutoColumn>
  )
}
