import React, { useMemo } from 'react'
import styled from 'styled-components/macro'
import { AutoColumn } from '../Column'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
`

/**
 * Content for balance stats modal
 */
export default function UniBalanceContent({ setShowUniBalanceModal }: { setShowUniBalanceModal: any }) {
  return <ContentWrapper gap="lg"></ContentWrapper>
}
