import { Button, Spinner } from '@axieinfinity/mochi'
import { TransactionResponse } from '@ethersproject/abstract-provider'
import { Contract } from '@ethersproject/contracts'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import CurrencyLogo from 'components/CurrencyLogo'
import Modal from 'components/Modal'
import { RowBetween } from 'components/Row'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import { ExtendedEther } from 'constants/tokens'
import useToggle from 'hooks/useToggle'
import { useActiveWeb3React } from 'hooks/web3'
import isNil from 'lodash/isNil'
import noop from 'lodash/noop'
import React, { useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import styled from 'styled-components'
import { CloseIcon } from 'theme'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
const ClaimSpinner = styled(Spinner)`
  margin-right: auto;
  margin-left: auto;
`

const ClaimDialog = styled.div`
  width: 100%;
  padding: 24px;
`

const MainDialogSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const ClaimingMessage = styled.h5`
  display: block;
  margin-top: 16px;
`

const WarningMessage = styled.div`
  margin-top: 8px;
  color: var(--mc-color-light-7);
  font-size: 12px;
  line-height: 16px;
`

const Emphasize = styled.div`
  display: inline-block;
  font-weight: 700;
`

const SpinnerWrapper = styled.div`
  margin-top: 28px;
  text-align: center;
`

const ClaimButtonWrapper = styled.div`
  margin-top: 20px;
`
const CloseIconWrapper = styled(RowBetween)`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`
export interface Props {
  isOpen: boolean
  onClose: () => void
  amount?: CurrencyAmount<Token>
  firstTokenInfo?: Token
  secondTokenInfo?: Token
  stakingPoolContract?: Contract
  addPendingTx?: (txHash: string) => void
}

export const FarmClaimDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  amount,
  stakingPoolContract,
  addPendingTx = noop,
}) => {
  const addTransaction = useTransactionAdder()
  const { account: selectedAddress, chainId } = useActiveWeb3React()

  const nativeRON = ExtendedEther.onChain(chainId)

  const [loading, setLoading] = useState<boolean>(false)
  const [txSubmitOpen, toggleTxSubmit] = useToggle(false)
  const [submitedTxHash, setSubmitedTxHash] = useState<string>()

  const amountText = amount ? formatCurrencyAmount(amount) : '--'

  const handleClickClaim = async () => {
    if (isNil(stakingPoolContract) || isNil(selectedAddress)) {
      return
    }

    setLoading(true)
    try {
      const estimateGas = await stakingPoolContract.estimateGas.claimPendingRewards()
      const safeGas = Math.round(estimateGas.toNumber() * 1.5)

      const response: TransactionResponse | undefined = await stakingPoolContract.claimPendingRewards({
        gasLimit: safeGas,
      })

      if (!isNil(response)) {
        setSubmitedTxHash(response.hash)
        toggleTxSubmit()
        addPendingTx(response.hash)
        addTransaction(response, {
          summary: `Claim ${amountText} RON`,
        })
      }
    } catch (error) {
      console.error('handleClickClaim', error)
    } finally {
      onClose()
      setLoading(false)
    }
  }

  const handleDismissSubmitTxModal = () => {
    toggleTxSubmit()
    setSubmitedTxHash(undefined)
  }

  return (
    <>
      <Modal isOpen={isOpen} onDismiss={onClose} maxWidth={460}>
        <ClaimDialog>
          <CloseIconWrapper>
            <CloseIcon onClick={onClose} size="20px" />
          </CloseIconWrapper>

          <MainDialogSection>
            <CurrencyLogo currency={nativeRON} size="60px" />
            <ClaimingMessage>{`You are claiming ${amountText} RON`}</ClaimingMessage>
            <WarningMessage>
              Doing this will prevent you from being able to claim rewards for <Emphasize>24 hours</Emphasize>. Are you
              sure you want to proceed?
            </WarningMessage>

            {loading === true && (
              <SpinnerWrapper>
                <ClaimSpinner intent="primary" />
              </SpinnerWrapper>
            )}
            {loading === false && (
              <ClaimButtonWrapper>
                <Button onClick={handleClickClaim} intent="primary" disabled={loading}>
                  Claim RON now
                </Button>
              </ClaimButtonWrapper>
            )}
          </MainDialogSection>
        </ClaimDialog>
      </Modal>
      <TransactionConfirmationModal
        isOpen={txSubmitOpen}
        onDismiss={handleDismissSubmitTxModal}
        hash={submitedTxHash}
        attemptingTxn={false}
        pendingText=""
        content={() => {
          return null
        }}
      />
    </>
  )
}
