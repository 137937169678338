import { Currency, TradeType } from '@uniswap/sdk-core'
import { Trade as V2Trade } from '@uniswap/v2-sdk'
import { Trade as V3Trade } from '@uniswap/v3-sdk'

import { ReactNode } from 'react'
import styled from 'styled-components'
import { ButtonError } from '../Button'
import { AutoRow } from '../Row'
import { SwapCallbackError } from './styleds'

const ButtonConfirm = styled(ButtonError)`
  width: 100%;
  margin: var(--mc-space-stack-20x) 0 0;
`
const ButtonConfirmWrapper = styled.div`
  font-weight: var(--mc-font-weight-semi-bold);
`
export default function SwapModalFooter({
  onConfirm,
  swapErrorMessage,
  disabledConfirm,
}: {
  trade: V2Trade<Currency, Currency, TradeType> | V3Trade<Currency, Currency, TradeType>
  onConfirm: () => void
  swapErrorMessage: ReactNode | undefined
  disabledConfirm: boolean
}) {
  return (
    <>
      {!disabledConfirm && (
        <AutoRow padding="0 20px 20px">
          <ButtonConfirm onClick={onConfirm} disabled={disabledConfirm} id="confirm-swap-or-send" size="large">
            {/* <Text fontSize={20} fontWeight={500}> */}
            <ButtonConfirmWrapper>
              {/* <Trans> */}Confirm Swap{/* </Trans> */}
            </ButtonConfirmWrapper>
            {/* </Text> */}
          </ButtonConfirm>

          {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
        </AutoRow>
      )}
    </>
  )
}
