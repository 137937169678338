import { JsonRpcProvider } from '@ethersproject/providers'
import { DEFAULT_CHAIN_ID, SupportedChainId } from '../constants/chains'
import { NetworkConnector } from './NetworkConnector'
import { RoninConnector } from './RoninConnector'

const NETWORK_URLS: {
  [chainId in SupportedChainId]: string
} = {
  [SupportedChainId.RONIN_MAINNET]: 'https://api.roninchain.com/rpc',
  [SupportedChainId.RONIN_TESTNET]: 'https://saigon-testnet.roninchain.com/rpc',
}

const SUPPORTED_CHAIN_IDS: SupportedChainId[] = [SupportedChainId.RONIN_MAINNET, SupportedChainId.RONIN_TESTNET]

export const network = new NetworkConnector({
  urls: NETWORK_URLS,
  defaultChainId: DEFAULT_CHAIN_ID,
})

let networkLibrary: JsonRpcProvider | undefined
export function getNetworkLibrary(): JsonRpcProvider {
  return (networkLibrary = networkLibrary ?? network.provider)
}

export const roninWallet = new RoninConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
})
