import { Percent } from '@uniswap/sdk-core'
import { darken } from 'polished'
import { useState } from 'react'
import { Link as HistoryLink, NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import { ArrowLeft } from 'react-feather'
import { RowBetween } from '../Row'
import SettingsTab from '../Settings'

import useTheme from 'hooks/useTheme'
import { useAppDispatch } from 'state/hooks'
import { resetMintState } from 'state/mint/actions'
import { resetMintState as resetMintV3State } from 'state/mint/v3/actions'
import { TYPE } from 'theme'

import { CrossIcon, QuestionIcon } from '@axieinfinity/mochi-icons'
import Modal from 'components/Modal'

const Tabs = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  border-radius: 3rem;
  justify-content: space-evenly;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  height: 3rem;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  font-size: 20px;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`

const ActiveText = styled.div`
  font-weight: 500;
  font-size: 20px;
`

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.text1};
`

const CrossIconWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  display: inline-block;
  top: 20px;
  right: 20px;
  color: var(--mc-color-light-7);
`

const LiquidityButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  color: var(--mc-color-blue-5);
  margin-left: 8px;
  cursor: pointer;
`
const LiquidityInfoContainer = styled.div`
  padding: 24px;
  position: relative;
`

const SeeMoreSection = styled.div`
  width: 100%;
  margin-bottom: 24px;
`
const SeeMoreHeading = styled.div`
  display: inline-block;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
`

const Description = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
`

const Emphasize = styled.div`
  font-weight: 700;
  display: inline;
`
const LearnMoreHeading = styled.div`
  font-size: 14px;
  line-height: 20px;
`

const LearnMoreList = styled.ul`
  list-style-type: disc;
  padding-left: 24px;
`
const LearnMoreItem = styled.a`
  color: var(--mc-color-blue-5);
  cursor: pointer;
  font-weight: 400;
`

const RemoveLiquidityInfoWrapper = styled.div`
  padding: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const MoreLiquidityInfo = styled.div`
  width: 100%;
`
const LiquidityDescription = styled.p`
  font-size: 14px;
  line-height: 20px;
`

const MoreInfoHeading = styled.div`
  display: inline-block;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
`
const TabHeader = TYPE.mediumHeader

const LiquidityActionItem = styled.div`
  display: flex;
  align-items: center;
`

export function SwapPoolTabs({ active }: { active: 'swap' | 'pool' }) {
  return (
    <Tabs style={{ marginBottom: '20px', display: 'none', padding: '1rem 1rem 0 1rem' }}>
      <StyledNavLink id={`swap-nav-link`} to={'/swap'} isActive={() => active === 'swap'}>
        {/* <Trans> */}Swap{/* </Trans> */}
      </StyledNavLink>
      <StyledNavLink id={`pool-nav-link`} to={'/pool'} isActive={() => active === 'pool'}>
        {/* <Trans> */}Pool{/* </Trans> */}
      </StyledNavLink>
    </Tabs>
  )
}

export function FindPoolTabs({ origin }: { origin: string }) {
  return (
    <Tabs>
      <RowBetween style={{ padding: '1rem 1rem 0 1rem' }}>
        <HistoryLink to={origin}>
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>
          {/* <Trans> */}Import V2 Pool{/* </Trans> */}
        </ActiveText>
      </RowBetween>
    </Tabs>
  )
}

export function InfoAddLiquidyModal({ isOpen, onDismiss }: { isOpen: boolean; onDismiss: () => void }) {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxWidth={400}>
      <LiquidityInfoContainer>
        <SeeMoreSection>
          <SeeMoreHeading>
            {/* <Trans> */}What’s “Add liquidity”{/* </Trans> */}
          </SeeMoreHeading>

          <CrossIconWrapper>
            <CrossIcon size={24} onClick={onDismiss} />
          </CrossIconWrapper>
        </SeeMoreSection>

        <Description>
          When you add liquidity, you will receive <Emphasize>pool tokens</Emphasize> representing your position. These
          tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any time.
        </Description>
        <Description>
          By adding liquidity you’ll earn <Emphasize>0.3%</Emphasize> of all trades on this pair, proportional to your
          share of pool. Fees are added to pool, accrue in real-time, and can be claimed by withdrawing your liquidity.
        </Description>

        <section>
          <LearnMoreHeading>Learn more:</LearnMoreHeading>

          <LearnMoreList>
            <LearnMoreItem
              href="https://axie-infinity.gitbook.io/katana-docs/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>Learn about provide liquidity</li>
            </LearnMoreItem>
            <LearnMoreItem
              href="https://support.axieinfinity.com/hc/en-us/articles/4409315514907-Katana-Ronin-Dex-Overview"
              target="_blank"
              rel="noopener"
            >
              <li>FAQ</li>
            </LearnMoreItem>
            {/* <a className="text-primary-5 font-regular hover:font-regular cursor-pointer">
              <li>Top pool</li>
            </a> */}
          </LearnMoreList>
        </section>
      </LiquidityInfoContainer>
    </Modal>
  )
}

export function InfoRemoveLiquidityModal({ isOpen, onDismiss }: { isOpen: boolean; onDismiss: () => void }) {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxWidth={400}>
      <RemoveLiquidityInfoWrapper>
        <MoreLiquidityInfo>
          <MoreInfoHeading>
            {/* <Trans> */}What’s “Remove liquidity”{/* </Trans> */}
          </MoreInfoHeading>
          <CrossIconWrapper>
            <CrossIcon size={24} onClick={onDismiss} />
          </CrossIconWrapper>
        </MoreLiquidityInfo>

        <LiquidityDescription>
          Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to
          your share of the pool. Accrued fees are included in the amounts you receive.
        </LiquidityDescription>

        <section>
          <LearnMoreHeading>More helpful link on this:</LearnMoreHeading>
          <LearnMoreList>
            <LearnMoreItem
              href="https://axie-infinity.gitbook.io/katana-docs/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>Learn about provide liquidity</li>
            </LearnMoreItem>
            <LearnMoreItem
              href="https://support.axieinfinity.com/hc/en-us/articles/4409315514907-Katana-Ronin-Dex-Overview"
              target="_blank"
              rel="noopener"
            >
              <li>FAQ</li>
            </LearnMoreItem>
            {/* <a className="text-primary-5 font-regular hover:font-regular cursor-pointer">
              <li>Top pool</li>
            </a> */}
          </LearnMoreList>
        </section>
      </RemoveLiquidityInfoWrapper>
    </Modal>
  )
}

export function AddRemoveTabs({
  adding,
  creating,
  positionID,
  defaultSlippage,
}: {
  adding: boolean
  creating: boolean
  positionID?: string | undefined
  defaultSlippage: Percent
}) {
  const theme = useTheme()

  // reset states on back
  const dispatch = useAppDispatch()
  const [openDescModal, setOpenDescModal] = useState<boolean>(false)
  const [openDescRemoveModal, setOpenDescRemoveModal] = useState<boolean>(false)

  const toggleOpenDescModal = () => setOpenDescModal(!openDescModal)
  const toggleOpenDescRemoveModal = () => setOpenDescRemoveModal(!openDescRemoveModal)

  return (
    <Tabs>
      <RowBetween style={{ padding: '1.5rem 1rem 0 1rem' }}>
        <HistoryLink
          to={'/pool' + (!!positionID ? `/${positionID.toString()}` : '')}
          onClick={() => {
            if (adding) {
              // not 100% sure both of these are needed
              dispatch(resetMintState())
              dispatch(resetMintV3State())
            }
          }}
        >
          <StyledArrowLeft stroke={theme.text2} />
        </HistoryLink>

        <TabHeader fontSize={20} fontWeight={600} lineHeight="28px">
          {creating ? (
            <>
              {/* <Trans> */}Create a pair{/* </Trans> */}
            </>
          ) : adding ? (
            <LiquidityActionItem>
              {/* <Trans> */}Add Liquidity{/* </Trans> */}
              <LiquidityButtonWrapper>
                <QuestionIcon onClick={toggleOpenDescModal} />
              </LiquidityButtonWrapper>
            </LiquidityActionItem>
          ) : (
            <LiquidityActionItem>
              {/* <Trans> */}Remove Liquidity{/* </Trans> */}
              <LiquidityButtonWrapper>
                <QuestionIcon onClick={toggleOpenDescRemoveModal} />
              </LiquidityButtonWrapper>
            </LiquidityActionItem>
          )}
        </TabHeader>
        <SettingsTab placeholderSlippage={defaultSlippage} />
      </RowBetween>
      <InfoAddLiquidyModal isOpen={openDescModal} onDismiss={toggleOpenDescModal} />
      <InfoRemoveLiquidityModal isOpen={openDescRemoveModal} onDismiss={toggleOpenDescRemoveModal} />
    </Tabs>
  )
}
