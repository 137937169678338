// NOTE: disable this eslint rule for internal app's tracking user purpose (FOR INTERNAL APP URL ONLY)
/* eslint-disable react/jsx-no-target-blank */
import {
  ArrowDownIcon,
  FilledShieldIcon,
  FilledInfoIcon,
  LoopIcon,
  QuestionIcon,
  CrossIcon,
  ExternalLinkIcon,
} from '@axieinfinity/mochi-icons'
import { Currency, CurrencyAmount, Token, TradeType } from '@uniswap/sdk-core'
import { Trade as V2Trade } from '@uniswap/v2-sdk'
import { Trade as V3Trade } from '@uniswap/v3-sdk'
import { AdvancedSwapDetails } from 'components/swap/AdvancedSwapDetails'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import { MouseoverTooltipContent } from 'components/Tooltip'
import JSBI from 'jsbi'
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { HelpCircle, Info } from 'react-feather'
import ReactGA from 'react-ga'
import { RouteComponentProps } from 'react-router-dom'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonConfirmed, ButtonError as BaseButtonError } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import Row, { AutoRow, RowFixed } from '../../components/Row'
import BetterTradeLink from '../../components/swap/BetterTradeLink'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'

import { Badge, Button, Spinner, Tooltip, Well } from '@axieinfinity/mochi'
import { AppContext } from 'index'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
import {
  ArrowWrapper as BaseArrowWrapper,
  BottomGrouping,
  Dots,
  SwapCallbackError,
  Wrapper,
} from '../../components/swap/styleds'
import SwapHeader from '../../components/swap/SwapHeader'
import TradePrice from '../../components/swap/TradePrice'
import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import TokenWarningModal from '../../components/TokenWarningModal'
import { useAllTokens, useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import { V3TradeState } from '../../hooks/useBestV3Trade'
import useENSAddress from '../../hooks/useENSAddress'
import { useERC20PermitFromTrade, UseERC20PermitState } from '../../hooks/useERC20Permit'
import useIsArgentWallet from '../../hooks/useIsArgentWallet'
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { Version } from '../../hooks/useToggledVersion'
import { useUSDCValue } from '../../hooks/useUSDCPrice'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useActiveWeb3React } from '../../hooks/web3'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from '../../state/swap/hooks'
import { useExpertModeManager, useUserSingleHopOnly } from '../../state/user/hooks'
import { ExternalLink, LinkStyledButton, TYPE } from '../../theme'
import { computeFiatValuePriceImpact } from '../../utils/computeFiatValuePriceImpact'
import { getTradeVersion } from '../../utils/getTradeVersion'
import { isTradeBetter } from '../../utils/isTradeBetter'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody'
import { getFreeSwapRon } from 'services/rpc/free-gas-rpc'
import { ThirdPartyWarning } from 'components/ThirdPartyWarning/ThirdPartyWarning'
import { EXPLORER_URL } from 'utils/getExplorerLink'

const StyledInfo = styled(Info)`
  opacity: 0.4;
  color: ${({ theme }) => theme.text1};
  height: 16px;
  width: 16px;
  :hover {
    opacity: 0.8;
  }
`
const ArrowWrapper = styled(BaseArrowWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`
const ButtonConnectWrapper = styled(ButtonWrapper)`
  gap: 4px;
`
const ButtonApproveWrapper = styled(ButtonWrapper)`
  gap: 8px;
`
const ButtonApprove = styled(Button)`
  flex: 1 1 0%;
  margin-top: 8px;
  @media (min-width: 768px) {
    margin-top: 0;
    flex-grow: 0;
    margin-left: 8px;
  }
`
const ButtonError = styled(BaseButtonError)`
  font-size: 16px;
  width: 100%;
`
const ButtonFullWidth = styled(Button)`
  width: 100%;
`

const IconWrapper = styled.div`
  display: inline-block;
  color: var(--mc-color-blue-5);
`
const FilledShieldIconWrapper = styled(IconWrapper)`
  fill: 'currentcolor';
  min-width: 24px;
  margin-right: 12px;
`
const LoopIconWrapper = styled(IconWrapper)`
  display: flex;
  align-items: center;
  margin-right: 4px;
  color: var(--mc-color-blue-5);
  display: flex;
  align-items: center;
`
const Container = styled.div`
  display: relative;
`
const RowFixedBold = styled(RowFixed)`
  font-weight: 700;
  color: var(--mc-color-light-7);
`
const RowTrade = styled(Row)<{ shouldHidden?: boolean }>`
  justify-content: ${({ shouldHidden }) => (shouldHidden ? 'center' : 'space-between')};
  display: ${({ shouldHidden }) => (shouldHidden ? 'hidden' : 'flex')};
`
const RowRecipient = styled(AutoRow)`
  padding: 0 1rem;
`
const RowApproveFlow = styled(AutoRow)`
  flex-wrap: nowrap;
  width: 100%;
`
const AutoColumnFullWidth = styled(AutoColumn)`
  width: 100%;
`
const RowEl = styled(AutoRow)`
  flex-wrap: nowrap;
`
const RowContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
const RowBody = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`
const RowContent = styled.div`
  display: flex;
  align-items: center;
`

const TextWrapper = styled.span`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow-wrap: break-word;
  gap: 4px;
`
const TooltipContent = styled.div`
  width: 216px;
`
const LiquidityMiningUpdate = styled(Well)`
  position: relative;
  width: 100% !important;
  max-width: 480px;
`

const CloseIconWrapper = styled(IconWrapper)`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  color: var(--mc-color-light-7);
`

const StyledExternalLink = styled.a`
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  gap: 4px;
  font-size: var(--mc-font-size-normal);
  color: var(--mc-color-blue-5);
  line-height: var(--mc-line-height-medium);
  font-weight: var(--mc-font-weight-semi-bold);
`

export const FreeGasSwapRPCContext = createContext<{
  freeGasSwap?: number
}>({
  freeGasSwap: undefined,
})

const FreeGasSwapRPCProvider: React.FC<{ freeGasSwap?: number; children: React.ReactNode }> = ({
  freeGasSwap,
  children,
}: {
  freeGasSwap?: number
  children: React.ReactNode
}) => {
  return <FreeGasSwapRPCContext.Provider value={{ freeGasSwap }}>{children}</FreeGasSwapRPCContext.Provider>
}

export const SWAP_CURRENCY_INPUT_PANEL_ID = {
  input: 'swap-currency-input',
  output: 'swap-currency-output',
} as const

export default function Swap({ history }: RouteComponentProps) {
  const loadedUrlParams = useDefaultsFromURLSearch()
  const { account, chainId } = useActiveWeb3React()
  const swapContainerRef = useRef<HTMLDivElement>(null)
  const { pendingLogin } = useContext(AppContext)
  const [showSwapRonFreeBanner, setShowSwapRonFreeBanner] = useState(true)

  const [freeGasSwap, setFreeGasSwap] = useState<number | undefined>()

  const revalidateFreeGasSwap = async () => {
    const data = await getFreeSwapRon(account, chainId)
    setFreeGasSwap(data)
  }

  useEffect(() => {
    revalidateFreeGasSwap()
  }, [account, chainId])

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId),
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c?.isToken ?? false) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useAllTokens()
  const importTokensNotInDefault =
    urlLoadedTokens &&
    urlLoadedTokens.filter((token: Token) => {
      return !Boolean(token.address.toLowerCase() in defaultTokens)
    })
  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  // get version from the url
  const toggledVersion = useToggledVersion()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    v2Trade,
    v3TradeState: { trade: v3Trade, state: v3TradeState },
    toggledTrade: trade,
    allowedSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo(toggledVersion)

  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
          }
        : {
            [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
            [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
          },
    [independentField, parsedAmount, showWrap, trade]
  )

  const fiatValueInput = useUSDCValue(parsedAmounts[Field.INPUT])
  const fiatValueOutput = useUSDCValue(parsedAmounts[Field.OUTPUT])
  const priceImpact = computeFiatValuePriceImpact(fiatValueInput, fiatValueOutput)

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
    history.push('/swap/')
  }, [history])

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: V2Trade<Currency, Currency, TradeType> | V3Trade<Currency, Currency, TradeType> | undefined
    attemptingTxn: boolean
    swapErrorMessage: { title: string; message: string } | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  const routeNotFound = !trade?.route
  const isLoadingRoute = toggledVersion === Version.v3 && V3TradeState.LOADING === v3TradeState

  // check whether the user has approved the router on the input token
  const [approvalState, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)
  const { state: signatureState, signatureData, gatherPermitSignature } = useERC20PermitFromTrade(
    trade,
    allowedSlippage
  )

  const handleApprove = useCallback(async () => {
    if (signatureState === UseERC20PermitState.NOT_SIGNED && gatherPermitSignature) {
      try {
        await gatherPermitSignature()
      } catch (error) {
        // try to approve if gatherPermitSignature failed for any reason other than the user rejecting it
        if ((error as any)?.code !== 4001) {
          await approveCallback()
        }
      }
    } else {
      await approveCallback()
    }
  }, [approveCallback, gatherPermitSignature, signatureState])

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approvalState === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approvalState, approvalSubmitted])

  const maxInputAmount: CurrencyAmount<Currency> | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const showMaxButton = Boolean(maxInputAmount?.greaterThan(0) && !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    recipient,
    signatureData
  )

  const [singleHopOnly] = useUserSingleHopOnly()

  const handleSwap = useCallback(() => {
    if (!swapCallback) {
      return
    }
    if (priceImpact && !confirmPriceImpactWithoutFee(priceImpact)) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then((hash) => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })

        ReactGA.event({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
              ? 'Swap w/o Send + recipient'
              : 'Swap w/ Send',
          label: [
            trade?.inputAmount?.currency?.symbol,
            trade?.outputAmount?.currency?.symbol,
            getTradeVersion(trade),
            singleHopOnly ? 'SH' : 'MH',
          ].join('/'),
        })
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error,
          txHash: undefined,
        })
      })
  }, [
    priceImpact,
    swapCallback,
    tradeToConfirm,
    showConfirm,
    recipient,
    recipientAddress,
    account,
    trade,
    singleHopOnly,
  ])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on the greater of fiat value price impact and execution price impact
  const priceImpactSeverity = useMemo(() => {
    const executionPriceImpact = trade?.priceImpact
    return warningSeverity(
      executionPriceImpact && priceImpact
        ? executionPriceImpact.greaterThan(priceImpact)
          ? executionPriceImpact
          : priceImpact
        : executionPriceImpact ?? priceImpact
    )
  }, [priceImpact, trade])

  const isArgentWallet = useIsArgentWallet()

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !isArgentWallet &&
    !swapInputError &&
    (approvalState === ApprovalState.NOT_APPROVED ||
      approvalState === ApprovalState.PENDING ||
      (approvalSubmitted && approvalState === ApprovalState.APPROVED)) &&
    !(signatureState === UseERC20PermitState.SIGNED) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
    revalidateFreeGasSwap()
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    // maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toSignificant())
    maxInputAmount && onUserInput(Field.INPUT, formatCurrencyAmount(maxInputAmount, undefined, true).toString())
  }, [maxInputAmount, onUserInput])

  const handleOutputSelect = useCallback((outputCurrency) => onCurrencySelection(Field.OUTPUT, outputCurrency), [
    onCurrencySelection,
  ])

  const swapIsUnsupported = useIsSwapUnsupported(currencies?.INPUT, currencies?.OUTPUT)

  const priceImpactTooHigh = priceImpactSeverity > 3 && !isExpertMode

  const isThirdPartyToken = currencies?.INPUT?.symbol === 'BERRY' || currencies?.OUTPUT?.symbol === 'BERRY'

  return (
    <FreeGasSwapRPCProvider freeGasSwap={freeGasSwap}>
      <TokenWarningModal
        isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
        tokens={importTokensNotInDefault}
        onConfirm={handleConfirmTokenWarning}
        onDismiss={handleDismissTokenWarning}
      />
      {showSwapRonFreeBanner && (
        <LiquidityMiningUpdate intent="primary" title="We’re moving Katana DEX to Ronin Apps!" icon={FilledInfoIcon}>
          <div style={{ marginTop: 8, width: 392 }}>
            After May 7, 2024, katana.roninchain.com will be redirected to{' '}
            <StyledExternalLink href={`${EXPLORER_URL[chainId]}/swap`} target="_blank">
              app.roninchain.com/swap <ExternalLinkIcon size={16} />
            </StyledExternalLink>
          </div>
          <CloseIconWrapper onClick={() => setShowSwapRonFreeBanner(false)}>
            <CrossIcon size={24} />
          </CloseIconWrapper>
        </LiquidityMiningUpdate>
      )}

      {isThirdPartyToken && <ThirdPartyWarning />}

      <AppBody>
        <SwapHeader allowedSlippage={allowedSlippage} />
        <Wrapper id="swap-page" ref={swapContainerRef}>
          <ConfirmSwapModal
            isOpen={showConfirm}
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            recipient={recipient}
            allowedSlippage={allowedSlippage}
            onConfirm={handleSwap}
            swapErrorMessage={swapErrorMessage}
            onDismiss={handleConfirmDismiss}
          />

          <AutoColumn gap={'md'}>
            <Container>
              <CurrencyInputPanel
                label={independentField === Field.OUTPUT && !showWrap ? 'From (at most)' : 'From'}
                value={formattedAmounts[Field.INPUT]}
                showMaxButton={showMaxButton}
                currency={currencies[Field.INPUT]}
                isDeposit
                onUserInput={handleTypeInput}
                onMax={handleMaxInput}
                fiatValue={fiatValueInput ?? undefined}
                onCurrencySelect={handleInputSelect}
                otherCurrency={currencies[Field.OUTPUT]}
                showCommonBases={true}
                id={SWAP_CURRENCY_INPUT_PANEL_ID.input}
                isSwap
              />
              <ArrowWrapper clickable>
                <ArrowDownIcon
                  size={24}
                  onClick={() => {
                    setApprovalSubmitted(false) // reset 2 step UI for approvals
                    onSwitchTokens()
                  }}
                  // color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? theme.text1 : theme.text3}
                  // color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? 'var(--mc-color-light-7)' : theme.text3}
                  color="currentColor"
                />
              </ArrowWrapper>
              <CurrencyInputPanel
                value={formattedAmounts[Field.OUTPUT]}
                onUserInput={handleTypeOutput}
                label={independentField === Field.INPUT && !showWrap ? 'To (at least)' : 'To'}
                showMaxButton={false}
                hideBalance={false}
                fiatValue={fiatValueOutput ?? undefined}
                priceImpact={priceImpact}
                currency={currencies[Field.OUTPUT]}
                onCurrencySelect={handleOutputSelect}
                otherCurrency={currencies[Field.INPUT]}
                showCommonBases={true}
                id={SWAP_CURRENCY_INPUT_PANEL_ID.output}
                isSwap
              />
            </Container>

            {recipient !== null && !showWrap ? (
              <>
                <RowRecipient justify="space-between">
                  <ArrowWrapper clickable={false}>
                    <ArrowDownIcon size={16} />
                  </ArrowWrapper>
                  <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                    {/* <Trans> */}- Remove send
                    {/* </Trans> */}
                  </LinkStyledButton>
                </RowRecipient>
                <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
              </>
            ) : null}
            {showWrap ? null : (
              <RowTrade shouldHidden={!trade}>
                <RowFixed>
                  {[V3TradeState.VALID, V3TradeState.SYNCING, V3TradeState.NO_ROUTE_FOUND].includes(v3TradeState) &&
                    (toggledVersion === Version.v3 && isTradeBetter(v3Trade, v2Trade) ? (
                      <BetterTradeLink version={Version.v2} otherTradeNonexistent={!v3Trade} />
                    ) : toggledVersion === Version.v2 && isTradeBetter(v2Trade, v3Trade) ? (
                      <BetterTradeLink version={Version.v3} otherTradeNonexistent={!v2Trade} />
                    ) : (
                      toggledVersion === Version.v2 && (
                        <></>
                        // <ButtonGray
                        //   width="fit-content"
                        //   padding="0.1rem 0.5rem 0.1rem 0.35rem"
                        //   as={Link}
                        //   to="/swap"
                        //   style={{
                        //     display: 'flex',
                        //     justifyContent: 'space-between',
                        //     alignItems: 'center',
                        //     height: '24px',
                        //     opacity: 0.8,
                        //     lineHeight: '120%',
                        //     marginLeft: '0.75rem',
                        //   }}
                        // >
                        //   <ArrowLeft color={theme.text3} size={12} /> &nbsp;
                        //   <TYPE.main style={{ lineHeight: '120%' }} fontSize={12}>
                        //     <Trans>
                        //       <HideSmall>Back to </HideSmall>
                        //     </Trans>
                        //   </TYPE.main>
                        // </ButtonGray>
                      )
                    ))}

                  {/* {toggledVersion === Version.v3 && trade && isTradeBetter(v2Trade, v3Trade) && (
                    <ButtonGray
                      width="fit-content"
                      padding="0.1rem 0.5rem"
                      disabled
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        height: '24px',
                        opacity: 0.4,
                        marginLeft: '0.25rem',
                      }}
                    >
                      <TYPE.black fontSize={12}>
                        <Trans>V3</Trans>
                      </TYPE.black>
                    </ButtonGray>
                  )} */}
                </RowFixed>
                {trade ? (
                  <RowFixedBold>
                    <LoopIconWrapper>
                      <LoopIcon size={16} />
                    </LoopIconWrapper>
                    <TradePrice
                      price={trade.executionPrice}
                      showInverted={showInverted}
                      setShowInverted={setShowInverted}
                    />
                    <MouseoverTooltipContent
                      content={<AdvancedSwapDetails trade={trade} isTooltip allowedSlippage={allowedSlippage} />}
                      placement="bottom"
                    >
                      <QuestionIcon />
                      {/* <StyledInfo /> */}
                    </MouseoverTooltipContent>
                  </RowFixedBold>
                ) : null}
              </RowTrade>
            )}
            {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage?.message} /> : null}
            <BottomGrouping>
              {swapIsUnsupported ? (
                <ButtonFullWidth disabled={true} intent="secondary" size="large">
                  <TYPE.main mb="4px">
                    {/* <Trans> */}
                    Unsupported Asset
                    {/* </Trans> */}
                  </TYPE.main>
                </ButtonFullWidth>
              ) : !account ? (
                // <ButtonLightBG onClick={toggleWalletModal}>
                <ButtonFullWidth disabled={pendingLogin} size="large" onClick={toggleWalletModal} intent="secondary">
                  <ButtonConnectWrapper>
                    {pendingLogin && <Spinner intent="primary" size="small" />}
                    Connect Ronin Wallet
                  </ButtonConnectWrapper>
                </ButtonFullWidth>
              ) : // </ButtonLightBG>
              showWrap ? (
                // <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
                <ButtonFullWidth intent="secondary" size="large" disabled={Boolean(wrapInputError)} onClick={onWrap}>
                  {wrapInputError ??
                    (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
                </ButtonFullWidth>
              ) : // </ButtonPrimary>
              routeNotFound && userHasSpecifiedInputOutput ? (
                // <GreyCard style={{ textAlign: 'center' }}>
                <ButtonFullWidth intent="secondary" minimal disabled size="large">
                  {/* <TYPE.main mb="4px"> */}
                  {isLoadingRoute ? (
                    <Dots>
                      {/* <Trans>Loading</Trans> */}
                      Loading
                    </Dots>
                  ) : singleHopOnly ? (
                    // <Trans>Insufficient liquidity for this trade. Try enabling multi-hop trades.</Trans>
                    'Insufficient liquidity for this trade. Try enabling multi-hop trades.'
                  ) : (
                    // <Trans>Insufficient liquidity for this trade.</Trans>
                    'Insufficient liquidity for this trade.'
                    // <>Unsupported Pair</>
                  )}
                  {/* </TYPE.main> */}
                </ButtonFullWidth>
              ) : // </GreyCard>
              showApproveFlow ? (
                <RowApproveFlow>
                  <AutoColumnFullWidth gap="12px">
                    {approvalState !== ApprovalState.APPROVED && (
                      <ButtonConfirmed
                        disabled={approvalState !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                        width="100%"
                        altDisabledStyle={approvalState === ApprovalState.PENDING} // show solid button while waiting
                      >
                        <RowEl justify="space-between">
                          <RowContainer>
                            {/* <CurrencyLogo
                            currency={currencies[Field.INPUT]}
                            size={'20px'}
                            style={{ marginRight: '8px', flexShrink: 0 }}
                          /> */}
                            {/* we need to shorten this string on mobile */}
                            <RowBody>
                              <RowContent>
                                <FilledShieldIconWrapper>
                                  <FilledShieldIcon size={24} />
                                </FilledShieldIconWrapper>
                                <TextWrapper>
                                  <span>Allow Katana to use your {currencies[Field.INPUT]?.symbol}</span>
                                  <Tooltip target={<HelpCircle size="16" color="var(--mc-color-blue-5)" />}>
                                    <TooltipContent>
                                      You must give the Katana smart contract permission to use your{' '}
                                      {currencies[Field.INPUT]?.symbol}. You only have to do this once per token.
                                    </TooltipContent>
                                  </Tooltip>
                                </TextWrapper>
                              </RowContent>
                              <ButtonApprove intent="primary" onClick={handleApprove} size="small">
                                {/* <Trans>Approve {currencies[Field.INPUT]?.symbol}</Trans> */}
                                <ButtonApproveWrapper>
                                  Approve {currencies[Field.INPUT]?.symbol}
                                  {approvalState === ApprovalState.PENDING ? <Spinner size="small" /> : undefined}
                                </ButtonApproveWrapper>
                              </ButtonApprove>
                            </RowBody>
                          </RowContainer>
                        </RowEl>
                      </ButtonConfirmed>
                    )}
                    {approvalState === ApprovalState.APPROVED && (
                      <ButtonError
                        onClick={() => {
                          if (isExpertMode) {
                            handleSwap()
                          } else {
                            setSwapState({
                              tradeToConfirm: trade,
                              attemptingTxn: false,
                              swapErrorMessage: undefined,
                              showConfirm: true,
                              txHash: undefined,
                            })
                          }
                        }}
                        size="large"
                        id="swap-button"
                        disabled={!isValid || approvalState !== ApprovalState.APPROVED || priceImpactTooHigh}
                        error={isValid && priceImpactSeverity > 2}
                      >
                        <Text fontSize={16} fontWeight={500}>
                          {priceImpactTooHigh
                            ? // <Trans>High Price Impact</Trans>
                              'High Price Impact'
                            : priceImpactSeverity > 2
                            ? // <Trans>Swap Anyway</Trans>
                              'Swap Anyway'
                            : // <Trans>Swap</Trans>
                              'Swap'}
                        </Text>
                      </ButtonError>
                    )}
                  </AutoColumnFullWidth>
                </RowApproveFlow>
              ) : (
                <ButtonError
                  onClick={() => {
                    if (isExpertMode) {
                      handleSwap()
                    } else {
                      setSwapState({
                        tradeToConfirm: trade,
                        attemptingTxn: false,
                        swapErrorMessage: undefined,
                        showConfirm: true,
                        txHash: undefined,
                      })
                    }
                  }}
                  size="large"
                  id="swap-button"
                  disabled={!isValid || priceImpactTooHigh || !!swapCallbackError}
                  error={(isValid && priceImpactSeverity > 2 && !swapCallbackError) || swapInputError}
                >
                  {swapInputError ? (
                    <Text fontSize={16} fontWeight={600} opacity={1} color="var(--mc-color-light-6)">
                      {swapInputError}
                    </Text>
                  ) : priceImpactTooHigh ? (
                    // <Trans>Price Impact Too High</Trans>
                    'Price Impact Too High'
                  ) : priceImpactSeverity > 2 ? (
                    'Swap Anyway'
                  ) : (
                    'Swap'
                  )}
                </ButtonError>
              )}
            </BottomGrouping>
          </AutoColumn>
        </Wrapper>
      </AppBody>
      <SwitchLocaleLink />
      {!swapIsUnsupported ? null : (
        <UnsupportedCurrencyFooter show={swapIsUnsupported} currencies={[currencies.INPUT, currencies.OUTPUT]} />
      )}
    </FreeGasSwapRPCProvider>
  )
}
