import { Tag } from '@axieinfinity/mochi'
import { CopyIcon } from '@axieinfinity/mochi-icons'
import React from 'react'
import styled from 'styled-components'
import useCopyClipboard from '../../hooks/useCopyClipboard'

const StyleTag = styled(Tag)`
  height: 20px;
`
export default function CopyHelper(props: { toCopy: string; children?: React.ReactNode }) {
  const [isCopied, setCopied] = useCopyClipboard()

  return (
    <StyleTag
      onClick={() => {
        setCopied(props.toCopy)
      }}
      intent="grey"
      interactive
      leftIcon={CopyIcon}
    >
      {isCopied ? 'Copied' : 'Copy address'}
    </StyleTag>
  )
}
