import { Currency, CurrencyAmount, Token } from '@uniswap/sdk-core'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import isNil from 'lodash/isNil'
import React from 'react'
import styled from 'styled-components'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'

interface TokenInputBoxProps {
  amountInEth: string
  onChangeAmount: (value: string) => void
  firstTokenInfo?: Currency
  secondTokenInfo?: Currency
  maxAmount?: CurrencyAmount<Token>
  insufficientError?: boolean
}

const Box = styled.div<{ insufficientError?: boolean }>`
  border-width: 1px;
  border-color: var(--mc-color-light-3);
  padding: 16px;
  width: 100%;
  justify-content: space-between;
  border-radius: 12px;
  margin-top: 24px;
  position: relative;
  border-color: ${({ insufficientError }) => (insufficientError ? 'var(--mc-color-red-5)' : 'var(--mc-color-light-3)')};
`

const CurrencyInfoSection = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 16px;
  padding-right: 16px;
`
const CurrencyInfoWrapper = styled.div`
  display: flex;
  padding: 4px 8px 4px 8px;
  background-color: var(--mc-color-light-1);
  border-radius: 12px;
  font-weight: 600;
  line-height: 20px;
  align-items: center;
`
const MainHeading = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  line-height: 16px;
  color: var(--mc-color-light-7);
  letter-spacing: 0.5px;
  font-size: 10px;
`

const TokeninputWrapper = styled.div`
  margin-top: 4px;
  width: 100%;
  max-width: 55%;
`

const TokenInput = styled.input<{ insufficientError?: boolean }>`
  font-size: 28px;
  line-height: 40px;
  width: 100%;
  ${({ insufficientError }) => insufficientError && 'color: var(--mc-color-red-5)'};
`

const MaxSelectionSection = styled.div`
  display: flex;
  margin-top: 4px;
`
const BalanceWrapper = styled.div`
  line-height: 20px;
  color: var(--mc-color-light-7);
`

const MaxSelectionButton = styled.div`
  margin-left: 8px;
  cursor: pointer;
  display: inline-block;
  background-color: var(--mc-color-blue-1);
  padding: 0 8px 0 8px;
  border-radius: 8px;
  border-width: 1px;
  border-color: var(--mc-color-blue-5);
  color: var(--mc-color-blue-5);
  font-size: 10px;
  height: 22px;
  line-height: 20px;
`
export const TokenInputBox = ({
  amountInEth,
  onChangeAmount,
  maxAmount,
  insufficientError,
  firstTokenInfo,
  secondTokenInfo,
}: TokenInputBoxProps) => {
  const onChangeAmountInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeAmount(event.target.value)
  }

  const onClickMax = () => {
    if (!isNil(maxAmount)) {
      const amount = maxAmount.toExact()
      onChangeAmount(amount ?? '0')
    }
  }

  const lpTokenName = `${firstTokenInfo?.symbol ?? `UNKN`}-${secondTokenInfo?.symbol ?? `UNKN`} LP`

  return (
    <Box insufficientError={insufficientError}>
      <CurrencyInfoSection>
        <CurrencyInfoWrapper>
          <div>
            <DoubleCurrencyLogo currency0={firstTokenInfo} currency1={secondTokenInfo} size={24} />
          </div>
          {lpTokenName}
        </CurrencyInfoWrapper>
      </CurrencyInfoSection>

      <MainHeading>Amount</MainHeading>

      <TokeninputWrapper>
        <TokenInput
          min={0}
          step={1}
          lang="en"
          type="number"
          value={amountInEth}
          onChange={onChangeAmountInput}
          className="non-arrow-number-input"
        />
      </TokeninputWrapper>

      <MaxSelectionSection>
        <BalanceWrapper>
          <span>Balance: </span>
          <span>{formatCurrencyAmount(maxAmount)}</span>
          <span> {lpTokenName}</span>
        </BalanceWrapper>

        <MaxSelectionButton onClick={onClickMax}>Max</MaxSelectionButton>
      </MaxSelectionSection>
    </Box>
  )
}
