import { HTMLAttributes, ReactNode } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--mc-color-light-1);
  border-radius: var(--mc-space-inset-8x);
`
interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}

export const TokenAvatar = ({ children, ...rest }: Props) => {
  return <Container>{children}</Container>
}
