import {
  IConnector,
  RoninExtConnector,
  RoninWcConnector,
  ConnectorError,
  GnosisConnector,
} from '@roninnetwork/walletgo'
import { IEip1193Provider } from '@roninnetwork/walletgo/dist/types/common/eip1193'
import { WrapWCProvider } from '@roninnetwork/walletgo/dist/types/connectors/ronin-mobile/WrapProvider'
import { SafeAppProvider } from '@safe-global/safe-apps-provider/dist/provider'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { AbstractConnectorArguments, ConnectorUpdate } from '@web3-react/types'
import { DEFAULT_CHAIN_ID } from 'constants/chains'
import { isIOS, isMobile } from 'react-device-detect'
import warning from 'tiny-warning'

export const checkInAppBrowser = () => {
  if (typeof window !== 'undefined') {
    return !!window.isWalletApp && window.ronin !== undefined
  } else {
    return false
  }
}

const noop = () => {}
const WC_PROJECT_ID = 'd2ef97836db7eb390bcb2c1e9847ecdc'
export class RoninConnector extends AbstractConnector {
  connector: IConnector<IEip1193Provider | WrapWCProvider | SafeAppProvider>

  constructor(kwargs: AbstractConnectorArguments) {
    super(kwargs)

    this.handleNetworkChanged = this.handleNetworkChanged.bind(this)
    this.handleChainChanged = this.handleChainChanged.bind(this)
    this.handleAccountsChanged = this.handleAccountsChanged.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.connector = this.initRoninConnector()
    // this.initGnosis()
  }

  private initRoninConnector() {
    const useExt = !isMobile || checkInAppBrowser()

    const newConnector = useExt
      ? RoninExtConnector.create({})
      : RoninWcConnector.create({
          projectId: WC_PROJECT_ID,
          clientMeta: {
            description: '',
            name: 'Katana',
            icons: ['https://cdn.axieinfinity.com/dapps/profit.png'],
            url: 'https://katana.roninchain.com',
            redirect: {
              universal: 'https://katana.roninchain.com',
            },
          },
        })

    newConnector.events.on('CONNECT_BY_QR', (uri: string) => {
      console.debug('CONNECTURI', uri)
    })

    return newConnector
  }

  async initGnosis() {
    const gnosisConnector = GnosisConnector.create({})
    await gnosisConnector.shouldAutoConnect()
    if (gnosisConnector.provider && this.connector.id !== 'GNOSIS_CONNECTOR') {
      this.connector = gnosisConnector
    }
  }

  private handleChainChanged(chainId: string | number): void {
    this.emitUpdate({ chainId, provider: this.connector.provider })
  }

  public handleAccountsChanged(accounts: string[]): void {
    if (accounts.length === 0 || accounts[0] === undefined) {
      this.emitDeactivate()
    } else {
      this.emitUpdate({ account: accounts[0] })
    }
  }

  private handleClose(code: number, reason: string): void {
    this.emitDeactivate()
  }

  private handleNetworkChanged(networkId: string | number): void {
    this.emitUpdate({ chainId: networkId, provider: this.connector.provider })
  }

  public async activate(): Promise<ConnectorUpdate> {
    await this.initGnosis()
    const walletConnector = this.connector
    const { account, chainId, provider } = await walletConnector.connect(DEFAULT_CHAIN_ID)

    walletConnector.events.on('SWITCH_ACCOUNT', (nextAccount) => this.handleAccountsChanged([nextAccount]))
    walletConnector.events.on('SWITCH_CHAIN', ({ chainId }) => this.handleChainChanged(chainId))
    walletConnector.events.on('DISCONNECTED', () => this.emitDeactivate())

    return { provider, account, chainId }
  }

  public async getProvider() {
    return this.connector.provider
  }

  public async getChainId(): Promise<number | string> {
    if (!this.connector || !this.connector.provider) {
      throw new ConnectorError('NotConnected')
    }

    let chainId: number | string = ''
    try {
      chainId = await this.connector.provider.request<number | string>({
        method: 'eth_chainId',
      })
    } catch {
      warning(false, 'eth_chainId was unsuccessful, falling back to net_version')
    }

    return chainId
  }

  public async getAccount(): Promise<null | string> {
    if (!this.connector || !this.connector.provider) {
      throw new ConnectorError('NotConnected')
    }

    let account = null
    try {
      const accounts = await this.connector.provider.request<string[]>({ method: 'eth_accounts' })
      account = accounts[0]
    } catch {
      warning(false, 'eth_accounts was unsuccessful, falling back to enable')
    }

    return account
  }

  public async deactivate() {
    console.log('Deactive ')

    this.connector.events.removeListener('SWITCH_CHAIN', noop)
    this.connector.events.removeListener('SWITCH_ACCOUNT', noop)
    this.connector.events.removeListener('OPEN_WALLET_FOR_SIGN', noop)
    this.connector.events.removeListener('CONNECT_BY_LINK', noop)
    this.connector.events.removeListener('DISCONNECTED', noop)

    this.connector.disconnect()
  }

  public async isAuthorized(): Promise<boolean> {
    if (!this.connector || !this.connector.provider) {
      return false
    }

    try {
      const accounts = await this.connector.provider.request<string[]>({ method: 'eth_accounts' })
      return accounts.length > 0
    } catch {
      return false
    }
  }
}
