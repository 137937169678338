import { forwardRef, HTMLProps } from 'react'
import styled from 'styled-components'

export interface NavButtonProps extends HTMLProps<HTMLAnchorElement> {
  isActive?: boolean
  testing?: boolean
}

interface INavBtn {
  isActive?: boolean
}

export const NavBtn = styled.a<INavBtn>`
  display: inline-flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  font-weight: 600;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 12px;
  background-color: ${(props) => (props.isActive ? 'var(--mc-color-blue-1)' : '')};
  &:hover {
    background-color: ${(props) => (!props.isActive ? 'var(--mc-color-light-1)' : '')};
    color: ${(props) => (props.isActive ? 'var(--mc-color-blue-5)' : '')};
  }
  color: ${(props) => (props.isActive ? 'var(--mc-color-blue-5)' : 'var(--mc-color-black)')};
`
export const NavButton = forwardRef<HTMLAnchorElement, NavButtonProps>(
  ({ isActive, testing, children, ...restProps }, ref) => {
    return (
      <NavBtn isActive={isActive} ref={ref}>
        {children}
      </NavBtn>
    )
  }
)

NavButton.displayName = 'NavButton'
