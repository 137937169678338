import { Button } from '@axieinfinity/mochi'
import { NavLink, NavLinkProps } from 'react-router-dom'
import styled from 'styled-components/macro'

const ACTIVE_NAV_LINK_CL = 'ACTIVE_NAV_LINK'

// Override text underline and design-system button
const NoUnderlineNavLink = styled(NavLink)`
  text-decoration: none !important;

  &.${ACTIVE_NAV_LINK_CL} .intent-module_secondary__e7wGV {
    background-color: var(--mc-color-blue-1) !important;
    color: var(--mc-color-blue-5) !important;
  }
`

interface ButtonNavLinkProps extends NavLinkProps {
  label: string
  disabled?: boolean
}

export const ButtonNavLink = (props: ButtonNavLinkProps) => {
  const { label, disabled, activeClassName, ...navLinkProps } = props

  if (disabled)
    return (
      <Button size="small" minimal disabled={disabled}>
        {/* <Trans> */}
        {label}
        {/* </Trans> */}
      </Button>
    )

  return (
    <NoUnderlineNavLink {...navLinkProps} activeClassName={ACTIVE_NAV_LINK_CL}>
      <Button size="small" intent="secondary" minimal style={{ marginRight: 12 }}>
        {/* <Trans> */}
        {label}
        {/* </Trans> */}
      </Button>
    </NoUnderlineNavLink>
  )
}
