import { AppBody, Layout } from '@axieinfinity/mochi-pattern'
import { MobileNavBar } from 'components/Header/MobileNavBar'
import { RNSBanner } from 'components/RNSBanner'
import { TestnetIndicator } from 'components/TestnetIndicator/TestnetIndicator'
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import { isMobile } from 'react-device-detect'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components/macro'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import AddressClaimModal from '../components/claim/AddressClaimModal'
import ErrorBoundary from '../components/ErrorBoundary'
import Header from '../components/Header'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import { ApplicationModal } from '../state/application/actions'
import { useModalOpen, useToggleModal } from '../state/application/hooks'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import { RedirectDuplicateTokenIds } from './AddLiquidity/redirects'
import { RedirectDuplicateTokenIdsV2 } from './AddLiquidityV2/redirects'
import { Farm } from './Farm/Farm'
import { PositionPage } from './Pool/PositionPage'
import PoolV2 from './Pool/v2'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import Swap from './Swap'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
import { useEffect } from 'react'
import { roninWallet } from 'connectors'
import { Button, Toast, useToast } from '@axieinfinity/mochi'
import { Text } from 'rebass'
import { IOpenWalletForSignEventArgs } from '@roninnetwork/walletgo'
const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 104px;
  align-items: center;
  flex: 1;
  z-index: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 4px;
  `};
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  max-width: 1280px;
  z-index: 2;
  margin: 0 auto;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

function TopLevelModals() {
  const open = useModalOpen(ApplicationModal.ADDRESS_CLAIM)
  const toggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  return <AddressClaimModal isOpen={open} onDismiss={toggle} />
}

type TToastContentProps = {
  appLink: string
}

const ToastContent: React.FC<TToastContentProps> = (props) => {
  // eslint-disable-next-line react/prop-types
  const { appLink } = props
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', gap: 8, alignItems: 'center', width: '100%' }}>
      <Text fontSize={12} fontWeight={500}>
        Check your Ronin Mobile Wallet app
      </Text>
      <a href={appLink}>
        <Button size="small">Open</Button>
      </a>
    </div>
  )
}

export default function App() {
  const toast = useToast({
    duration: 10,
  })

  const handleConnectByLink = (appLink: string) => {
    toast.open({ content: <ToastContent appLink={appLink} /> })
  }

  const handleOpenWalletForSign = (args: IOpenWalletForSignEventArgs) => {
    toast.open({ content: <ToastContent appLink={args.appLink} /> })
  }

  useEffect(() => {
    roninWallet.connector.events.on('CONNECT_BY_LINK', handleConnectByLink)
    roninWallet.connector.events.on('OPEN_WALLET_FOR_SIGN', handleOpenWalletForSign)

    return () => {
      roninWallet.connector.events.removeListener('CONNECT_BY_LINK', handleConnectByLink)
      roninWallet.connector.events.removeListener('OPEN_WALLET_FOR_SIGN', handleOpenWalletForSign)
    }
  }, [])

  return (
    <>
      <RNSBanner />
      <Layout hiddenNavBar={window.isWalletApp}>
        <AppBody>
          <ErrorBoundary>
            <Route component={GoogleAnalyticsReporter} />
            <Route component={DarkModeQueryParamReader} />
            <Route component={ApeModeQueryParamReader} />
            <AppWrapper>
              <TestnetIndicator />
              <HeaderWrapper>
                <Header />
              </HeaderWrapper>
              <BodyWrapper>
                <Popups />
                <TopLevelModals />
                <Web3ReactManager>
                  <Switch>
                    <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                    <Route exact strict path="/swap" component={Swap} />

                    <Route exact strict path="/pool/find" component={PoolFinder} />
                    <Route exact strict path="/pool" component={PoolV2} />
                    <Route exact strict path="/pool/:tokenId" component={PositionPage} />

                    <Route exact strict path="/farm" component={Farm} />

                    <Route
                      exact
                      strict
                      path="/add/:currencyIdA?/:currencyIdB?"
                      component={RedirectDuplicateTokenIdsV2}
                    />
                    <Route
                      exact
                      strict
                      path="/add/:currencyIdA?/:currencyIdB?/:feeAmount?"
                      component={RedirectDuplicateTokenIds}
                    />

                    <Route
                      exact
                      strict
                      path="/increase/:currencyIdA?/:currencyIdB?/:feeAmount?/:tokenId?"
                      component={AddLiquidity}
                    />

                    <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />

                    <Route component={RedirectPathToSwapOnly} />
                  </Switch>
                </Web3ReactManager>
                <Marginer />
              </BodyWrapper>
              {isMobile ? <MobileNavBar /> : null}
            </AppWrapper>
          </ErrorBoundary>
        </AppBody>
      </Layout>
      <Toast />
    </>
  )
}
