import { transparentize } from 'polished'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Well } from '@axieinfinity/mochi'
import { FilledAlertTriangleIcon } from '@axieinfinity/mochi-icons'
import { Text } from 'rebass'
import styled, { css } from 'styled-components'
import { AutoColumn } from '../Column'
export const Wrapper = styled.div`
  position: relative;
  padding: 20px;
`

export const ArrowWrapper = styled.div<{ clickable: boolean }>`
  padding: 2px;
  border-radius: 12px;
  height: 32px;
  width: 32px;
  position: relative;
  margin-top: -8px;
  color: var(--mc-color-light-7);
  margin-bottom: -8px;
  left: calc(50% - 16px);
  /* transform: rotate(90deg); */
  /* background-color: ${({ theme }) => theme.bg1}; */
  background-color: var(--mc-color-light-1);
  border: 2px solid ${({ theme }) => theme.bg0};
  z-index: 2;
  ${({ clickable }) =>
    clickable
      ? css`
          :hover {
            cursor: pointer;
            /* opacity: 0.8; */
            color: var(--mc-color-blue-5);
          }
        `
      : null}
`

export const SectionBreak = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.bg3};
`

export const BottomGrouping = styled.div`
  /* margin-top: 0; */
  /* background-color: ${({ theme }) => theme.bg1}; */
`

export const ErrorText = styled(Text)<{ severity?: 0 | 1 | 2 | 3 | 4 }>`
  color: ${({ theme, severity }) =>
    severity === 3 || severity === 4
      ? 'var(--mc-color-red-5)'
      : severity === 2
      ? 'var(--mc-color-red-5)'
      : severity === 1
      ? 'var(--mc-color-yellow-5)'
      : 'var(--mc-color-yellow-5)'};
`

export const ErrorPill = styled(Text)<{ severity?: 0 | 1 | 2 | 3 | 4 }>`
  border-radius: 8px;

  color: ${({ theme, severity }) =>
    severity === 3 || severity === 4
      ? 'var(--mc-color-red-5)'
      : severity === 2
      ? 'var(--mc-color-yellow-5)'
      : severity === 1
      ? 'var(--mc-color-light-9)'
      : 'var(--mc-color-light-7)'};

  /* background-color: ${({ theme, severity }) =>
    severity === 3 || severity === 4
      ? transparentize(0.9, theme.red1)
      : severity === 2
      ? transparentize(0.9, theme.yellow2)
      : severity === 1
      ? transparentize(0.9, theme.text1)
      : transparentize(0.9, theme.green1)}; */
`

export const StyledBalanceMaxMini = styled.button`
  /* height: 22px; */
  width: fit-content;
  background-color: ${({ theme }) => theme.bg1};
  border: none;
  border-radius: 8px;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 400;
  opacity: 0.6;
  margin-right: 0.5rem;
  cursor: pointer;
  color: ${({ theme }) => theme.text1};
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;

  :hover {
    background-color: ${({ theme }) => theme.bg2};
  }
  :focus {
    background-color: ${({ theme }) => theme.bg2};
    outline: none;
  }
`

export const TruncatedText = styled(Text)`
  text-overflow: ellipsis;
  max-width: 220px;
  overflow: hidden;
  text-align: right;
`

// styles
export const Dots = styled.div`
  display: inline-block;
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`
const ErrorWrapper = styled.div`
  color: var(--mc-color-light-7);
`

// const SwapCallbackErrorInner = styled.div`
//   /* background-color: ${({ theme }) => transparentize(0.9, theme.red1)}; */
//   background-color: var(--mc-color-red-1);
//   border-radius: 8px;
//   display: flex;
//   align-items: center;
//   font-size: 14px;
//   line-height: 20px;
//   width: 100%;
//   margin-top: 16px;
//   color: var(--mc-color-light-7);
//   p {
//     padding: 0;
//     margin: 0;
//     font-weight: 500;
//   }
// `

// const SwapCallbackErrorInnerAlertTriangle = styled.div`
//   background-color: ${({ theme }) => transparentize(0.9, theme.red1)};
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-right: 12px;
//   border-radius: 12px;
//   min-width: 48px;
//   height: 48px;
// `

export function SwapCallbackError({ error }: { error: ReactNode }) {
  return (
    <Well intent="danger" icon={FilledAlertTriangleIcon}>
      <ErrorWrapper>{error}</ErrorWrapper>
    </Well>
    // <SwapCallbackErrorInner>
    //   <SwapCallbackErrorInnerAlertTriangle>
    //     <AlertTriangle size={24} />
    //   </SwapCallbackErrorInnerAlertTriangle>
    //   <p style={{ wordBreak: 'break-word' }}>{error}</p>
    // </SwapCallbackErrorInner>
  )
}

export const SwapShowAcceptChanges = styled(AutoColumn)`
  /* background-color: ${({ theme }) => transparentize(0.9, theme.primary1)}; */
  background-color: var(--mc-color-blue-1);
  /* color: ${({ theme }) => theme.primary1}; */
  /* padding: 0.5rem; */
  padding: 12px 16px;
  border-radius: 8px;
  margin-top: 12px;
  margin-bottom: 16px;
`
export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.bg2};
`

export const V2TradeAlertWrapper = styled(Link)`
  background-color: ${({ theme }) => theme.bg2};
  display: flex;
  align-items: center;
  border-radius: 12px;
  height: 22px;
  margin-right: 0.5rem;
  padding: 0 0.25rem 0 0.5rem;
  text-decoration: none !important;
`
