// NOTE: disable this eslint rule for internal app's tracking user purpose (FOR INTERNAL APP URL ONLY)
/* eslint-disable react/jsx-no-target-blank */
import styled from 'styled-components'
import { ButtonNavLink } from './ButtonNavLink'
import { NavButton } from './NavButton'

const analyticsDomain = process.env.REACT_APP_KATANA_ANALYTICS_DOMAIN ?? ''

const NavBarContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  line-height: var(--mc-line-height-large);
`
const ButtonBackLink = styled.div`
  width: full;
  display: inline;
`
export const NavBar = () => {
  return (
    <NavBarContainer className="desktop-element">
      <ButtonNavLink label="Swap" id="swap-nav-link" to="/swap" />
      <ButtonNavLink
        label="Pool"
        id="pool-nav-link"
        to="/pool"
        isActive={(match, { pathname }) => {
          return (
            Boolean(match) ||
            pathname.startsWith('/add') ||
            pathname.startsWith('/remove') ||
            pathname.startsWith('/increase') ||
            pathname.startsWith('/find')
          )
        }}
      />
      <ButtonNavLink label="Farm" id="farm-nav-link" to="/farm" />

      <ButtonBackLink>
        <a href={analyticsDomain} target="_blank" rel="noopener" style={{ width: 'fit-content' }}>
          <NavButton>Analytics</NavButton>
        </a>
      </ButtonBackLink>
      <ButtonBackLink>
        <a
          href="https://axie-infinity.gitbook.io/katana-docs/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ width: 'fit-content' }}
        >
          <NavButton>Docs</NavButton>
        </a>
      </ButtonBackLink>
      {/* <ButtonNavLink label="Docs" id="docs-nav-link" to="/docs" /> */}
    </NavBarContainer>
  )
}
