import { Button } from '@axieinfinity/mochi'
import { ExternalLinkIcon } from '@axieinfinity/mochi-icons'
import errorPicImg from 'assets/images/error-pic.png'
import React, { ErrorInfo } from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components/macro'
import store, { AppState } from '../../state'
import { getUserAgent } from '../../utils/getUserAgent'

const FallbackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  z-index: 1;
`

const BodyWrapper = styled.div<{ margin?: string }>`
  padding: 1rem;
  width: 100%;
  /* white-space: ; */
`

const CodeBlockWrapper = styled.div`
  background: ${({ theme }) => theme.bg0};
  overflow: auto;
  white-space: pre;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 24px;
  padding: 18px 24px;
  color: ${({ theme }) => theme.text1};
`

const LinkWrapper = styled.div`
  color: ${({ theme }) => theme.blue1};
  padding: 6px 24px;
`

const SomethingWentWrongWrapper = styled.div`
  padding: 6px 24px;
`

const ServerError = styled.div`
  width: 100%;
  height: 100vh;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`
const ServerErrorHeading = styled.h1`
  font-size: 32px;
  line-height: 44px;
  font-weight: 700;
  margin-bottom: 16px;
`

const ServerErrorMessage = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 24px;
  max-width: 429px;
`
const SupportButton = styled.a`
  margin-top: 24px;
`

const ServerErrorDisplay = styled.img`
  max-width: 100vw;
  width: 520px;
`
const SupportTitle = styled.div`
  display: inline-block;
  text-decoration-line: underline;
`

type ErrorBoundaryState = {
  error: Error | null
}

export default class ErrorBoundary extends React.Component<unknown, ErrorBoundaryState> {
  constructor(props: unknown) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    ReactGA.exception({
      ...error,
      ...errorInfo,
      fatal: true,
    })
  }

  render() {
    const { error } = this.state
    if (error !== null) {
      const encodedBody = encodeURIComponent(issueBody(error))
      return (
        <ServerError>
          <div>
            <ServerErrorHeading>Sorry, something went wrong.</ServerErrorHeading>

            <ServerErrorMessage>
              The server encountered an internal error or misconfiguration and was unable to complete your request.
            </ServerErrorMessage>

            <SupportButton href="https://skymavis.zendesk.com" rel="noopener" target="_blank">
              <Button rightIcon={ExternalLinkIcon}>
                <SupportTitle>Get support</SupportTitle>
              </Button>
            </SupportButton>
          </div>

          <ServerErrorDisplay src={errorPicImg} />
        </ServerError>
      )
    }
    return this.props.children
  }
}

function getRelevantState(): null | keyof AppState {
  const path = window.location.hash
  if (!path.startsWith('#/')) {
    return null
  }
  const pieces = path.substring(2).split(/[\/\\?]/)
  switch (pieces[0]) {
    case 'swap':
      return 'swap'
    case 'add':
      if (pieces[1] === 'v2') return 'mint'
      else return 'mintV3'
    case 'remove':
      if (pieces[1] === 'v2') return 'burn'
      else return 'burnV3'
  }
  return null
}

function issueBody(error: Error): string {
  const relevantState = getRelevantState()
  const deviceData = getUserAgent()
  return `## URL
  
${window.location.href}

${
  relevantState
    ? `## \`${relevantState}\` state
    
\`\`\`json
${JSON.stringify(store.getState()[relevantState], null, 2)}
\`\`\`
`
    : ''
}
${
  error.name &&
  `## Error

\`\`\`
${error.name}${error.message && `: ${error.message}`}
\`\`\`
`
}
${
  error.stack &&
  `## Stacktrace

\`\`\`
${error.stack}
\`\`\`
`
}
${
  deviceData &&
  `## Device data

\`\`\`json
${JSON.stringify(deviceData, null, 2)}
\`\`\`
`
}
`
}
