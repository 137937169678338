import { BigNumber } from '@ethersproject/bignumber'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const ClaimCountdownWrapper = styled.div`
  color: #f6a300;
  font-weight: 700;
  text-align: right;
`

const ClaimTimer = styled.div`
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
`
interface INextClaimCountdownProps {
  nextClaimTimestamp: BigNumber
  onCountdownFinish?: () => void
}

export const formatClaimTime = (timeInSecs: number) => {
  const second = timeInSecs % 60
  const minute = Math.floor(timeInSecs / 60) % 60
  const hour = Math.floor(timeInSecs / 3600)

  const hDisplay = hour > 0 ? `${hour}h` : ''
  const mDisplay = minute > 0 ? `${minute}m` : ''
  const sDisplay = second > 0 ? `${second}s` : ''

  return `${hDisplay} ${mDisplay} ${sDisplay}`
}

export const NextClaimCountdown = (props: INextClaimCountdownProps) => {
  const { nextClaimTimestamp, onCountdownFinish } = props
  const nextClaimInSecs = nextClaimTimestamp.toNumber()
  const [countdown, setCountdown] = useState<number>(nextClaimInSecs)
  const timeoutId = useRef(0)

  const deceaseCountdown = () => {
    setCountdown((current) => {
      if (current === 1 && onCountdownFinish) {
        onCountdownFinish()
      }

      return current > 0 ? current - 1 : 0
    })
  }

  useEffect(() => {
    timeoutId.current = window.setInterval(deceaseCountdown, 1000)

    return () => window.clearInterval(timeoutId.current)
  }, [])
  useEffect(() => {
    setCountdown(nextClaimTimestamp.toNumber())
  }, [nextClaimTimestamp])
  return (
    <ClaimCountdownWrapper>
      Next claim in
      <br />
      <ClaimTimer>{formatClaimTime(countdown)}</ClaimTimer>
    </ClaimCountdownWrapper>
  )
}
