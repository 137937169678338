import { Button, Tag } from '@axieinfinity/mochi'
import { ArrowRightIcon, ChevronDownIcon, LockIcon } from '@axieinfinity/mochi-icons'
import { Currency, CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import { Pair } from '@uniswap/v2-sdk'
import { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { RowBetween, RowFixed } from 'components/Row'
import CurrencySearchModal from 'components/SearchModal/CurrencySearchModal'
import { useActiveWeb3React } from 'hooks/web3'
import { darken } from 'polished'
import { ReactNode, useCallback, useContext, useState } from 'react'
import { useCurrencyBalance } from 'state/wallet/hooks'
import styled from 'styled-components/macro'
import { TYPE } from 'theme'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
import useTheme from '../../hooks/useTheme'

import { ButtonGray } from '../Button'
import { Input as NumericalInput } from '../NumericalInput'
import { FiatValue } from './FiatValue'

const MaxCounterTag = styled(Tag)`
  margin-left: 8px;
  font-size: 10px;
  font-size: var(--mc-font-size-medium);
  border: 1px solid var(--mc-color-blue-5);
`

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '16px' : '12px')};
  background-color: ${({ theme, hideInput }) => (hideInput ? 'transparent' : theme.bg2)};
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
`

const FixedContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.bg1};
  opacity: 0.95;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`

const Container = styled.div<{ hideInput: boolean }>`
  border-radius: ${({ hideInput }) => (hideInput ? '16px' : '12px')};
  background-color: var(--mc-color-light-1);
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
`

const CurrencySelectWrapper = styled.div<{ hideInput?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  height: ${({ hideInput }) => (hideInput ? '44px' : '44px')};
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
  margin-right: ${({ hideInput }) => (hideInput ? '0' : '12px')};
`

const CurrencySelect = styled(ButtonGray)<{ selected: boolean; hideInput?: boolean; hasBackground?: boolean }>`
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  background-color: ${({ selected, theme, hasBackground }) => (selected && hasBackground ? theme.bg0 : 'transparent')};
  color: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
  border-radius: 12px;
  box-shadow: ${({ hasBackground }) => (hasBackground ? 'var(--mc-theme-shadow-3)' : '')};
  outline: none;
  cursor: ${({ hasBackground }) => (hasBackground ? 'pointer' : 'initial')};
  user-select: none;
  border: none;

  padding: ${({ selected }) => (selected ? '0 4px' : 0)};
  justify-content: space-between;
  :focus,
  :hover {
    background-color: ${({ hasBackground }) => (hasBackground ? '#fff' : 'transparent')};
  }
`

const InputRow = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  padding: ${({ selected }) => (selected ? ' 1rem 1rem 0.75rem 1rem' : '1rem 1rem 0.75rem 1rem')};
`

const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.text1};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0 1rem 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
`

const FiatRow = styled(LabelRow)`
  justify-content: flex-end;
`

const Aligner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const StyledTokenName = styled.div<{ active?: boolean }>`
  /* ${({ active }) => (active ? '  margin: 0 0.25rem 0 0.25rem;' : '  margin: 0 0.25rem 0 0.25rem;')} */
  margin: 0;
  font-size: ${({ active }) => (active ? '24px' : '24px')};
  font-weight: 600;
  display: inline-block;
`

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  color: var(--mc-color-light-9);
`
const TokenPairName = styled(StyledTokenName)`
  margin-right: 8px;
`

const TokenUnpairedName = styled(StyledTokenName)`
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
`

const UserDepositInput = styled(NumericalInput)`
  font-weight: 700;
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: ReactNode
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  fiatValue?: CurrencyAmount<Token> | null
  priceImpact?: Percent
  id: string
  errorInput?: string
  isDeposit?: boolean
  isReceiving?: boolean
  showCommonBases?: boolean
  renderBalance?: (amount: CurrencyAmount<Currency>) => ReactNode
  locked?: boolean
  isSwap?: boolean
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  onCurrencySelect,
  currency,
  otherCurrency,
  id,
  showCommonBases,
  renderBalance,
  fiatValue,
  priceImpact,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  locked = false,
  isDeposit = false,
  isReceiving = false,
  isSwap,
  ...rest
}: CurrencyInputPanelProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const { account } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const theme = useTheme()

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  return (
    <InputPanel id={id} hideInput={hideInput} {...rest}>
      {locked && (
        <FixedContainer>
          <AutoColumn gap="sm" justify="center">
            <LockIcon />
            <TYPE.label fontSize="12px" textAlign="center" padding="0 12px">
              {/* <Trans> */}The market price is outside your specified price range. Single-asset deposit only.
              {/* </Trans> */}
            </TYPE.label>
          </AutoColumn>
        </FixedContainer>
      )}
      <Container hideInput={hideInput}>
        <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={!onCurrencySelect}>
          <CurrencySelectWrapper hideInput={hideInput}>
            <CurrencySelect
              selected={!!currency}
              className="open-currency-select-button"
              hasBackground={!!onCurrencySelect}
              onClick={() => {
                if (onCurrencySelect) {
                  setModalOpen(true)
                }
              }}
            >
              <Aligner>
                <RowFixed>
                  {pair ? (
                    <span style={{ marginRight: '0.5rem' }}>
                      <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={32} margin={true} />
                    </span>
                  ) : currency ? (
                    <CurrencyLogo style={{ marginRight: '0.5rem' }} currency={currency} size={'32px'} />
                  ) : null}
                  {pair ? (
                    <TokenPairName className="pair-name-container">
                      {pair?.token0.symbol}/{pair?.token1.symbol}
                    </TokenPairName>
                  ) : (
                    <TokenUnpairedName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
                      {(currency && currency.symbol && currency.symbol.length > 20
                        ? currency.symbol.slice(0, 4) +
                          '...' +
                          currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                        : currency?.symbol) || (
                        <Button intent="primary" rightIcon={ArrowRightIcon}>
                          {/* <Trans> */}Select token{/* </Trans> */}
                        </Button>
                      )}
                    </TokenUnpairedName>
                  )}
                </RowFixed>
                {/* {onCurrencySelect && currency && <StyledDropDown selected={!!currency} />} */}
                {onCurrencySelect && currency && <StyledChevronDownIcon size={24} />}
              </Aligner>
            </CurrencySelect>
          </CurrencySelectWrapper>

          {!hideInput && (
            <>
              <UserDepositInput
                className="token-amount-input"
                value={value}
                style={{
                  color:
                    isDeposit &&
                    selectedCurrencyBalance &&
                    Number(formatCurrencyAmount(selectedCurrencyBalance, undefined, true)) < Number(value)
                      ? 'var(--mc-color-red-5)'
                      : isReceiving
                      ? 'var(--mc-color-green-4)'
                      : 'var(--mc-color-light-9)',
                }}
                error={
                  isDeposit &&
                  selectedCurrencyBalance &&
                  Number(selectedCurrencyBalance.toSignificant()) < Number(value)
                }
                onUserInput={(val) => {
                  // if (
                  //   !(
                  //     isDeposit &&
                  //     selectedCurrencyBalance &&
                  //     Number(selectedCurrencyBalance.toSignificant()) < Number(val) / 1000000
                  //   )
                  // ) {
                  onUserInput(val)
                  // }
                }}
              />
            </>
          )}
        </InputRow>
        {!hideInput && !hideBalance && (
          <FiatRow>
            <RowBetween>
              {account ? (
                <RowFixed style={{ height: '17px' }}>
                  <TYPE.body
                    onClick={onMax}
                    color={theme.text2}
                    fontWeight={400}
                    fontSize={14}
                    style={{ display: 'inline', cursor: 'pointer' }}
                  >
                    {!hideBalance && currency && selectedCurrencyBalance
                      ? renderBalance
                        ? renderBalance(selectedCurrencyBalance)
                        : `Balance: ${formatCurrencyAmount(selectedCurrencyBalance)} ${currency.symbol}`
                      : null}
                    {showMaxButton && selectedCurrencyBalance ? (
                      // <StyledBalanceMax onClick={onMax}>
                      <MaxCounterTag interactive onClick={onMax}>
                        {/* <Trans> */}MAX{/* </Trans> */}
                      </MaxCounterTag>
                    ) : // </StyledBalanceMax>
                    null}
                  </TYPE.body>
                </RowFixed>
              ) : (
                <span />
              )}
              <FiatValue fiatValue={fiatValue} priceImpact={priceImpact} />
            </RowBetween>
          </FiatRow>
        )}
      </Container>
      {onCurrencySelect && (
        <CurrencySearchModal
          srcId={id}
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
          isSwap={isSwap}
        />
      )}
    </InputPanel>
  )
}
