import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
import { CurrencyAmount, NativeCurrency, Token } from '@uniswap/sdk-core'
import { ExtendedEther, tokenAddressByChain } from 'constants/tokens'
import { useActiveWeb3React } from 'hooks/web3'
import isNil from 'lodash/isNil'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useIsTransactionPending } from 'state/transactions/hooks'
import { useTokenBalance } from 'state/wallet/hooks'
import { useBlockNumber } from '../../state/application/hooks'

export interface IUseRoninStakingInfoResult {
  totalStaked?: CurrencyAmount<Token>
  walletBalance?: CurrencyAmount<Token>
  userTotalStaked?: CurrencyAmount<Token>
  pendingReward?: CurrencyAmount<Token>
  dailyReward?: CurrencyAmount<NativeCurrency>
  isClaimableNow?: boolean
  nextClaimTimestamp?: BigNumber
  addPendingTx: (txHash: string) => void
}

export type IUseRoninStakingInfo = (
  stakingTokenInfo?: Token,
  stakingPoolContract?: Contract,
  stakingManagerContract?: Contract
) => IUseRoninStakingInfoResult

export const useRoninStakingInfo: IUseRoninStakingInfo = (
  stakingTokenInfo,
  stakingPoolContract,
  stakingManagerContract
) => {
  const currentBlockNumber = useBlockNumber()
  const { account: selectedAccount, chainId, library } = useActiveWeb3React()
  const wronTokenInfo = tokenAddressByChain(chainId)?.WRON
  const nativeRON = ExtendedEther.onChain(chainId)

  const walletBalance = useTokenBalance(selectedAccount ?? undefined, stakingTokenInfo)
  const [totalStaked, setTotalStaked] = useState<CurrencyAmount<Token> | undefined>()
  const [userTotalStaked, setUserTotalStaked] = useState<CurrencyAmount<Token> | undefined>()
  const [pendingReward, setPendingReward] = useState<CurrencyAmount<Token> | undefined>()
  const [dailyReward, setDailyReward] = useState<CurrencyAmount<NativeCurrency> | undefined>()
  const [refetchFlag, setRefecthFlag] = useState<number>(0)
  const [isClaimableNow, setIsClaimableNow] = useState<boolean>(false)
  const [nextClaimTimestamp, setNextClaimTimestamp] = useState<BigNumber | undefined>()

  const fetchTotalStaked = useCallback(async () => {
    if (!isNil(stakingPoolContract) && !isNil(stakingTokenInfo)) {
      try {
        const totalStaked = await stakingPoolContract.getStakingTotal()
        const tokenAmount = CurrencyAmount.fromRawAmount(stakingTokenInfo, totalStaked)

        setTotalStaked(tokenAmount)
      } catch (error) {
        setTotalStaked(undefined)
        console.error('fetchTotalStaked', error)
      }
    }
  }, [stakingPoolContract])

  const fetchUserTotalStaked = useCallback(async () => {
    if (isNil(stakingTokenInfo) || isNil(stakingPoolContract) || isNil(selectedAccount)) {
      setUserTotalStaked(undefined)
      return
    }

    try {
      const userTotalStaked = await stakingPoolContract.getStakingAmount(selectedAccount)
      const tokenAmount = CurrencyAmount.fromRawAmount(stakingTokenInfo, userTotalStaked)

      setUserTotalStaked(tokenAmount)
    } catch (error) {
      setUserTotalStaked(undefined)
      console.error('fetchUserTotalStaked', error)
    }
  }, [selectedAccount, stakingPoolContract])

  const fetchPendingReward = useCallback(async () => {
    if (isNil(wronTokenInfo) || isNil(stakingPoolContract) || isNil(selectedAccount)) {
      setUserTotalStaked(undefined)
      return
    }

    try {
      const userReward = await stakingPoolContract.getPendingRewards(selectedAccount)
      const tokenAmount = CurrencyAmount.fromRawAmount(wronTokenInfo, userReward)

      setPendingReward(tokenAmount)
    } catch (error) {
      setPendingReward(undefined)
      console.error('fetchPendingReward', error)
    }
  }, [selectedAccount, stakingPoolContract])

  const fetchDailyReward = useCallback(async () => {
    if (isNil(currentBlockNumber) || isNil(stakingManagerContract) || isNil(stakingPoolContract)) {
      return
    }

    // const axsEthDailyReward = CurrencyAmount.fromRawAmount(nativeRON, '555556000000000000000000')
    // const ronEthDailyReward = CurrencyAmount.fromRawAmount(nativeRON, '277778000000000000000000')

    try {
      const blockReward = await stakingManagerContract.getBlockReward(
        stakingPoolContract.address,
        BigNumber.from(currentBlockNumber)
      )

      if (!isNil(blockReward)) {
        const dailyRewardNumber = (blockReward as BigNumber).mul(28800)
        const dailyReward = CurrencyAmount.fromRawAmount(nativeRON, dailyRewardNumber.toString())

        setDailyReward(dailyReward)
      }
    } catch (err) {
      setDailyReward(undefined)
      console.error(err)
    }
  }, [currentBlockNumber, stakingPoolContract, stakingManagerContract])

  const fetchClaimRewardInfo = useCallback(async () => {
    if (isNil(library) || isNil(selectedAccount) || isNil(stakingManagerContract) || isNil(stakingPoolContract)) {
      return
    }
    try {
      const [userRewardInfo, minClaimedTimeWindow, timeStamp] = await Promise.all([
        await stakingManagerContract?.userRewardInfo(stakingPoolContract?.address, selectedAccount),
        await stakingManagerContract?.minClaimedTimeWindow(),
        BigNumber.from((await library.getBlock('latest'))?.timestamp),
      ])
      const [, , userLastClaimedTime] = userRewardInfo
      if (isNil(userLastClaimedTime) || isNil(minClaimedTimeWindow)) {
        return
      }

      const isClaimableNow = userLastClaimedTime.add(minClaimedTimeWindow).lt(timeStamp ?? 0)

      const nextClaimTimestamp = userLastClaimedTime
        .add(minClaimedTimeWindow ?? 0)
        .sub(timeStamp ?? 0)
        .abs()

      setIsClaimableNow(isClaimableNow)
      setNextClaimTimestamp(nextClaimTimestamp)
    } catch (err) {
      setNextClaimTimestamp(undefined)
      console.error('fetchClaimRewardInfo', err)
    }
  }, [library, selectedAccount, stakingManagerContract, stakingPoolContract])

  useEffect(() => {
    fetchDailyReward()
  }, [currentBlockNumber])

  useEffect(() => {
    fetchTotalStaked()
  }, [refetchFlag])

  useEffect(() => {
    fetchUserTotalStaked()
    fetchPendingReward()
    fetchClaimRewardInfo()
  }, [selectedAccount, refetchFlag])

  // Listen to pending tx to refresh data
  const pendingTxsRef = useRef<string[]>([])
  const firstPendingTx = pendingTxsRef.current[0]
  const isPendingFirstTx = useIsTransactionPending(firstPendingTx)

  useEffect(() => {
    if (!isNil(firstPendingTx) && !isPendingFirstTx) {
      setRefecthFlag((current) => current + 1)

      const currentList = pendingTxsRef.current
      const newList = currentList.slice(1)

      pendingTxsRef.current = newList
    }
  }, [isPendingFirstTx, firstPendingTx])

  const addPendingTx = useCallback((txHash: string) => {
    const currentList = pendingTxsRef.current
    const newList = [...currentList, txHash]

    pendingTxsRef.current = newList
  }, [])
  return {
    totalStaked,
    walletBalance,
    userTotalStaked,
    pendingReward,
    dailyReward,
    addPendingTx,
    isClaimableNow,
    nextClaimTimestamp,
  }
}
