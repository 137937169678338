import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import { Trade as V2Trade } from '@uniswap/v2-sdk'
import { Trade as V3Trade } from '@uniswap/v3-sdk'
import { useContext, useMemo } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { TYPE } from '../../theme'
import { computeRealizedLPFeePercent } from '../../utils/prices'
import { AutoColumn } from '../Column'
import { RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import SwapRoute from './SwapRoute'

const Container = styled(AutoColumn)<{ isTooltip: boolean }>`
  background-color: ${({ isTooltip }) => (isTooltip ? 'var(--mc-color-white)' : 'var(--mc-color-light-1)')};
`
const Header = styled(TYPE.black)`
  text-transform: uppercase;
`
export interface AdvancedSwapDetailsProps {
  trade?: V2Trade<Currency, Currency, TradeType> | V3Trade<Currency, Currency, TradeType>
  allowedSlippage: Percent
  isTooltip?: boolean
}

export function AdvancedSwapDetails({ trade, allowedSlippage, isTooltip = false }: AdvancedSwapDetailsProps) {
  const theme = useContext(ThemeContext)

  const { realizedLPFee, priceImpact } = useMemo(() => {
    if (!trade) return { realizedLPFee: undefined, priceImpact: undefined }

    const realizedLpFeePercent = computeRealizedLPFeePercent(trade)
    const realizedLPFee = trade.inputAmount.multiply(realizedLpFeePercent)
    const priceImpact = trade.priceImpact.subtract(realizedLpFeePercent)
    return { priceImpact, realizedLPFee }
  }, [trade])

  return !trade ? null : (
    <Container isTooltip={isTooltip} gap="8px">
      <RowBetween>
        <RowFixed>
          <Header fontSize={10} fontWeight={700} color={'var(--mc-color-light-7)'}>
            {/* <Trans> */}Liquidity Provider Fee{/* </Trans> */}
          </Header>
        </RowFixed>
        <TYPE.black textAlign="right" fontSize={14} color={'var(--mc-color-light-9)'} fontWeight={600}>
          {realizedLPFee ? `${realizedLPFee.toSignificant(4)} ${realizedLPFee.currency.symbol}` : '-'}
        </TYPE.black>
      </RowBetween>

      <RowBetween>
        <RowFixed>
          <Header fontSize={10} fontWeight={700} color={'var(--mc-color-light-7)'}>
            {/* <Trans> */}Route{/* </Trans> */}
          </Header>
        </RowFixed>
        <TYPE.black textAlign="right" fontSize={14} color={'var(--mc-color-light-9)'} fontWeight={600}>
          <SwapRoute trade={trade} />
        </TYPE.black>
      </RowBetween>

      <RowBetween>
        <RowFixed>
          <Header fontSize={10} fontWeight={700} color={'var(--mc-color-light-7)'}>
            {/* <Trans> */}Price Impact{/* </Trans> */}
          </Header>
        </RowFixed>
        <TYPE.black textAlign="right" fontSize={14} color={'var(--mc-color-light-9)'} fontWeight={600}>
          <FormattedPriceImpact priceImpact={priceImpact} />
        </TYPE.black>
      </RowBetween>

      <RowBetween>
        <RowFixed>
          <Header fontSize={10} fontWeight={700} color={'var(--mc-color-light-7)'}>
            {trade.tradeType === TradeType.EXACT_INPUT ? 'Minimum received' : 'Maximum sent'}
          </Header>
        </RowFixed>
        <TYPE.black textAlign="right" fontSize={14} color={'var(--mc-color-light-9)'} fontWeight={600}>
          {trade.tradeType === TradeType.EXACT_INPUT
            ? `${trade.minimumAmountOut(allowedSlippage).toSignificant(6)} ${trade.outputAmount.currency.symbol}`
            : `${trade.maximumAmountIn(allowedSlippage).toSignificant(6)} ${trade.inputAmount.currency.symbol}`}
        </TYPE.black>
      </RowBetween>

      <RowBetween>
        <RowFixed>
          <Header fontSize={10} fontWeight={700} color={'var(--mc-color-light-7)'}>
            {/* <Trans> */}Slippage tolerance{/* </Trans> */}
          </Header>
        </RowFixed>
        <TYPE.black textAlign="right" fontSize={14} color={'var(--mc-color-light-9)'} fontWeight={600}>
          {allowedSlippage.toFixed(2)}%
        </TYPE.black>
      </RowBetween>
    </Container>
  )
}
