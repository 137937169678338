import { FarmCard } from 'components/FarmCard/FarmCard'
import {
  AXSETH_LP_STAKING_POOL_ADDRESS,
  RONETH_LP_STAKING_POOL_ADDRESS,
  SLPETH_LP_STAKING_POOL_ADDRESS,
  RONAXS_LP_STAKING_POOL_ADDRESS,
  RONSLP_LP_STAKING_POOL_ADDRESS,
  RONUSDC_LP_STAKING_POOL_ADDRESS,
  STAKING_MANAGER_ADDRESS,
} from 'constants/addresses'
import { ExtendedEther, tokenAddressByChain } from 'constants/tokens'
import { useRoninStakingManagerContract, useRoninStakingPoolContract } from 'hooks/useContract'
import { useActiveWeb3React } from 'hooks/web3'
import styled from 'styled-components/macro'
interface IFarmPoolListProps {
  className?: string
}

const FarmCardRow = styled.div`
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
`

const FarmCardRowWithGap = styled(FarmCardRow)`
  gap: 16px;
`

export const FarmPoolList = (props: IFarmPoolListProps) => {
  const { chainId } = useActiveWeb3React()

  const tokenAddresses = tokenAddressByChain(chainId)
  const nativeRON = ExtendedEther.onChain(chainId)
  const axsTokenInfo = tokenAddresses?.AXS
  const slpTokenInfo = tokenAddresses?.SLP
  const wethTokenInfo = tokenAddresses?.WETH
  const usdcTokenInfo = tokenAddresses?.USDC
  const axsEthLPPairInfo = tokenAddresses?.AXSETH_LP
  const slpEthLPPairInfo = tokenAddresses?.SLPETH_LP
  const ronEthLPPairInfo = tokenAddresses?.RONETH_LP
  const ronAxsLPPairInfo = tokenAddresses?.AXSRON_LP
  const ronSlpLPPairInfo = tokenAddresses?.RONSLP_LP
  const ronUsdcLPPairInfo = tokenAddresses?.RONUSDC_LP

  const axsEthLPStakingPoolAddress = AXSETH_LP_STAKING_POOL_ADDRESS[chainId]
  const slpEthLPStakingPoolAddress = SLPETH_LP_STAKING_POOL_ADDRESS[chainId]
  const ronEthLPStakingPoolAddress = RONETH_LP_STAKING_POOL_ADDRESS[chainId]
  const ronAxsLPStakingPoolAddress = RONAXS_LP_STAKING_POOL_ADDRESS[chainId]
  const ronSlpLPStakingPoolAddress = RONSLP_LP_STAKING_POOL_ADDRESS[chainId]
  const ronUsdcLPStakingPoolAddress = RONUSDC_LP_STAKING_POOL_ADDRESS[chainId]
  const stakingManagerAddress = STAKING_MANAGER_ADDRESS[chainId]

  const axsEthLpPoolContract = useRoninStakingPoolContract(axsEthLPStakingPoolAddress, true) ?? undefined
  const slpEthLpPoolContract = useRoninStakingPoolContract(slpEthLPStakingPoolAddress, true) ?? undefined
  const ronEthLpPoolContract = useRoninStakingPoolContract(ronEthLPStakingPoolAddress, true) ?? undefined
  const ronAxsLpPoolContract = useRoninStakingPoolContract(ronAxsLPStakingPoolAddress, true) ?? undefined
  const ronSlpLpPoolContract = useRoninStakingPoolContract(ronSlpLPStakingPoolAddress, true) ?? undefined
  const ronUsdcLpPoolContract = useRoninStakingPoolContract(ronUsdcLPStakingPoolAddress, true) ?? undefined
  const stakingManagerContract = useRoninStakingManagerContract(stakingManagerAddress) ?? undefined

  return (
    <>
      <FarmCardRowWithGap>
        <FarmCard
          firstTokenInfo={nativeRON}
          secondTokenInfo={axsTokenInfo}
          lpTokenInfo={ronAxsLPPairInfo}
          stakingPoolContract={ronAxsLpPoolContract}
        />
        <FarmCard
          firstTokenInfo={nativeRON}
          secondTokenInfo={usdcTokenInfo}
          lpTokenInfo={ronUsdcLPPairInfo}
          stakingPoolContract={ronUsdcLpPoolContract}
        />
        <FarmCard
          firstTokenInfo={slpTokenInfo}
          secondTokenInfo={wethTokenInfo}
          lpTokenInfo={slpEthLPPairInfo}
          stakingPoolContract={slpEthLpPoolContract}
        />
      </FarmCardRowWithGap>
      <FarmCardRowWithGap>
        <FarmCard
          firstTokenInfo={nativeRON}
          secondTokenInfo={slpTokenInfo}
          lpTokenInfo={ronSlpLPPairInfo}
          stakingPoolContract={ronSlpLpPoolContract}
        />
        <FarmCard
          firstTokenInfo={nativeRON}
          secondTokenInfo={wethTokenInfo}
          lpTokenInfo={ronEthLPPairInfo}
          stakingPoolContract={ronEthLpPoolContract}
        />
        <FarmCard
          firstTokenInfo={axsTokenInfo}
          secondTokenInfo={wethTokenInfo}
          lpTokenInfo={axsEthLPPairInfo}
          stakingPoolContract={axsEthLpPoolContract}
        />
      </FarmCardRowWithGap>
    </>
  )
}
