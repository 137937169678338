// NOTE: disable this eslint rule for internal app's tracking user purpose (FOR INTERNAL APP URL ONLY)
/* eslint-disable react/jsx-no-target-blank */
import { Well } from '@axieinfinity/mochi'
import { FilledInfoIcon } from '@axieinfinity/mochi-icons'
import styled from 'styled-components'

const ThirdPartyTokenBanner = styled(Well)`
  margin-top: 12px
  width: 100% !important;
  max-width: 480px;
`

export const ThirdPartyWarning: React.FC = () => {
  return (
    <ThirdPartyTokenBanner title="Disclaimer" icon={FilledInfoIcon}>
      This is a token linked to{' '}
      <a href="https://roninchain.com/ecosystem" target="_blank" rel="noopener">
        Pixels
      </a>
      . New token pairs can be volatile
      <br />
      Always do your own research before trading.{' '}
      <a href="https://docs.pixels.xyz/economics/tokens/usdberry" target="_blank" rel="noopener noreferrer">
        Read more
      </a>
    </ThirdPartyTokenBanner>
  )
}
