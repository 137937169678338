import { Button } from '@axieinfinity/mochi'
import { CaretDownIcon, CaretUpIcon, ExternalLinkIcon, FilledShieldIcon } from '@axieinfinity/mochi-icons'
import { SvgIcon } from '@axieinfinity/mochi-icons/dist/SvgIcon'
import { Contract } from '@ethersproject/contracts'
import { Currency, Token, CurrencyAmount, Price, NativeCurrency } from '@uniswap/sdk-core'
import { Pair } from '@uniswap/v2-sdk'
import CurrencyLogo from 'components/CurrencyLogo'
import { useRoninStakingInfo } from 'components/FarmCard/useRoninStakingInfo'
import { FarmClaimDialog } from 'components/FarmClaimDialog/FarmClaimDialog'
import { FarmStakeDialog, StakeDialogAction } from 'components/FarmStakeDialog/FarmStakeDialog'
import Loader from 'components/Loader'
import { STAKING_MANAGER_ADDRESS } from 'constants/addresses'
import { AXSETH_LP, ExtendedEther, SLPETH_LP, tokenAddressByChain } from 'constants/tokens'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { useRoninStakingManagerContract } from 'hooks/useContract'
import useToggle from 'hooks/useToggle'
import { useTotalSupply } from 'hooks/useTotalSupply'
import useUSDCPrice from 'hooks/useUSDCPrice'
import { useV2Pairs } from 'hooks/useV2Pairs'
import { useActiveWeb3React } from 'hooks/web3'
import isNil from 'lodash/isNil'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useWalletModalToggle } from 'state/application/hooks'
import styled from 'styled-components/macro'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
import { NextClaimCountdown } from './NextClaimCountdown'
import { Section } from './Section'
import { Token as TokenView } from './Token'
import { TokenAvatar } from './TokenAvatar'
import { TokenWithFiat } from './TokenWithFiat'

const analyticsDomain = process.env.REACT_APP_KATANA_ANALYTICS_DOMAIN ?? ''

const MaxWidthCard = styled.div`
  max-width: 470px;
  border-width: 1px;
  box-shadow: 0 0 #0000, 0 0 #0000, var(--mc-theme-shadow-2);
  border-color: var(--mc-color-light-3);
  border-radius: var(--mc-space-inset-12x);
  background-color: var(--mc-color-white);
  &:hover {
    border-color: var(--mc-color-blue-5);
  }
`

const RoninConnectButton = styled(Button)`
  background-color: var(--mc-color-blue-1) !important;
  color: var(--mc-color-blue-5) !important;
  margin-top: 20px;
  width: 100%;
`

const StakeButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
`

const UnstakeButton = styled(Button)`
  width: 100%;
  margin-top: 8px;
`

const ExternalLinkIconWrapper = styled.div`
  display: inline-flex;
  line-height: 20px;
  color: var(--mc-color-light-7);
  gap: 4px;
`

const FilledShieldIconWrapper = styled.div`
  color: var(--mc-color-blue-5);
  width: 24px;
  height: 24px;
  margin-right: 12px;
`

const ToggleCollapse = styled.div`
  padding: 24px;
  padding-bottom: 20px;
  cursor: pointer;
`

const FarmCardDropdown = styled.div`
  display: flex;
  justify-content: space-between;
`

const FarmCardHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const PairPoolName = styled.h5`
  margin-left: 4px;
  font-weight: 600;
`

const CollapseIconWrapper = styled.div`
  color: var(--mc-color-blue-5);
`

const LockValueSection = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
`
const LockValueHeading = styled.div`
  line-height: 20px;
`

const DailyReward = styled.div`
  line-height: 20px;
  display: flex;
  align-items: center;
`
const RewardCurrencyLogo = styled(CurrencyLogo)`
  margin-top: 1px;
  margin-right: 4px;
`

const ARPHeading = styled.div`
  line-height: 20px;
`

const RewardSplitter = styled.div`
  border-top: 1px solid var(--mc-color-light-3);
  margin-bottom: 20px;
`

const RewardDetailSection = styled.div`
  padding-left: 24px;
  padding-right: 24px;
`

const RewardDetailWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 12px;
  gap: 12px;
`

const RewardDetail = styled(Section)`
  flex: 1 1 0%;
`

const RewardItem = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
`

const ClaimSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 20px;
  background-color: var(--mc-color-blue-1);
  border-radius: 4px;
`

const TokenViewWrapper = styled.div`
  margin-top: 4px;
`

const ClaimTokenView = styled(TokenView)`
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: var(--mc-color-blue-5);
`

const GetMoreLPSection = styled.div`
  display: flex;
  gap: 12px;
  padding: 16px;
  background-color: var(--mc-color-blue-1);
  border-radius: 4px;
  margin-top: 20px;
`

const GetMoreLPIcon = styled(SvgIcon)`
  color: var(--mc-color-blue-5);
`

const GetTokenHeading = styled.div`
  margin-bottom: 4px;
  font-weight: 600;
  line-height: 20px;
`
const ApproveSection = styled.div`
  border: 1px solid var(--mc-color-blue-5);
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: var(--mc-color-blue-1);
  border-radius: 4px;
  margin-top: 12px;
`

const ApproveMessage = styled.div`
  overflow-wrap: break-word;
  margin-right: 12px;
`

const ButtonApproveWrapper = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
`

const PairInfoSection = styled.div`
  background-color: var(--mc-color-light-1);
  margin-top: 20px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  font-size: var(--mc-font-size-small);
  @media (min-width: 480px) {
    font-size: var(--mc-font-size-normal);
  }
`

const PairInfoCard = styled.a`
  margin-left: 10px;
  @media (min-width: 480px) {
    margin-left: 32px;
  }
`

const PairInfoDetail = styled.div`
  display: inline-flex;
  line-height: 20px;
  color: var(--mc-color-light-7);
`

const InfoIcon = styled(SvgIcon)`
  margin-left: 4px;
`
const ToggleContent = styled.div<{ shouldHidden?: boolean }>`
  display: ${(props) => (props.shouldHidden ? 'none' : 'block')};
`

interface IFarmCardProps {
  firstTokenInfo?: Currency
  secondTokenInfo?: Currency
  lpTokenInfo?: Token

  stakingPoolContract?: Contract

  className?: string
}

interface IGetFiatValueParams {
  pairInfo: Pair | null
  calcAmount?: CurrencyAmount<Token>
  cAPrice?: Price<Currency, Token>
  cBPrice?: Price<Currency, Token>
  lpTotalSupply?: CurrencyAmount<Token>
}

const calcFiatByTotalSupply = (params: IGetFiatValueParams) => {
  const { pairInfo, calcAmount, cAPrice, cBPrice, lpTotalSupply } = params

  if (pairInfo && calcAmount && cAPrice && cBPrice && lpTotalSupply) {
    const wrapTokenA = cAPrice?.baseCurrency.wrapped
    const wrapTokenB = cBPrice?.baseCurrency.wrapped

    const [price0, price1] = wrapTokenA.sortsBefore(wrapTokenB) ? [cAPrice, cBPrice] : [cBPrice, cAPrice]
    const { reserve0, reserve1 } = pairInfo

    const fiat0 = Number(reserve0.toExact()) * Number(price0.toFixed())
    const fiat1 = Number(reserve1.toExact()) * Number(price1.toFixed())
    const totalFiat = fiat0 + fiat1
    const percentage = Number(calcAmount.toExact()) / Number(lpTotalSupply.toExact())

    const fiatAmount = percentage * totalFiat

    return fiatAmount
  }

  return undefined
}

const calcAPR = (
  totalLockFiat?: number,
  RONPrice?: Price<Currency, Token>,
  dailyReward?: CurrencyAmount<NativeCurrency>
) => {
  if (RONPrice && dailyReward && totalLockFiat !== undefined) {
    const oneYearReward = Number(dailyReward.toExact()) * Number(RONPrice.toFixed()) * 365

    const apr = (oneYearReward / (totalLockFiat ?? 1)) * 100

    return apr
  }

  return undefined
}

export const FarmCard = (props: IFarmCardProps) => {
  const { firstTokenInfo, secondTokenInfo, lpTokenInfo, stakingPoolContract, className } = props

  const { account: selectedAccount, chainId } = useActiveWeb3React()

  const nativeRON = ExtendedEther.onChain(chainId)
  const stakingManagerAddress = STAKING_MANAGER_ADDRESS[chainId]
  const stakingManagerContract = useRoninStakingManagerContract(stakingManagerAddress) ?? undefined

  const [[, pairInfo]] = useV2Pairs([[firstTokenInfo, secondTokenInfo]])
  const totalSupply = useTotalSupply(lpTokenInfo)

  const toggleWalletModal = useWalletModalToggle()
  const ronPrice = useUSDCPrice(tokenAddressByChain(chainId)?.WRON)

  const {
    totalStaked,
    walletBalance,
    userTotalStaked,
    pendingReward,
    dailyReward,
    addPendingTx,
    nextClaimTimestamp,
    isClaimableNow,
  } = useRoninStakingInfo(lpTokenInfo, stakingPoolContract, stakingManagerContract)
  const token1Price = useUSDCPrice(firstTokenInfo)
  const token2Price = useUSDCPrice(secondTokenInfo)

  const [collapse, toggleCollapse] = useToggle(true)
  const [stakeDialogOpen, toggleStakeDialog] = useToggle(false)
  const [stakeDialogAction, setStakeDialogAction] = useState<StakeDialogAction>(StakeDialogAction.Stake)
  const [claimDialogOpen, toggleClaimDialog] = useToggle(false)

  const [approvalState, approveWalletBalance] = useApproveCallback(walletBalance, stakingPoolContract?.address)

  const handleClickStake = () => {
    setStakeDialogAction(StakeDialogAction.Stake)
    toggleStakeDialog()
  }

  const handleClickUnstake = () => {
    setStakeDialogAction(StakeDialogAction.Unstake)
    toggleStakeDialog()
  }

  const lpTokenName = `${firstTokenInfo?.symbol ?? `UNKN`}-${secondTokenInfo?.symbol ?? `UNKN`} LP`
  const pairPoolName = `${firstTokenInfo?.symbol ?? `UNKN`}/${secondTokenInfo?.symbol ?? `UNKN`}`

  const isZeroWalletBalance = !isNil(walletBalance) && walletBalance.equalTo(0)
  const notZeroWalletBalance = !isNil(walletBalance) && !walletBalance.equalTo(0)
  const isZeroStakeBalance = !isNil(userTotalStaked) && userTotalStaked.equalTo(0)
  const notZeroStakeBalance = !isNil(userTotalStaked) && !userTotalStaked.equalTo(0)

  const showConnect = isNil(selectedAccount)
  const showGetMoreLPTokens = !showConnect && isZeroWalletBalance && isZeroStakeBalance
  const zeroDailyRewards = dailyReward?.equalTo(0) ?? true

  const showApprove =
    !showConnect &&
    approvalState !== ApprovalState.APPROVED &&
    approvalState !== ApprovalState.UNKNOWN &&
    notZeroWalletBalance

  const showStakeSection =
    !showConnect && approvalState === ApprovalState.APPROVED && (notZeroWalletBalance || notZeroStakeBalance)

  const showClaimSection = !showConnect && !isNil(pendingReward) && pendingReward.greaterThan(0)

  const lpUserStakeFiat = calcFiatByTotalSupply({
    pairInfo,
    calcAmount: userTotalStaked,
    cAPrice: token1Price,
    cBPrice: token2Price,
    lpTotalSupply: totalSupply,
  })
  const lpWalletFiat = calcFiatByTotalSupply({
    pairInfo,
    calcAmount: walletBalance,
    cAPrice: token1Price,
    cBPrice: token2Price,
    lpTotalSupply: totalSupply,
  })
  const lpTotalStakedFiat = calcFiatByTotalSupply({
    pairInfo,
    calcAmount: totalStaked,
    cAPrice: token1Price,
    cBPrice: token2Price,
    lpTotalSupply: totalSupply,
  })
  const poolAPR = calcAPR(lpTotalStakedFiat, ronPrice, dailyReward)

  const displayUserLockFiat = lpUserStakeFiat
    ? '~$' + lpUserStakeFiat.toLocaleString('en-US', { maximumFractionDigits: 4 })
    : '--'
  const displayWalletFiat = lpWalletFiat
    ? '~$' + lpWalletFiat.toLocaleString('en-US', { maximumFractionDigits: 4 })
    : '--'
  const displayTotalLockFiat = lpTotalStakedFiat
    ? '~$' + lpTotalStakedFiat.toLocaleString('en-US', { maximumFractionDigits: 0 })
    : '--'
  const displayAPR = `${
    poolAPR
      ? poolAPR.toLocaleString('en-US', {
          maximumFractionDigits: 0,
        })
      : '--'
  }%`

  return (
    <MaxWidthCard className={className}>
      <ToggleCollapse onClick={toggleCollapse}>
        <FarmCardDropdown>
          <FarmCardHeadingWrapper>
            <TokenAvatar>
              <CurrencyLogo currency={firstTokenInfo} size="24px" />
            </TokenAvatar>
            <TokenAvatar>
              <CurrencyLogo currency={secondTokenInfo} size="24px" />
            </TokenAvatar>
            <PairPoolName>{pairPoolName}</PairPoolName>
          </FarmCardHeadingWrapper>

          <CollapseIconWrapper>
            {collapse && <CaretDownIcon size={32} />}
            {!collapse && <CaretUpIcon size={32} />}
          </CollapseIconWrapper>
        </FarmCardDropdown>

        <LockValueSection>
          <Section style={{ flex: 0.4 }} title="Total Value Staked">
            <LockValueHeading>{displayTotalLockFiat}</LockValueHeading>
          </Section>
          <Section style={{ flex: 0.5 }} title="Daily reward">
            <DailyReward>
              <RewardCurrencyLogo currency={nativeRON} size="16px" />
              {((firstTokenInfo?.symbol?.includes('RON') && secondTokenInfo?.symbol === 'WETH') ||
                (firstTokenInfo?.symbol === 'WETH' && secondTokenInfo?.symbol?.includes('RON'))) &&
              zeroDailyRewards
                ? (500000).toLocaleString('en-US')
                : formatCurrencyAmount(dailyReward)}{' '}
              RON / day
            </DailyReward>
          </Section>
          <Section style={{ flex: 0.1 }} title="APR">
            <ARPHeading>{displayAPR}</ARPHeading>
          </Section>
        </LockValueSection>
      </ToggleCollapse>

      <ToggleContent shouldHidden={collapse}>
        <RewardSplitter />
        <RewardDetailSection>
          <RewardDetailWrapper>
            <RewardDetail title="Available in wallet">
              <RewardItem>
                <TokenWithFiat
                  fiatValue={displayWalletFiat}
                  value={formatCurrencyAmount(walletBalance).toString()}
                  symbol={lpTokenName}
                />
              </RewardItem>
            </RewardDetail>
            <RewardDetail title="Total Staked">
              <RewardItem>
                <TokenWithFiat
                  fiatValue={displayUserLockFiat}
                  value={formatCurrencyAmount(userTotalStaked).toString()}
                  symbol={lpTokenName}
                />
              </RewardItem>
            </RewardDetail>
          </RewardDetailWrapper>

          {showConnect && (
            <RoninConnectButton onClick={toggleWalletModal} minimal>
              Connect Ronin Wallet
            </RoninConnectButton>
          )}

          {showClaimSection && (
            <ClaimSectionWrapper>
              <Section title={isClaimableNow ? 'Claimable Rewards' : 'Pending Rewards'}>
                <TokenViewWrapper>
                  <ClaimTokenView
                    value={formatCurrencyAmount(pendingReward).toString()}
                    symbol="RON"
                    symbolStyle={{ fontSize: 14 }}
                  />
                </TokenViewWrapper>
              </Section>
              {isClaimableNow && (
                <Button size="small" intent="primary" onClick={toggleClaimDialog}>
                  Claim
                </Button>
              )}
              {!isClaimableNow && nextClaimTimestamp && <NextClaimCountdown nextClaimTimestamp={nextClaimTimestamp} />}
            </ClaimSectionWrapper>
          )}

          {showGetMoreLPTokens && (
            <GetMoreLPSection>
              <GetMoreLPIcon size={40}>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.5 4c-2.268 0-4.228 1.432-5.083 3.399A1 1 0 017.583 6.6C8.728 3.968 11.368 2 14.5 2 18.652 2 22 5.348 22 9.5c0 3.113-1.851 5.771-4.615 6.923a1 1 0 01-.77-1.846A5.447 5.447 0 0020 9.5C20 6.452 17.548 4 14.5 4z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 8a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1116 0 8 8 0 01-16 0z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.293 11.313a1 1 0 011.414 0l1.98 1.98a1 1 0 010 1.414l-1.98 1.98a1 1 0 01-1.414 0l-1.98-1.98a1 1 0 010-1.414l1.98-1.98zM10 13.434L9.434 14l.566.566.566-.566-.566-.566z"
                />
              </GetMoreLPIcon>

              <div>
                <GetTokenHeading>{`Get your ${lpTokenName} Token`}</GetTokenHeading>
                <div>
                  <NavLink
                    to={`/add/${firstTokenInfo?.isNative ? firstTokenInfo.symbol : firstTokenInfo?.address}/${
                      secondTokenInfo?.isNative ? secondTokenInfo.symbol : secondTokenInfo?.address
                    }`}
                  >
                    Provide Liquidity
                  </NavLink>{' '}
                  to get your LP Token and start your staking journey.
                </div>
              </div>
            </GetMoreLPSection>
          )}

          {showApprove && (
            <ApproveSection>
              <FilledShieldIconWrapper>
                <FilledShieldIcon size={24} />
              </FilledShieldIconWrapper>

              <ApproveMessage>Allow Katana to use your {walletBalance?.currency?.symbol ?? 'UNKN'}</ApproveMessage>
              <Button size="small" intent="primary" onClick={approveWalletBalance}>
                <ButtonApproveWrapper>
                  Approve
                  {approvalState === ApprovalState.PENDING && <Loader stroke="white" />}
                </ButtonApproveWrapper>
              </Button>
            </ApproveSection>
          )}

          {showStakeSection && (
            <>
              <StakeButton size="large" intent="primary" onClick={handleClickStake} disabled={zeroDailyRewards}>
                Stake
              </StakeButton>
              <UnstakeButton intent="danger" minimal={true} size="large" onClick={handleClickUnstake}>
                Unstake
              </UnstakeButton>
            </>
          )}
        </RewardDetailSection>

        <PairInfoSection>
          <NavLink
            to={`/add/${firstTokenInfo?.isNative ? firstTokenInfo.symbol : firstTokenInfo?.address}/${
              secondTokenInfo?.isNative ? secondTokenInfo.symbol : secondTokenInfo?.address
            }`}
          >
            <ExternalLinkIconWrapper>
              Get {lpTokenName} <ExternalLinkIcon size={20} />
            </ExternalLinkIconWrapper>
          </NavLink>

          <PairInfoCard href={`${analyticsDomain}/pair/${lpTokenInfo?.address}`} target="_blank" rel="noopener">
            <PairInfoDetail>
              See pair info{' '}
              <InfoIcon size={20}>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.513 2a1 1 0 01.957.757l3.667 14.669 1.914-5.742A1 1 0 0117 11h4a1 1 0 110 2h-3.28l-2.771 8.316a1 1 0 01-1.92-.073L9.45 6.92l-1.485 5.348A1 1 0 017 13H3a1 1 0 110-2h3.24l2.296-8.268A1 1 0 019.513 2z"
                />
              </InfoIcon>
            </PairInfoDetail>
          </PairInfoCard>
        </PairInfoSection>
      </ToggleContent>

      <FarmStakeDialog
        isOpen={stakeDialogOpen}
        onClose={toggleStakeDialog}
        firstTokenInfo={firstTokenInfo}
        secondTokenInfo={secondTokenInfo}
        action={stakeDialogAction}
        maxAmount={stakeDialogAction === StakeDialogAction.Stake ? walletBalance : userTotalStaked}
        stakingPoolContract={stakingPoolContract}
        addPendingTx={addPendingTx}
      />

      <FarmClaimDialog
        isOpen={claimDialogOpen}
        onClose={toggleClaimDialog}
        amount={pendingReward}
        stakingPoolContract={stakingPoolContract}
        addPendingTx={addPendingTx}
      />
    </MaxWidthCard>
  )
}
