import { Currency } from '@uniswap/sdk-core'
import React, { useMemo } from 'react'
import styled from 'styled-components/macro'
import RONLogo from '../../assets/svg/ron.svg'
import Logo from '../Logo'
import AXSLogo from '../../assets/svg/axs.svg'
import WETHLogo from '../../assets/images/eth.png'
import SLPLogo from '../../assets/svg/slp.svg'
import USDCLogo from '../../assets/svg/usdc.svg'
import WRONLogo from '../../assets/svg/ronin-logo.svg'
import BerryLogo from '../../assets/berry.png'
import PixelLogo from '../../assets/pixel.png'
import BananaLogo from '../../assets/banana.png'
import YggLogo from '../../assets/ygg.png'

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
  ...rest
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const srcs: string[] = useMemo(() => {
    if (!currency || currency.isNative) return []

    if (currency.isToken) {
      switch (currency.symbol) {
        case 'AXS':
          return [AXSLogo]
        case 'SLP':
          return [SLPLogo]
        case 'WETH':
          return [WETHLogo]
        case 'RON':
          return [RONLogo]
        case 'WRON':
          return [WRONLogo]
        case 'USDC':
          return [USDCLogo]
        case 'BERRY':
          return [BerryLogo]
        case 'PIXEL':
          return [PixelLogo]
        case 'BANANA':
          return [BananaLogo]
        case 'YGG':
          return [YggLogo]
        default:
          return []
      }
    }
    return []
  }, [currency])

  if (currency?.isNative) {
    return <StyledEthereumLogo src={RONLogo} size={size} style={style} {...rest} />
  }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} {...rest} />
}
