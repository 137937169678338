import { AbstractConnector } from '@web3-react/abstract-connector'
import { roninWallet } from '../connectors'
import roninLogo from '../assets/svg/ron-logo.svg'

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconURL: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  Ronin: {
    connector: roninWallet,
    name: 'Ronin',
    iconURL: roninLogo,
    description: 'Login using Ronin extensions',
    href: null,
    color: '#4A6C9B',
    mobile: true,
  },
}
