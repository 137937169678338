import { Tooltip } from '@axieinfinity/mochi'
import { QuestionIcon } from '@axieinfinity/mochi-icons'
import { ReactNode } from 'react'
import styled from 'styled-components/macro'

const Container = styled.span`
  margin-left: var(--mc-space-inline-4x);
  display: flex;
  align-items: center;
`
const QuestionIconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: var(--mc-color-light-7);
  &:hover {
    color: var(--mc-color-blue-5);
  }
`
const Content = styled.div`
  padding-top: var(--mc-space-stack-4x);
  padding-bottom: var(--mc-space-stack-4x);
  text-align: center;
`

export default function QuestionHelper({ text, size }: { text: ReactNode; size?: number }) {
  return (
    <Container>
      <Tooltip
        target={
          <QuestionIconWrapper>
            <QuestionIcon />
          </QuestionIconWrapper>
        }
      >
        <Content style={{ maxWidth: size }}>{text}</Content>
      </Tooltip>
    </Container>
  )
}
