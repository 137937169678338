import styled from 'styled-components/macro'

import { Spinner } from '@axieinfinity/mochi'
import { AlertTriangleIcon, ExternalLinkIcon, TickIcon } from '@axieinfinity/mochi-icons'
import { useActiveWeb3React } from '../../hooks/web3'
import { useAllTransactions } from '../../state/transactions/hooks'
import { ExternalLink } from '../../theme'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'

const TransactionWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`

const TransactionStatusText = styled.div``

const TxStatusHeading = styled(TransactionStatusText)`
  margin-right: 8px;
  font-size: 14px;
  line-height: 20px;
`

const TransactionState = styled(ExternalLink)<{ pending: boolean; success?: boolean }>`
  text-decoration: none !important;
  display: flex;
  align-items: center;
  color: var(--mc-color-light-7) !important;
`

const IconWrapper = styled.div<{ pending: boolean; success?: boolean }>`
  color: ${({ pending, success, theme }) => (pending ? theme.primary1 : success ? theme.green1 : theme.red1)};
`

const TxIconWrapper = styled(IconWrapper)`
  margin-right: 8px;
  display: flex;
  align-items: center;
`

export default function Transaction({ hash }: { hash: string }) {
  const { chainId } = useActiveWeb3React()
  const allTransactions = useAllTransactions()

  const tx = allTransactions?.[hash]
  const summary = tx?.summary
  const pending = !tx?.receipt
  const success = !pending && tx && (tx.receipt?.status === 1 || typeof tx.receipt?.status === 'undefined')

  if (!chainId) return null

  return (
    <TransactionWrapper>
      <TxIconWrapper pending={pending} success={success}>
        {pending ? (
          <div className="basic-circular">
            <Spinner size="small" />
          </div>
        ) : success ? (
          <TickIcon />
        ) : (
          <AlertTriangleIcon />
        )}
      </TxIconWrapper>
      <TxStatusHeading>{summary ?? hash}</TxStatusHeading>
      <TransactionState
        href={getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)}
        pending={pending}
        success={success}
      >
        <ExternalLinkIcon />
      </TransactionState>
    </TransactionWrapper>
  )
}
