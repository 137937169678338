import { Button } from '@axieinfinity/mochi'
import { CurrencyExchangeIcon } from '@axieinfinity/mochi-icons'
import { Currency, CurrencyAmount, Percent, Price } from '@uniswap/sdk-core'
import { Text } from 'rebass'
import styled from 'styled-components'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
import CurrencyLogo from '../../components/CurrencyLogo'
import { Field } from '../../state/mint/actions'
import { TYPE } from '../../theme'
import { PoolPriceBar } from './PoolPriceBar'

const Container = styled.div`
  padding: var(--mc-space-inset-24x);
  padding-top: 0px;
`

const ButtonConfirm = styled(Button)`
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
`
const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`
const IconWrapper = styled.div`
  display: inline-block;
`
const CurrencyExchangeIconWrapper = styled(IconWrapper)`
  margin-right: 4px;
  color: var(--mc-color-light-7);
`
const DepositContainer = styled.div`
  display: flex;
  margin-top: var(--mc-space-stack-16x);
  gap: var(--mc-space-inline-20x);
`

const DepositCard = styled.div`
  border-radius: var(--mc-space-inset-8x);
  align-items: center;
  background-color: var(--mc-color-light-1);
  justify-content: center;
  padding-top: var(--mc-space-stack-12x);
  padding-bottom: var(--mc-space-stack-12x);
  flex: 1 1 0%;
  flex-direction: column;
`
const DepositHeader = styled(Text)`
  color: var(--mc-color-light-7);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: var(--mc-space-stack-8x);
`
const DepositBody = styled.div`
  display: flex;
  justify-content: center;
  margin-top: var(--mc-space-stack-8x);
  gap: var(--mc-space-inline-4x);
`
const DepositAmount = styled.div`
  font-weight: var(--mc-font-weight-semi-bold);
  font-size: var(--mc-font-size-large);
  line-height: var(--mc-line-height-x-large);
  color: var(--mc-color-light-7);
`
const PriceAndPoolShare = styled.div`
  background-color: var(--mc-color-white);
  border-width: var(--mc-space-inset-x);
  border-color: var(--mc-color-light-3);
  border-radius: var(--mc-space-inset-8x);
  padding: var(--mc-space-inset-12x);
  margin-top: var(--mc-space-stack-16x);
`
const HeaderPPS = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: var(--mc-space-stack-12x);
  gap: var(--mc-space-inline-4x);
`
const SubHeader = styled(TYPE.subHeader)`
  color: var(--mc-color-light-7);
  text-transform: uppercase;
  line-height: var(--mc-line-height-normal);
`
export function ConfirmAddModalBottom({
  noLiquidity,
  price,
  currencies,
  parsedAmounts,
  poolTokenPercentage,
  onAdd,
}: {
  noLiquidity?: boolean
  // price?: Fraction
  price?: Price<Currency, Currency>
  currencies: { [field in Field]?: Currency }
  parsedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  poolTokenPercentage?: Percent
  onAdd: () => void
}) {
  return (
    <Container>
      <DepositContainer>
        <DepositCard>
          <DepositHeader fontSize={10} lineHeight="16px" fontWeight={700}>
            {/* <Trans>{currencies[Field.CURRENCY_A]?.symbol} Deposited</Trans> */}
            {currencies[Field.CURRENCY_A]?.symbol} Deposited
          </DepositHeader>
          <DepositBody>
            <CurrencyLogo currency={currencies[Field.CURRENCY_A]} />
            <DepositAmount>{formatCurrencyAmount(parsedAmounts[Field.CURRENCY_A])}</DepositAmount>
          </DepositBody>
        </DepositCard>
        <DepositCard>
          <DepositHeader fontSize={10} lineHeight="16px" fontWeight={700}>
            {/* <Trans>{currencies[Field.CURRENCY_B]?.symbol} Deposited</Trans> */}
            {currencies[Field.CURRENCY_B]?.symbol} Deposited
          </DepositHeader>
          <DepositBody>
            <CurrencyLogo currency={currencies[Field.CURRENCY_B]} />
            <DepositAmount>{formatCurrencyAmount(parsedAmounts[Field.CURRENCY_B])}</DepositAmount>
          </DepositBody>
        </DepositCard>
      </DepositContainer>
      {/* <RowBetween>
        <TYPE.body>
          <Trans>{currencies[Field.CURRENCY_A]?.symbol} Deposited</Trans>
        </TYPE.body>
        <RowFixed>
          <CurrencyLogo currency={currencies[Field.CURRENCY_A]} style={{ marginRight: '8px' }} />
          <TYPE.body>{parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}</TYPE.body>
        </RowFixed>
      </RowBetween> */}
      {/* <RowBetween>
        <TYPE.body>
          <Trans>{currencies[Field.CURRENCY_B]?.symbol} Deposited</Trans>
        </TYPE.body>
        <RowFixed>
          <CurrencyLogo currency={currencies[Field.CURRENCY_B]} style={{ marginRight: '8px' }} />
          <TYPE.body>{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}</TYPE.body>
        </RowFixed>
      </RowBetween> */}

      <PriceAndPoolShare>
        <HeaderPPS>
          <CurrencyExchangeIcon size={16} color="var(--mc-color-light-7)" />
          <SubHeader fontWeight={700} fontSize={10}>
            {noLiquidity ? 'Initial prices and pool share' : 'Prices and pool share'}
          </SubHeader>
        </HeaderPPS>
        {price && (
          <PoolPriceBar
            currencies={currencies}
            poolTokenPercentage={poolTokenPercentage}
            noLiquidity={noLiquidity}
            price={price}
          />
        )}
      </PriceAndPoolShare>
      {/* <RowBetween>
        <TYPE.body>
          <Trans>Rates</Trans>
        </TYPE.body>
        <TYPE.body>
          {`1 ${currencies[Field.CURRENCY_A]?.symbol} = ${price?.toSignificant(4)} ${
            currencies[Field.CURRENCY_B]?.symbol
          }`}
        </TYPE.body>
      </RowBetween>
      <RowBetween style={{ justifyContent: 'flex-end' }}>
        <TYPE.body>
          {`1 ${currencies[Field.CURRENCY_B]?.symbol} = ${price?.invert().toSignificant(4)} ${
            currencies[Field.CURRENCY_A]?.symbol
          }`}
        </TYPE.body>
      </RowBetween>
      <RowBetween>
        <TYPE.body>
          <Trans>Share of Pool:</Trans>
        </TYPE.body>
        <TYPE.body>
          <Trans>{noLiquidity ? '100' : poolTokenPercentage?.toSignificant(4)}%</Trans>
        </TYPE.body>
      </RowBetween> */}
      {/* <ButtonPrimary style={{ margin: '20px 0 0 0' }} onClick={onAdd}> */}
      <ButtonWrapper>
        <ButtonConfirm onClick={onAdd} intent="primary">
          {noLiquidity ? 'Create Pool & Supply' : 'Confirm Supply'}
        </ButtonConfirm>
      </ButtonWrapper>
      {/* <Text fontWeight={500} fontSize={20}>
          {noLiquidity ? <Trans>Create Pool & Supply</Trans> : <Trans>Confirm Supply</Trans>}
        </Text> */}
      {/* </ButtonPrimary> */}
    </Container>
  )
}
