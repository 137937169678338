import styled from 'styled-components'
import { Token } from './Token'

interface Props {
  value?: string
  symbol: string
  hidden?: boolean
  fiatValue?: string
}

const FiatValue = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: var(--mc-color-light-9);
  font-weight: 400;
  margin-left: 4px;
`

export const TokenWithFiat = ({ value, hidden, symbol, fiatValue }: Props) => {
  return (
    <div>
      {hidden && '**'}
      {!hidden && (
        <>
          <div>
            <Token
              value={value}
              symbol={symbol}
              symbolStyle={{ fontSize: 14, lineHeight: '20px', color: 'var(--mc-color-light-7)' }}
            />
          </div>

          {value && <FiatValue>{fiatValue || '~$0'}</FiatValue>}
        </>
      )}
    </div>
  )
}
