import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { DEFAULT_CHAIN_ID, SupportedChainId } from 'constants/chains'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { roninWallet } from '../connectors'
import { NetworkContextName } from '../constants/misc'
import { checkInAppBrowser } from 'connectors/RoninConnector'

interface IActiveWeb3 extends Web3ReactContextInterface<Web3Provider> {
  chainId: SupportedChainId
}

export function useActiveWeb3React(): IActiveWeb3 {
  const context = useWeb3ReactCore<Web3Provider>()
  const contextNetwork = useWeb3ReactCore<Web3Provider>(NetworkContextName)
  const activedContext = context.active ? context : contextNetwork

  return {
    ...activedContext,
    chainId: activedContext.chainId ?? DEFAULT_CHAIN_ID,
  }
}

// try to auto-connect to Ronin Wallet when mount
export function useEagerConnect() {
  const { activate, active, account } = useWeb3ReactCore() // specifically using useWeb3ReactCore because of what this hook does
  const [tried, setTried] = useState(false)

  useEffect(() => {
    if (checkInAppBrowser() || localStorage.getItem('walletgo.RECONNECT.RONIN_EXT_CONNECTOR') === 'true') {
      activate(roninWallet, undefined, true).catch(() => {
        setTried(true)
      })

      setTried(true)
      return
    }

    if (isMobile) {
      setTried(true)
      return
    }

    setTried(true)

    // // if on mobile, check walletconnect info before connect
    // if (isMobile && !window.ronin && !localStorage.getItem('walletconnect')) {
    //   setTried(true)
    //   return
    // }

    // //only eagerly connect when account is cached or else extension will pop up on mount === bad UX
    // if (window.isWalletApp || (!isMobile && localStorage.getItem('account'))) {
    //   activate(roninWallet, undefined, true).catch(() => {
    //     setTried(true)
    //   })
    // }
  }, []) // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (active) {
      setTried(true)
    }
  }, [active])

  return tried
}
