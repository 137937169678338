import { Button, Checkbox, Switch } from '@axieinfinity/mochi'
import { SettingsIcon } from '@axieinfinity/mochi-icons'
import { Percent } from '@uniswap/sdk-core'
import { useContext, useRef, useState } from 'react'
import { Settings, X } from 'react-feather'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleSettingsMenu } from '../../state/application/hooks'
import { useExpertModeManager, useUserSingleHopOnly } from '../../state/user/hooks'
import { ButtonError } from '../Button'
import { AutoColumn } from '../Column'
import Modal from '../Modal'
import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'
import TransactionSettings from '../TransactionSettings'
const StyledMenuIcon = styled(Settings)`
  height: 20px;
  width: 20px;

  > * {
    stroke: ${({ theme }) => theme.text2};
  }

  :hover {
    opacity: 0.7;
  }
`

const StyledCloseIcon = styled(X)`
  height: 20px;
  width: 20px;
  :hover {
    cursor: pointer;
  }

  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`
const StyledSettingIcon = styled.div<{ open?: boolean }>`
  color: ${({ open }) => (open ? 'var(--mc-color-blue-5)' : 'var(--mc-color-light-7)')};
  &:hover {
    color: var(--mc-color-blue-5);
  }
  cursor: pointer;
  display: flex;
  align-items: center;
`
const ButtonCancel = styled(Button)`
  margin-right: 20px;
`
const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  border-radius: 0.5rem;
  height: 20px;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
  }
`
const EmojiWrapper = styled.div`
  position: absolute;
  font-size: 12px;
  line-height: 16px;
  left: -24px;
  width: 20px;
  height: 20px;
  background-color: var(--mc-color-red-1);
  border-radius: 8px;
  padding: 2px 4px;
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.div`
  min-width: 20.125rem;
  /* background-color: ${({ theme }) => theme.bg2}; */
  background-color: #fff;
  /* border: 1px solid ${({ theme }) => theme.bg3}; */
  border: 1px solid #dee6f1;
  box-shadow: 0px 2px 8px rgba(48, 59, 80, 0.25);
  border-radius: 8px;
  /* box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01); */
  /* border-radius: 12px; */
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 2rem;
  right: 0rem;
  z-index: 100;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    min-width: 18.125rem;
  `};

  user-select: none;
`

const Break = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.bg3};
`

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: var(--mc-color-white);
  width: 100%;
  padding: var(--mc-space-stack-24x) 0;
`
const ModalWrapper = styled(AutoColumn)`
  width: 100%;
`
const ModalHeader = styled(RowBetween)`
  padding: 0 var(--mc-space-inline-20x) 0 var(--mc-space-inline-24x);
  margin-bottom: var(--mc-space-stack-4x);
  align-items: flex-start;
`
const ModalBody = styled(AutoColumn)`
  padding: 0 var(--mc-space-inline-24x);
`
const ModalBodyText = styled(Text)`
  line-height: var(--mc-line-height-medium);
  max-width: 352px;
`
const LabelCheckbox = styled.div`
  color: var(--mc-color-light-8);
`
const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const MenuFlyoutContainer = styled(AutoColumn)`
  padding: 1rem;
`
const HeaderMenuFlyout = styled.div<{ marginTop?: string }>`
  font-weight: var(--mc-font-weight-semi-bold);
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop}px` : '')};
  color: var(--mc-color-light-9);
  font-size: var(--mc-font-size-normal);
  line-height: var(--mc-line-height-medium);
`
export default function SettingsTab({ placeholderSlippage }: { placeholderSlippage: Percent }) {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.SETTINGS)
  const toggle = useToggleSettingsMenu()

  const theme = useContext(ThemeContext)
  const [understood, setUnderstood] = useState<boolean>(false)

  const [expertMode, toggleExpertMode] = useExpertModeManager()

  const [singleHopOnly, setSingleHopOnly] = useUserSingleHopOnly()

  // show confirmation view before turning on
  const [showConfirmation, setShowConfirmation] = useState(false)

  useOnClickOutside(node, open ? toggle : undefined)

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <Modal isOpen={showConfirmation} onDismiss={() => setShowConfirmation(false)} maxHeight={100} maxWidth={420}>
        <ModalContainer>
          <ModalWrapper gap="md">
            <ModalHeader>
              <Text fontWeight={500} fontSize={20}>
                {/* <Trans> */}🔥 Expert mode 🔥{/* </Trans> */}
              </Text>
              <StyledCloseIcon onClick={() => setShowConfirmation(false)} />
            </ModalHeader>
            <Break />
            <ModalBody gap="lg">
              <ModalBodyText fontWeight={400} fontSize={14}>
                {/* <Trans> */}
                Expert mode turns off the confirm transaction prompt and allows high slippage trades that often result
                in bad rates and lost funds.
                {/* </Trans> */}
              </ModalBodyText>
              {/* <Text fontWeight={600} fontSize={20}>
                <Trans>ONLY USE THIS MODE IF YOU KNOW WHAT YOU ARE DOING. </Trans>
              </Text> */}
              <Checkbox
                checked={understood}
                onChange={() => setUnderstood(!understood)}
                label={<LabelCheckbox>I understand what I am doing.</LabelCheckbox>}
              />
              <ModalButtonContainer>
                <ButtonCancel intent="secondary" onClick={() => setShowConfirmation(false)}>
                  Cancel
                </ButtonCancel>
                <ButtonError
                  error={true}
                  disabled={!understood}
                  onClick={() => {
                    // const confirmWord = `confirm`
                    // if (
                    //   window.prompt(`Please type the word "${confirmWord}" to enable expert mode.`) === confirmWord
                    // ) {
                    toggleExpertMode()
                    setShowConfirmation(false)
                    setUnderstood(false)
                    // }
                  }}
                >
                  {/* <Text fontSize={20} fontWeight={500} id="confirm-expert-mode"> */}
                  {/* <Trans> */}Turn on expert mode{/* </Trans> */}
                  {/* </Text> */}
                </ButtonError>
              </ModalButtonContainer>
            </ModalBody>
          </ModalWrapper>
        </ModalContainer>
      </Modal>

      <StyledSettingIcon open={open}>
        <SettingsIcon onClick={toggle} size={24} />
      </StyledSettingIcon>
      {expertMode ? (
        <EmojiWrapper>
          <span role="img" aria-label="wizard-icon">
            🔥
          </span>
        </EmojiWrapper>
      ) : null}
      {/* <StyledMenuButton onClick={toggle} id="open-settings-dialog-button">
        <StyledMenuIcon />
        {expertMode ? (
          <EmojiWrapper>
            <span role="img" aria-label="wizard-icon">
              🧙
            </span>
          </EmojiWrapper>
        ) : null}
      </StyledMenuButton> */}
      {open && (
        <MenuFlyout>
          <MenuFlyoutContainer gap="md">
            <HeaderMenuFlyout>
              {/* <Trans> */}Transaction Settings{/* </Trans> */}
            </HeaderMenuFlyout>
            <TransactionSettings placeholderSlippage={placeholderSlippage} />
            <HeaderMenuFlyout marginTop="8">
              {/* <Trans> */}Interface Settings{/* </Trans> */}
            </HeaderMenuFlyout>
            <RowBetween>
              <RowFixed>
                <Switch
                  checked={expertMode}
                  onChange={
                    expertMode
                      ? () => {
                          toggleExpertMode()
                          setShowConfirmation(false)
                        }
                      : () => {
                          toggle()
                          setShowConfirmation(true)
                        }
                  }
                  label={
                    <div style={{ display: 'flex' }}>
                      {/* <Trans> */}Expert Mode{/* </Trans> */}
                      <QuestionHelper
                        size={166}
                        text={
                          <>
                            {/* <Trans> */}
                            Allow high price impact trades and skip the confirm screen. Use at your own risk.
                            {/* </Trans> */}
                          </>
                        }
                      />
                    </div>
                  }
                />
              </RowFixed>
              {/* <RowFixed>
                <TYPE.black fontWeight={400} fontSize={14} color={theme.text2}>
                  <Trans>Toggle Expert Mode </Trans>
                </TYPE.black>
                <QuestionHelper
                  text={
                    <Trans>Allow high price impact trades and skip the confirm screen. Use at your own risk.</Trans>
                  }
                />
              </RowFixed> */}
              {/* <Toggle
                id="toggle-expert-mode-button"
                isActive={expertMode}
                toggle={
                  expertMode
                    ? () => {
                        toggleExpertMode()
                        setShowConfirmation(false)
                      }
                    : () => {
                        toggle()
                        setShowConfirmation(true)
                      }
                }
              /> */}
            </RowBetween>
            {/* <RowBetween>
              <RowFixed>
                <TYPE.black fontWeight={400} fontSize={14} color={theme.text2}>
                  <Trans>Disable Multihops{/* </Trans>
                </TYPE.black>
                <QuestionHelper text={<Trans>Restricts swaps to direct pairs only.</Trans></>} />
              </RowFixed>
              <Toggle
                id="toggle-disable-multihop-button"
                isActive={singleHopOnly}
                toggle={() => {
                  ReactGA.event({
                    category: 'Routing',
                    action: singleHopOnly ? 'disable single hop' : 'enable single hop',
                  })
                  setSingleHopOnly(!singleHopOnly)
                }}
              />
            </RowBetween> */}
          </MenuFlyoutContainer>
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
